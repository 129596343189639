import { useEffect, useState } from "react";

import DashboardTableCard from "./dashboardTableCard";
import { RealtimeData } from "../../models/dashboard";
import fetchGCP from "../../utils/fetchGCP";

const header = [
  "Status",
  "Entity",
  "Latest timestamp",
  "Latest generation",
  "Type",
];
const metadata = [
  { id: "a", param: "smith-creek", entity: "Smith Creek", type: "Hydro" },
  { id: "b", param: "panorama", entity: "Panorama", type: "Wind" },
  { id: "c", param: "ashmesa", entity: "Ashmesa", type: "Solar" },
];

const RealtimeGeneration = () => {
  const [rows, setRows] = useState<RealtimeData[]>([]);

  useEffect(() => {
    const fetchData = async (index: number) => {
      let url;
      if (index === 0) {
        url = `${process.env.REACT_APP_ENDPOINTS_URL}/dashboard/real-time-generation-react?entity=${metadata[index].param}`;
        const response = await fetchGCP(url);
        if (response.ok) {
          // When API succeeds
          const fetchedData = await response.json();
          const row: RealtimeData = {
            id: metadata[index].id,
            status: fetchedData.isDataGood,
            entity: metadata[index].entity,
            type: metadata[index].type,
            latestTimestamp: new Date(
              fetchedData.latestDatetime,
            ).toLocaleString(),
            latestValue: fetchedData.latestGeneration,
          };
          setRows((prevRows) => [...prevRows, row]);
        } else {
          // When API fails
          const row: RealtimeData = {
            id: metadata[index].id,
            status: false,
            entity: metadata[index].entity,
            type: metadata[index].type,
            latestTimestamp: "N/A",
            latestValue: null,
          };
          setRows((prevRows) => [...prevRows, row]);
        }
      } else {
        url = `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/generations/actuals?entity=${metadata[index].param}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_AWS_API_GATEWAY_API_KEY!,
          },
        });
        if (response.ok) {
          const fetchedData = await response.json();
          const t = new Date(fetchedData[0].index);
          const now = new Date();
          const threshold = 3 * 60 * 60 * 1000; /* 3 hours */
          const status = now.getTime() - t.getTime() < threshold;
          const row: RealtimeData = {
            id: metadata[index].id,
            status: status,
            entity: metadata[index].entity,
            type: metadata[index].type,
            latestTimestamp: new Date(fetchedData[0].index).toLocaleString(),
            latestValue: fetchedData[0].value,
          };
          setRows((prevRows) => [...prevRows, row]);
        } else {
          const row: RealtimeData = {
            id: metadata[index].id,
            status: false,
            entity: metadata[index].entity,
            type: metadata[index].type,
            latestTimestamp: "N/A",
            latestValue: null,
          };
          setRows((prevRows) => [...prevRows, row]);
        }
      }
    };

    setRows([]);
    for (let i = 0; i < metadata.length; i++) {
      fetchData(i);
    }
  }, []);

  return (
    <DashboardTableCard
      title={"Real-time generation"}
      rows={rows}
      header={header}
      isLoading={rows.length !== metadata.length ? true : false}
    />
  );
};

export default RealtimeGeneration;
