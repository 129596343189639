import { useState } from "react";
import Grid from "@mui/material/Grid";

import LongTermLoadForecastSettings from "../../components/long-term-load-forecast/LongTermLoadForecastSetting";
import LongTermLoadForecastPlotCard from "../../components/data-visualization/LongTermLoadForecastPlotCard";
import ModelEvaluation from "../../components/long-term-load-forecast/LongTermLoadForecastModelEvaluation";
import {
  tabBlue,
  tabOrange,
  tabGreen,
  tabBrown,
  tabPink,
  tabPurple,
  tabCyan,
  tabRed,
  tabOlive,
} from "../../utils/style";
import LongTermDownloadData from "../../components/download-data/LongTermDownloadData";

const metadata = [
  { value: "Actual", color: tabBlue, strokeWidth: 2 },
  {
    value: "Backcast",
    color: tabOrange,
    strokeWidth: 2,
    strokeDasharray: "5 5",
  },
  {
    value: "Forecast",
    color: tabGreen,
    strokeWidth: 2,
    strokeDasharray: "5 5",
  },
];

const settingdata = {
  entity: "",
  load: "",
  model: "",
  scenario: [],
  start: "",
  end: "",
};

const colorPickers = [
  tabGreen,
  tabRed,
  tabCyan,
  tabPurple,
  tabPink,
  tabBrown,
  tabOlive,
];

const LongTermLoadForecastPage = () => {
  const [monthlyTotal, setMonthlyTotal] = useState([]);
  const [monthlyPeak, setMonthlyPeak] = useState([]);
  const [summerProfile, setSummerProfile] = useState([]);
  const [winterProfile, setWinterProfile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [meta, setMeta] = useState(metadata);
  const [settingData, setSettingData] = useState(settingdata);

  //We want this to dynamically fill graphs with the number of scenarios
  const generateMetadata = (scenarios: string[]) => {
    const temp: typeof metadata = [];
    temp.push(Object.assign({}, metadata[0]));
    temp.push(Object.assign({}, metadata[1]));
    for (let index = 0; index < scenarios.length; index++) {
      let t = Object.assign({}, metadata[2]);
      //Set value to be scenario name
      t.value += scenarios[index];
      t.color = colorPickers[index];
      temp.push(t);
    }
    setMeta(temp);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      p={2}
    >
      <Grid item xs={12}>
        <LongTermLoadForecastSettings
          setMonthlyTotal={setMonthlyTotal}
          setMonthlyPeak={setMonthlyPeak}
          setHourlySummer={setSummerProfile}
          setHourlyWinter={setWinterProfile}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          generateMetadata={generateMetadata}
          setSettingData={setSettingData}
        />
      </Grid>

      <Grid item xs={12}>
        <LongTermLoadForecastPlotCard
          title="Monthly total"
          data={monthlyTotal}
          metadata={meta}
          xAxisUnits=""
          yAxisUnits="KW"
          isLoading={isLoading}
        />
      </Grid>

      <Grid item xs={12}>
        <LongTermLoadForecastPlotCard
          title="Monthly peak"
          data={monthlyPeak}
          metadata={meta}
          xAxisUnits=""
          yAxisUnits="KW"
          isLoading={isLoading}
        />
      </Grid>

      <Grid item xs={12}>
        <LongTermLoadForecastPlotCard
          title="Summer Profile"
          data={summerProfile}
          metadata={meta}
          xAxisUnits="HE"
          yAxisUnits="KWH"
          isLoading={isLoading}
        />
      </Grid>

      <Grid item xs={12}>
        <LongTermLoadForecastPlotCard
          title="Winter Profile"
          data={winterProfile}
          metadata={meta}
          xAxisUnits="HE"
          yAxisUnits="KWH"
          isLoading={isLoading}
        />
      </Grid>

      <Grid item xs={12}>
        <LongTermDownloadData
          settingData={settingData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </Grid>

      <Grid item xs={7}>
        <ModelEvaluation settingData={settingData} />
      </Grid>
    </Grid>
  );
};

export default LongTermLoadForecastPage;
