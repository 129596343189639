import React, { useState, useEffect } from "react";
import PositionData from "./Positions";
import DNRData from "./DNRs";
import PositionsTable from "./PositionsTable";

/** Setting up position table */

interface PositionsTableContainerProps {
  load: string;
  onPathsFetched: (paths: { [key: string]: string[] }[]) => void;
  onSubstationsFetched: (substations: {
    [key: string]: [number, number];
  }) => void;
  highlightedRow: string | null;
  onRowHover: (key: string | null) => void;
}

const PositionsTableContainer: React.FC<PositionsTableContainerProps> = ({
  load,
  onPathsFetched,
  onSubstationsFetched,
  onRowHover,
}) => {
  const [positionData, setPositionData] = useState<any>(null);
  const [dnrData, setDnrData] = useState<any[]>([]);
  const [combinedData, setCombinedData] = useState<any[]>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const [highlightedRow, setHighlightedRow] = useState<string | null>(null);

  useEffect(() => {
    if (positionData && dnrData.length) {
      const combined: any[] = [];
      const loadData = positionData[load];
      if (!loadData) return;

      const dateRange = loadData["Date-Range"];
      if (!Array.isArray(dateRange) || dateRange.length !== 2) {
        console.error("Invalid date range in position data.");
        return;
      }
      const startDate = parseDate(dateRange[0]);
      const endDate = parseDate(dateRange[1]);
      if (!startDate || !endDate) {
        console.error("Invalid date format in position data.");
        return;
      }
      const months = generateMonths(startDate, endDate);
      setColumns(months);
      // joining position data with DNR data
      Object.keys(loadData).forEach((key) => {
        const position = loadData[key];
        if (key !== "Date-Range") {
          if (position && position.arefs) {
            const matchedDnr = dnrData.filter((dnr: any) =>
              position.arefs.includes(parseInt(dnr.aref, 10)),
            );
            combined.push({ key, position, matchedDnr });
          } else {
            combined.push({ key, position, matchedDnr: [] });
          }
        }
      });
      setCombinedData(combined);
    }
  }, [positionData, dnrData, load]);

  // For passing into transmission component to highlight lines
  const handleRowHover = (key: string | null) => {
    setHighlightedRow(key);
    onRowHover(key);
  };

  const parseDate = (dateString: string): Date | null => {
    const parts = dateString.split("/");
    if (parts.length !== 3) return null;
    const [month, day, year] = parts.map(Number);
    if (isNaN(month) || isNaN(day) || isNaN(year)) return null;
    return new Date(year, month - 1, day);
  };

  // Spreading date range into individual columns for each month
  const generateMonths = (startDate: Date, endDate: Date): string[] => {
    const months: string[] = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const monthName = currentDate.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
      });
      months.push(monthName);
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return months;
  };

  return (
    <div>
      <PositionData
        load={load}
        onDataFetched={setPositionData}
        onPathsFetched={onPathsFetched}
        onSubstationsFetched={onSubstationsFetched}
      />
      <DNRData onDataFetched={setDnrData} />
      <PositionsTable
        combinedData={combinedData}
        columns={columns}
        highlightedRow={highlightedRow}
        onRowHover={handleRowHover}
      />
    </div>
  );
};

export default PositionsTableContainer;
