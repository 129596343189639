import React, { useState, useEffect } from "react";
import { firebaseAuth } from "../initializeFirebase";
import {
  User as FirebaseUser,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import { FirebaseError } from "firebase/app";

type AuthContextObj = {
  user: FirebaseUser | null;
  isLoggedIn: boolean;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
};

interface Props {
  children: React.ReactNode;
}

const AuthContext = React.createContext<AuthContextObj>({
  user: null,
  isLoggedIn: false,
  login: async (email: string, password: string) => {},
  logout: async () => {},
});

export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [loggedIn, setLoggedin] = useState(false);

  useEffect(() => {
    const listener = onAuthStateChanged(
      firebaseAuth,
      (user: FirebaseUser | null) => {
        setUser(user);
        setLoggedin(!!user);
      },
    );

    return () => {
      listener();
    };
  }, []);

  const logoutHandler = async () => {
    try {
      await signOut(firebaseAuth);
    } catch (e) {
      const error = e as FirebaseError;
      console.error(`Log out error (${error.code}): ${error.message}`);
    }
  };

  const loginHandler = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(firebaseAuth, email, password);
    } catch (e) {
      const error = e as FirebaseError;
      console.error(`Login error (${error.code}): ${error.message}`);
    }
  };

  const contextValue = {
    user: user,
    isLoggedIn: loggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
