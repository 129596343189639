import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import PscoForecast from "../../components/forecast/Psco";
import ModelEvaluationCard from "../../components/data-visualization/ModelEvaluationCard";
import ShortTermLoadForecast from "../../components/forecast/ShortTermLoadForecast";
import GenerationForecast from "../../components/forecast/GenerationForecast";

const Psco = () => {
  return (
    <Grid container p={2} spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" component="div" align="center">
          PSCO
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <PscoForecast
          data="wind-penetration"
          title="Wind penetration forecast"
          ymin={0}
          ymax={1}
          ylabel="Percent"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <PscoForecast
          data="curtailment-probability"
          title="Curtailment probability"
          ymin={0}
          ymax={1}
          ylabel="Percent"
        />
      </Grid>

      <Grid item xs={12}>
        <ShortTermLoadForecast entity="psco" />
      </Grid>

      {/* <Grid item xs={12} md={6}>
        <PscoForecast data="wind" title="Wind forecast" />
      </Grid> */}

      <Grid item xs={12} md={6}>
        <GenerationForecast entity="psco" power="wind" title="Wind forecast" />
      </Grid>

      <Grid item xs={12} md={6}>
        <ModelEvaluationCard
          entity="psco"
          title="Curtailment forecast model evaluation"
          data="curtailment-probability"
          folder="psco_curtailment_forecast"
          images={[
            { summary: "Feature importance", name: "feature_importance.png" },
            { summary: "Wind effect", name: "pdp_wind.png" },
            { summary: "Load effect", name: "pdp_load.png" },
          ]}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ModelEvaluationCard
          entity="psco"
          title="Load forecast model evaluation"
          data="load"
          folder="psco_short_term_load_forecast"
          images={[
            {
              summary: "Feature importance",
              name: "feature_importance_all.png",
            },
            {
              summary: "Temperature effect",
              name: "pdp_temperature_denver_aurora.png",
            },
            {
              summary: "Solar radiation effect",
              name: "pdp_solar_radiation_denver_aurora.png",
            },
            {
              summary: "Humidity effect",
              name: "pdp_humidity_denver_aurora.png",
            },
            { summary: "Hour effect", name: "pdp_cosine_of_hour.png" },
            { summary: "Date effect", name: "pdp_sine_of_date.png" },
            { summary: "Holiday effect", name: "pdp_holiday.png" },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default Psco;
