import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "data-analytics-prod.firebaseapp.com",
  projectId: "data-analytics-prod",
  storageBucket: "data-analytics-prod.appspot.com",
  messagingSenderId: "296045995294",
  appId: "1:296045995294:web:e7b5d76d52e4e9fa39950f",
  measurementId: "G-B9R3RYVZFP",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
