import React, { useRef, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import LoadAreas from "../components/map/LoadAreas";
import PositionsTableContainer from "../components/map/PositionsTableContainer";
import TransmissionLines from "../components/map/TransmissionLines";

/** Displaying map and future capacity plan for loads */

const CapacityPlan: React.FC = () => {
  const mapRef = useRef(null);
  const lat = 38.11035938114642;
  const long = -105.58397543614977;
  const [load, setLoad] = useState<string>("");
  const [paths, setPaths] = useState<{ [key: string]: string[] }[]>([]);
  const [substations, setSubstations] = useState<{
    [key: string]: [number, number];
  }>({});
  const [highlightedRow, setHighlightedRow] = useState<string | null>(null);
  const legendData = [
    { name: "CO - West", color: "red" },
    { name: "CO - East", color: "blue" },
    { name: "CO - South", color: "green" },
    { name: "NM", color: "purple" },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "5vh",
      }}
    >
      {load === "" ? (
        <>
          <h2>Future Capacity Plan for Loads</h2>
        </>
      ) : (
        <>
          <h2>{load}</h2>
        </>
      )}
      <div
        style={{ position: "absolute", marginRight: "72vw", marginTop: "10vh" }}
      >
        <h3>Legend</h3>
        {legendData.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: item.color,
                marginRight: "5px",
              }}
            ></div>
            <span>{item.name}</span>
          </div>
        ))}
      </div>
      <MapContainer
        center={[lat, long]}
        zoom={6}
        ref={mapRef}
        style={{ height: "58vh", width: "65vw" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LoadAreas
          load={load}
          setLoad={setLoad}
          paths={paths}
          setPaths={setPaths}
          substations={substations}
          setSubstations={setSubstations}
        />
        {!(load === "LaPlata" || load === "HolyCross" || load === "") ? (
          <TransmissionLines
            paths={paths}
            substations={substations}
            highlightedRow={highlightedRow}
          />
        ) : (
          <></>
        )}
      </MapContainer>

      {!(load === "LaPlata" || load === "HolyCross" || load === "") ? (
        <PositionsTableContainer
          load={load}
          onPathsFetched={setPaths}
          onSubstationsFetched={setSubstations}
          highlightedRow={highlightedRow}
          onRowHover={setHighlightedRow}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CapacityPlan;
