import { GridColDef, GridRowId } from "@mui/x-data-grid-v7";

export interface GridRow {
  id: GridRowId;
  [key: string]: any;
}

export interface ValidationRule {
  name: string;
  ruleId: string;
  validate: (
    rows: GridRow[],
    columns: GridColDef[],
    context?: ValidationContext
  ) => ValidationResult;
}

// Add a context interface to pass additional information to validation rules
export interface ValidationContext {
  transaction?: string;  // "Import" or "Export"
}

export interface ValidationResult {
  isValid: boolean;
  errorMessage: string;
  affectedRows?: GridRowId[];
  affectedColumns?: string[];
}

export const validationRules: ValidationRule[] = [
  {
    name: "Last Two Segments Price Equality",
    ruleId: "LAST_TWO_SEGMENTS_EQUAL",
    validate: (rows, columns) => {
      const segmentPriceColumns = columns
        .filter((col) => col.field.startsWith("segment") && col.field.endsWith("Price"))
        .sort((a, b) => {
          const aNum = parseInt(a.field.replace("segment", "").replace("Price", ""));
          const bNum = parseInt(b.field.replace("segment", "").replace("Price", ""));
          return aNum - bNum;
        });
        
      if (segmentPriceColumns.length < 2) {
        return { isValid: true, errorMessage: "" };
      }

      const lastTwoSegments = segmentPriceColumns.slice(-2);
      const errors: ValidationResult = {
        isValid: true,
        errorMessage: "",
        affectedRows: [],
        affectedColumns: lastTwoSegments.map((col) => col.field),
      };

      rows.forEach((row) => {
        if (row.id === "total") return;
        
        const lastPrice = row[lastTwoSegments[1].field];
        const secondLastPrice = row[lastTwoSegments[0].field];
        
        // Only validate if both prices are defined and not null
        if (lastPrice != null && secondLastPrice != null) {
          if (lastPrice !== secondLastPrice) {
            errors.isValid = false;
            errors.affectedRows?.push(row.id);
          }
        }
      });

      if (!errors.isValid) {
        errors.errorMessage = `The prices of the last two segments must be equal.`;
      }

      return errors;
    },
  },
  {
    name: "Direction-Based Monotonic Price Segments",
    ruleId: "DIRECTION_BASED_MONOTONIC_SEGMENTS",
    validate: (rows, columns, context?: ValidationContext) => {
      const segmentPriceColumns = columns
        .filter((col) => col.field.startsWith("segment") && col.field.endsWith("Price"))
        .sort((a, b) => {
          const aNum = parseInt(a.field.replace("segment", "").replace("Price", ""));
          const bNum = parseInt(b.field.replace("segment", "").replace("Price", ""));
          return aNum - bNum;
        });

      if (segmentPriceColumns.length < 2) {
        return { isValid: true, errorMessage: "" };
      }

      const errors: ValidationResult = {
        isValid: true,
        errorMessage: "",
        affectedRows: [],
        affectedColumns: segmentPriceColumns.map((col) => col.field),
      };

      // Determine required direction based on transaction type
      const shouldBeIncreasing = context?.transaction === "Import";
      const directionText = shouldBeIncreasing ? "increasing" : "decreasing";

      rows.forEach((row) => {
        if (row.id === "total") return;

        let previousPrice: number | null = null;
        let violatesMonotonicity = false;

        for (const column of segmentPriceColumns) {
          const currentPrice = row[column.field];
          
          // Skip if price is not defined
          if (currentPrice == null) continue;
          
          if (previousPrice != null) {
            if (shouldBeIncreasing) {
              // For Import: prices must be increasing
              if (currentPrice < previousPrice) {
                violatesMonotonicity = true;
                break;
              }
            } else {
              // For Export: prices must be decreasing
              if (currentPrice > previousPrice) {
                violatesMonotonicity = true;
                break;
              }
            }
          }
          previousPrice = currentPrice;
        }

        if (violatesMonotonicity) {
          errors.isValid = false;
          errors.affectedRows?.push(row.id);
        }
      });

      if (!errors.isValid) {
        errors.errorMessage = `For ${context?.transaction} bids, segment prices must be monotonically ${directionText}.`;
      }

      return errors;
    },
  },
];

export const runValidations = (
  selectedRows: GridRow[],
  columns: GridColDef[],
  context?: ValidationContext
): ValidationResult[] => {
  return validationRules
    .map((rule) => rule.validate(selectedRows, columns, context))
    .filter((result) => !result.isValid);
};