export const tabBlue = "#1f77b4";
export const tabOrange = "#ff7f0e";
export const tabGreen = "#2ca02c";
export const tabRed = "#d62728";
export const tabPurple = "#9467bd";
export const tabBrown = "#8c564b";
export const tabPink = "#e377c2";
export const tabGray = "#7f7f7f";
export const tabOlive = "#bcbd22";
export const tabCyan = "#17becf";

export const lightTabRed = "#FFBCBD";
