import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";

import AuthContext from "../store/auth-context";

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const emailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredEmail = event.target.value;
    setEmail(enteredEmail);
  };

  const passwordChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const enteredPassword = event.target.value;
    setPassword(enteredPassword);
  };

  const submitLoginInformationHandler = async () => {
    setIsLoading(true);

    try {
      await authCtx.login(email, password);
      navigate("/dashboard", { replace: true });
    } catch (error: any) {
      alert(error);
    }

    setIsLoading(false);
  };

  return (
    <Card>
      <CardHeader title="Login" />
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Email"
              variant="outlined"
              onChange={emailChangeHandler}
              type="email"
              sx={{ width: { xs: "100%", md: "50%" } }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Password"
              variant="outlined"
              onChange={passwordChangeHandler}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  submitLoginInformationHandler();
                }
              }}
              type="password"
              sx={{ width: { xs: "100%", md: "50%" } }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        {!isLoading && (
          <Button variant="contained" onClick={submitLoginInformationHandler}>
            Login
          </Button>
        )}
      </CardActions>
      {isLoading && <LinearProgress />}
    </Card>
  );
};

export default LoginForm;
