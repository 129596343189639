import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  TextField,
} from "@mui/material";
import Card from "@mui/material/Card";
import { DatePicker } from "@mui/x-date-pickers";
import { useState, useRef } from "react";
import { CSVLink } from "react-csv";
import { getLocalDateStringFromDate } from "../../utils/datetime";
import fetchGCP from "../../utils/fetchGCP";

const BroncoDownload = () => {
  const [date, setDate] = useState<string | null>("");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [csvData, setCsvData] = useState<string>("");

  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const handleDateChange = (value: string | null) => {
    if (!value) {
      return;
    }
    setDate(value);
    setSubmitDisabled(false);
  };

  //This will all be in UTC time given back from the server
  const submitDataRequest = async () => {
    if (!date) {
      return;
    }
    var temp = getLocalDateStringFromDate(new Date(date));

    // This submission is dogshit right now. We have no error handling so we can return a format that is not expected
    // There is no documentation on which value is coming from where
    // The value of REV_DAY_AFTER_GEN is in MWH
    // The value of the other fields are in MW
    // There are warnings on that function all over the place
    // TODO: Fix this shit Shane
    setSubmitDisabled(true);
    const url = `${process.env.REACT_APP_ENDPOINTS_URL}/bronco-download-request?date=${temp}`;

    console.log(url);

    const response = await fetchGCP(url);
    const mapTotal = new Map();
    const fetchedData = await response.json();

    // ...

    // const fetchedData = await response.json();
    // Convert JSON data to CSV
    const csvFields = [
      "Datetime",
      "CES_total",
      "SFTP_ACT_PWR",
      "REV_DAY_AFTER_GEN", //This is in MWH
      "Guzman\\Bronco 1|MW",
      "Guzman\\Bronco 2|MW",
      "kepserver_total",
      "WBO2_SETPT",
      "WBO2_GUZ_SETPT",
    ];

    // this will take a map and then add all the key value pairs to the map
    for (let i = 1; i < csvFields.length; i++) {
      Array(fetchedData[csvFields[i]]).forEach((item: any) => {
        for (const [key, value] of Object.entries(item)) {
          mapTotal.set(key, {
            ...mapTotal.get(key),
            [csvFields[i]]: value,
          });
        }
      });
    }

    const mapTotalAsc = new Map([...mapTotal.entries()].sort());

    var csvData = csvFields.join(",");

    mapTotalAsc.forEach((value, key) => {
      csvData +=
        "\n" + new Date(parseInt(key)) + "," + Object.values(value).join(",");
    });

    setCsvData(csvData);

    //Request has been made so time to clean up
    setSubmitDisabled(false);

    //Need to wait for csv to grab memory
    await new Promise((r) => setTimeout(r, 1000));

    csvLink?.current?.link.click();
  };

  return (
    <Card>
      <CardHeader />
      <CardContent>
        <Stack direction="column" spacing={2} justifyContent="center">
          <DatePicker
            onChange={(newValue) => handleDateChange(newValue)}
            value={date}
            renderInput={(params) => <TextField {...params} />}
          ></DatePicker>
        </Stack>
      </CardContent>
      <CardActions>
        <CSVLink
          data={csvData}
          filename={`Bronco_data_${date}.csv`}
          className="csvLinkNoStyle"
          ref={csvLink}
          uFEFF={false}
        />
        <Button
          variant="contained"
          disabled={submitDisabled}
          onClick={submitDataRequest}
        >
          Submit
        </Button>
      </CardActions>
    </Card>
  );
};

export default BroncoDownload;
