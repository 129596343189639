import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const rows = [
  {
    service: "EQR Data Studio",
    url: "https://datastudio.google.com/s/nmbpQhB-Q9A",
  },
  {
    service: "webTrader production",
    url: "https://www.gpm.oati.com/gpm/login",
  },
  { service: "webTrader development", url: "https://dev.gpm.oati.com/gpm/" },
  {
    service: "webSmartTag",
    url: "https://www.tag.oati.com/oati/ets-login.wml",
  },
  { service: "Trident", url: "https://greptrident.com/login" },
  {
    service: "Actuals tracker Google Sheets",
    url: "https://docs.google.com/spreadsheets/d/1DwLg6ZyL02ZmTzo8vzyXB6UBuD0_Dq7NkVIyzW8E7iQ/edit?usp=sharing",
  },
  {
    service: "PSCO curtailments Google Sheets",
    url: "https://docs.google.com/spreadsheets/d/1uRslVhGf5-Wafn0rn0CyedSG4TrCqbFDvimcunCkK7E/edit?usp=sharing",
  },
  { service: "PRT", url: "https://www.prt-inc.com/eload/forecast/362/home/" },
  { service: "MystAI", url: "https://app.myst.ai" },
  {
    service: "SPP Integrated Marketplace",
    url: "https://marketplace-mte.itespp.org",
  },
  { service: "myPlant", url: "https://myplant.io/en/login" },
  {
    service: "YesEnergy Snowflake",
    url: "https://goa98624.us-east-1.snowflakecomputing.com/console/login#/",
  },
  {
    service: "Data Analytics Confluence",
    url: "https://guzmanenergy.atlassian.net/wiki/spaces/DATA/overview",
  },
  {
    service: "Data Analytics Jira",
    url: "https://guzmanenergy.atlassian.net/jira/projects",
  },
  { service: "Energy GPS", url: "https://www.energygps.com/Account/LogOn" },
  { service: "Ansergy", url: "https://wp.ansergy.com/login-to-continue/" },
  {
    service: "Guzman Energy Asana",
    url: "https://app.asana.com/0/1162071947872569/overview",
  },
];

const LinksTable = () => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Service</TableCell>
            <TableCell align="left">URL</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.service}>
              <TableCell align="left">{row.service}</TableCell>
              <TableCell align="left">
                <a href={row.url} target="_blank" rel="noreferrer">
                  {row.url}
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LinksTable;
