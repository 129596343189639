import { useEffect, useState } from "react";

import DashboardTableCard from "./dashboardTableCard";
import { RealtimeData } from "../../models/dashboard";

const header = ["Status", "Entity", "Latest timestamp", "Latest load"];
const entities = ["HCE", "DMEA", "KCEC", "Raton", "Jicarilla", "Acoma"];

const RealtimeLoad = () => {
  const [rows, setRows] = useState<RealtimeData[]>([]);

  useEffect(() => {
    const fetchData = async (index: number) => {
      const url = `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/loads/actuals?entity=${entities[index].toLowerCase()}`;
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_AWS_API_GATEWAY_API_KEY!,
          },
        });
        if (response.ok) {
          // When API succeeds
          const fetchedData = await response.json();

          const t = new Date(fetchedData[0].index);
          // 3 hours threshold
          const status =
            new Date().getTime() - t.getTime() < 3 * 60 * 60 * 1000;

          const row: RealtimeData = {
            id: index.toString(),
            status: status,
            entity: entities[index],
            latestTimestamp: new Date(fetchedData[0].index).toLocaleString(),
            latestValue: fetchedData[0].value,
          };
          setRows((prevRows) => [...prevRows, row]);
        } else {
          const row = {
            id: index.toString(),
            status: false,
            entity: entities[index],
            latestTimestamp: "N/A",
            latestValue: null,
          };
          setRows((prevRows) => [...prevRows, row]);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
        return;
      }
    };
    // Clear state because the array inflates every time save code during development
    setRows([]);
    for (let i = 0; i < entities.length; i++) {
      fetchData(i);
    }
  }, []);

  return (
    <DashboardTableCard
      title={"Real-time load"}
      rows={rows}
      header={header}
      isLoading={rows.length !== entities.length ? true : false}
    />
  );
};

export default RealtimeLoad;
