import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { getDefaultDate } from "../../utils/datetime";

const entities = [
  { key: "HCE", value: "hce" },
  { key: "DMEA", value: "dmea" },
  { key: "KCEC", value: "kcec" },
  { key: "Aztec", value: "aztec" },
  { key: "Raton", value: "raton" },
  { key: "Jicarilla", value: "jicarilla" },
  { key: "Acoma", value: "acoma" },
];

const loadTypes = [
  // { key: "Gross load", value: "gross" },
  { key: "Net load", value: "net" },
];

// Our models for longterm forecasts are monthly since 2023 Aug
const getMonthsSinceAug2023 = (startDate: Date) => {
  var temp =
    startDate.getUTCFullYear() +
    (startDate.getUTCMonth() + 1).toString().padStart(2, "0");
  var index = 0;
  var out = [];

  //Safety stop tryin to load if we are ten years out
  var breaker = 0;
  while (temp > "202307" && breaker < 144) {
    breaker++;
    // console.log(temp);
    index++;
    out.push({ key: temp, value: temp });
    // Handle the case where we go from Jan to Dec
    if (startDate.getUTCMonth() + 1 - index < 1) {
      startDate.setUTCFullYear(startDate.getUTCFullYear() - 1);
      startDate.setUTCMonth(11);
      index = 0;
    }
    temp =
      startDate.getUTCFullYear() +
      (startDate.getUTCMonth() + 1 - index).toString().padStart(2, "0");
  }
  return out;
};

const models = getMonthsSinceAug2023(new Date(Date.now()));

//This needs to also be pulled in from bq or somewhere so we have a real list
//Should have a query that will chace this in firebase and then just pull it from there
const scenarios = [
  { key: "2015", value: "2015" },
  { key: "2016", value: "2016" },
  { key: "2017", value: "2017" },
  { key: "2018", value: "2018" },
  { key: "2019", value: "2019" },
  { key: "2020", value: "2020" },
  { key: "2021", value: "2021" },
  { key: "2022", value: "2022" },
];

interface Props {
  setMonthlyTotal: any;
  setMonthlyPeak: any;
  setHourlySummer: any;
  setHourlyWinter: any;
  isLoading: boolean;
  setIsLoading: any;
  generateMetadata: any;
  setSettingData: any;
}

const LongTermLoadForecastSettings: React.FC<Props> = (props) => {
  const [checked, setChecked] = useState(
    new Array(entities.length).fill(false),
  );
  const [loadType, setLoadType] = useState(loadTypes[0].value);
  const [model, setModel] = useState(models[0].value);
  const [scenario, setScenario] = useState<string[]>([scenarios[3].value]);
  const [start, setStart] = useState<Date | null>(getDefaultDate(-365));
  const [end, setEnd] = useState<Date | null>(getDefaultDate(365));

  const changeLoadType = (event: SelectChangeEvent) => {
    setLoadType(event.target.value as string);
  };

  const changeModel = (event: SelectChangeEvent) => {
    setModel(event.target.value as string);
  };

  const settingDataPassUp = () => {
    const selectedEntities: string[] = [];
    for (let i = 0; i < entities.length; i++) {
      if (checked[i]) {
        selectedEntities.push(entities[i].value);
      }
    }
    const entity = selectedEntities.join(",");
    const s = start?.toISOString()!;
    const e = end?.toISOString()!;

    props.setSettingData({
      entity: entity,
      load: loadType,
      model: model,
      scenario: scenario,
      start: s,
      end: e,
    });
  };

  const createURLS = () => {
    const selectedEntities: string[] = [];
    for (let i = 0; i < entities.length; i++) {
      if (checked[i]) {
        selectedEntities.push(entities[i].value);
      }
    }
    const entity = selectedEntities.join(",");
    const s = start?.toISOString()!;
    const e = end?.toISOString()!;

    const baseUrl = process.env.REACT_APP_AWS_API_GATEWAY_URL;
    const scenarioParam = scenario[0];
    const urls = [
      // Actual load monthly total
      `${baseUrl}/loads/actuals?entity=${entity}&load=${loadType}&aggregation=monthly-total&start=${s}&end=${e}`,
      // Backcast forecast monthly total
      `${baseUrl}/loads/forecasts/long-term?forecast=backcast&entity=${entity}&load=${loadType}&model=${model}&aggregation=monthly-total&start=${s}&end=${e}`,
      // Future forecast monthly total
      `${baseUrl}/loads/forecasts/long-term?forecast=future&entity=${entity}&load=${loadType}&model=${model}&scenario=${scenarioParam}&aggregation=monthly-total&start=${s}&end=${e}`,

      // Actual load monthly peak
      `${baseUrl}/loads/actuals?entity=${entity}&load=${loadType}&aggregation=monthly-peak&start=${s}&end=${e}`,
      // Backcast forecast monthly peak
      `${baseUrl}/loads/forecasts/long-term?forecast=backcast&entity=${entity}&load=${loadType}&model=${model}&aggregation=monthly-peak&start=${s}&end=${e}`,
      // Future forecast monthly peak
      `${baseUrl}/loads/forecasts/long-term?forecast=future&entity=${entity}&load=${loadType}&model=${model}&scenario=${scenarioParam}&aggregation=monthly-peak&start=${s}&end=${e}`,

      // Actual hourly summer
      `${baseUrl}/loads/actuals?entity=${entity}&load=${loadType}&aggregation=hourly-summer&start=${s}&end=${e}`,
      // Backcast forecast hourly summer
      `${baseUrl}/loads/forecasts/long-term?forecast=backcast&entity=${entity}&load=${loadType}&model=${model}&aggregation=hourly-summer&start=${s}&end=${e}`,
      // Future forecast hourly summer
      `${baseUrl}/loads/forecasts/long-term?forecast=future&entity=${entity}&load=${loadType}&model=${model}&scenario=${scenarioParam}&aggregation=hourly-summer&start=${s}&end=${e}`,

      // Actual hourly winter
      `${baseUrl}/loads/actuals?entity=${entity}&load=${loadType}&aggregation=hourly-winter&start=${s}&end=${e}`,
      // Backcast forecast hourly winter
      `${baseUrl}/loads/forecasts/long-term?forecast=backcast&entity=${entity}&load=${loadType}&model=${model}&aggregation=hourly-winter&start=${s}&end=${e}`,
      // Future forecast hourly winter
      `${baseUrl}/loads/forecasts/long-term?forecast=future&entity=${entity}&load=${loadType}&model=${model}&scenario=${scenarioParam}&aggregation=hourly-winter&start=${s}&end=${e}`,
    ];

    for (let index = 1; index < scenario.length; index++) {
      const element = scenario[index];
      urls.push(
        `${baseUrl}/loads/forecasts/long-term?forecast=future&entity=${entity}&load=${loadType}&model=${model}&scenario=${element}&aggregation=monthly-total&start=${s}&end=${e}`,
      );
      urls.push(
        `${baseUrl}/loads/forecasts/long-term?forecast=future&entity=${entity}&load=${loadType}&model=${model}&scenario=${element}&aggregation=monthly-peak&start=${s}&end=${e}`,
      );
      urls.push(
        `${baseUrl}/loads/forecasts/long-term?forecast=future&entity=${entity}&load=${loadType}&model=${model}&scenario=${element}&aggregation=hourly-summer&start=${s}&end=${e}`,
      );
      urls.push(
        `${baseUrl}/loads/forecasts/long-term?forecast=future&entity=${entity}&load=${loadType}&model=${model}&scenario=${element}&aggregation=hourly-winter&start=${s}&end=${e}`,
      );
    }

    return urls;
  };

  const updateDataHandler = async () => {
    props.setIsLoading(true);
    settingDataPassUp();

    //Get the URLS for every scenario
    var urls: string[] = [];
    urls.push(...createURLS());

    // console.log("entity", paramEntity);
    // console.log("load", loadType);
    // console.log("model", model);
    // console.log("scenario", scenario);
    // console.log("start", start);
    // console.log("end", end);
    // console.log(urls);

    const mapTotal = new Map();
    const mapPeak = new Map();
    const mapHourlySummer = new Map();
    const mapHourlyWinter = new Map();
    const names = [
      "Actual",
      "Backcast",
      `Forecast${scenario[0]}`,
      "Actual",
      "Backcast",
      `Forecast${scenario[0]}`,
      "Actual",
      "Backcast",
      `Forecast${scenario[0]}`,
      "Actual",
      "Backcast",
      `Forecast${scenario[0]}`,
    ];

    for (let index = 1; index < scenario.length; index++) {
      names.push("Forecast" + scenario[index]);
      names.push("Forecast" + scenario[index]);
      names.push("Forecast" + scenario[index]);
      names.push("Forecast" + scenario[index]);
    }

    await Promise.all(
      urls.map(async (url, index) => {
        // console.log(`getting url : ${url}`);
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_AWS_API_GATEWAY_API_KEY!,
          },
        });
        const fetchedData = await response.json();

        if (!response.ok) {
          props.setIsLoading(false);
          throw new Error(fetchedData.error.message);
        }

        // Debugging
        // console.log(url, fetchedData);

        const plotIndex = index % 4;

        // Rename
        fetchedData.forEach((data: any) => {
          data[names[index]] = data["value"];
          delete data["value"];
        });

        // Merge
        // Monthly total
        if ((index >= 0 && index < 3) || (index >= 12 && plotIndex === 0)) {
          fetchedData.forEach((item: any) => {
            mapTotal.set(item.index, { ...mapTotal.get(item.index), ...item });
          });
        }
        // Monthly Peak
        else if (
          (index >= 3 && index < 6) ||
          (index >= 12 && plotIndex === 1)
        ) {
          fetchedData.forEach((item: any) =>
            mapPeak.set(item.index, { ...mapPeak.get(item.index), ...item }),
          );
        }
        const hourEndingToMntTz = (item: any) => {
          const hour =
            parseInt(
              new Date(Date.now()).toLocaleString("en-US", {
                hour: "2-digit",
                hour12: false,
                timeZone: "America/Denver",
              }),
            ) -
            parseInt(
              new Date().toLocaleString("en-US", {
                hour: "2-digit",
                hour12: false,
                timeZone: "UTC",
              }),
            );
          var he = parseInt(item.index) + hour;
          if (he < 0) {
            he = 24 + he;
          }
          item.index = he.toString();
        };
        // Summer Profile
        if ((index >= 6 && index < 9) || (index >= 12 && plotIndex === 2)) {
          fetchedData.forEach((item: any) => {
            hourEndingToMntTz(item);
            mapHourlySummer.set(item.index, {
              ...mapHourlySummer.get(item.index),
              ...item,
            });
          });
        }
        // Winter Profile
        if ((index >= 9 && index < 12) || (index >= 12 && plotIndex === 3)) {
          fetchedData.forEach((item: any) => {
            hourEndingToMntTz(item);
            mapHourlyWinter.set(item.index, {
              ...mapHourlyWinter.get(item.index),
              ...item,
            });
          });
        }

        return fetchedData;
      }),
    );

    // console.log("Got responses");
    // console.log(mapTotal.entries());

    // Monthly total
    const mapTotalAsc = new Map([...mapTotal.entries()].sort());
    const mergedTotal = Array.from(mapTotalAsc.values());
    props.setMonthlyTotal(mergedTotal);

    // console.log("Monthly total", mergedTotal);

    // Monthly Peak
    const mapPeakAsc = new Map([...mapPeak.entries()].sort());
    const mergedPeak = Array.from(mapPeakAsc.values());
    props.setMonthlyPeak(mergedPeak);

    // console.log("Monthly peak", mergedPeak);

    // Summer Profile
    const mapHourlySummerAsc = new Map(
      [...mapHourlySummer.entries()].sort((a, b) => a[0] - b[0]),
    );
    const mergedHourlySummer = Array.from(mapHourlySummerAsc.values());
    props.setHourlySummer(mergedHourlySummer);

    // console.log("Hourly summer", mergedHourlySummer);

    // Winter Profile
    const mapHourlyWinterAsc = new Map(
      [...mapHourlyWinter.entries()].sort((a, b) => a[0] - b[0]),
    );
    const mergedHourlyWinter = Array.from(mapHourlyWinterAsc.values());
    props.setHourlyWinter(mergedHourlyWinter);

    // console.log("Hourly winter", mergedHourlyWinter);
    props.generateMetadata(scenario);
    props.setIsLoading(false);
  };

  const changeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const updatedChecked = [...checked];
    updatedChecked[index] = event.target.checked;
    setChecked(updatedChecked);
  };

  const handleChangeScenario = (event: SelectChangeEvent<typeof scenario>) => {
    const {
      target: { value },
    } = event;
    setScenario(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  useEffect(() => {}, []);

  return (
    <Card variant="outlined" sx={{ borderColor: "primary.main" }}>
      <CardHeader title="Settings" />
      <CardContent>
        <Stack spacing={2}>
          <FormGroup row={true}>
            {entities.map((e, i) => (
              <FormControlLabel
                key={e.key}
                control={
                  <Checkbox
                    checked={checked[i]}
                    onChange={(event) => changeCheckbox(event, i)}
                  />
                }
                label={e.key}
              />
            ))}
          </FormGroup>

          <Stack direction="row" spacing={2}>
            <FormControl>
              <InputLabel>Load type</InputLabel>
              <Select
                value={loadType}
                label="Load type"
                onChange={changeLoadType}
              >
                {loadTypes.map((e) => (
                  <MenuItem
                    key={e.key}
                    value={e.value}
                    disabled={e.key === "Net Load" ? true : false}
                  >
                    {e.key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel>Model</InputLabel>
              <Select value={model} label="Model" onChange={changeModel}>
                {models.map((e) => (
                  <MenuItem key={e.key} value={e.value}>
                    {e.key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel>Scenario</InputLabel>
              <Select
                multiple
                value={scenario}
                label="Scenarios"
                placeholder="2022"
                onChange={handleChangeScenario}
              >
                {scenarios.map((e) => (
                  <MenuItem key={e.key} value={e.value}>
                    {e.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start"
                value={start}
                onChange={(newValue) => {
                  setStart(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />

              <DatePicker
                label="End"
                value={end}
                onChange={(newValue) => {
                  setEnd(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Stack>
        </Stack>
      </CardContent>
      <CardActions>
        {!props.isLoading && (
          <Button variant="contained" onClick={updateDataHandler}>
            OK
          </Button>
        )}
        {props.isLoading && (
          <Button disabled variant="contained">
            <CircularProgress size={24} />
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default LongTermLoadForecastSettings;
