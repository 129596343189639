import React, { useEffect, useState, useRef, Fragment } from "react";
import { Helmet } from "react-helmet";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import fetchGCP from "../../utils/fetchGCP";

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

interface TableauEmbedProps {
  resourceUrl: string;
  tokenUrl?: string;
}

const TableauEmbed: React.FC<TableauEmbedProps> = ({
  resourceUrl,
  tokenUrl = `${process.env.REACT_APP_ENDPOINTS_URL}/tableau-jwt`,
}) => {
  const [viz, setViz] = useState<React.ReactNode>();
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>();
  const [width] = useState(getWidth());

  const vizRef = useRef<HTMLDivElement>(null);

  const loadViz = () => {
    const showMobile = width <= 1050;

    setViz(
      <tableau-viz
        ref={vizRef}
        id="tableauViz"
        src={resourceUrl}
        device={showMobile ? "phone" : "desktop"}
        hide-tabs={false}
        token={token}
        toolbar="top"
        height={"100%"}
      />,
    );
  };

  useEffect(() => {
    const fetchJWT = async () => {
      try {
        const res = await fetchGCP(tokenUrl);
        if (res.ok) {
          const resJson = await res.json();
          setToken(resJson.token);
        }
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    fetchJWT().catch(console.error);
  }, [tokenUrl]);

  useEffect(() => {
    if (token) {
      loadViz();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (loading) return <CircularProgress />;
  if (error) return <Typography>Error: {JSON.stringify(error)}</Typography>;

  return (
    <Fragment>
      <Helmet>
        <script
          type="module"
          src="https://public.tableau.com/javascripts/api/tableau.embedding.3.1.0.min.js"
          async
        ></script>
      </Helmet>
      <div style={{ width: "100%", height: "100%" }} className="mx-0">
        {viz}
      </div>
    </Fragment>
  );
};

export default TableauEmbed;
