import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import WeccOutagesForm from "../components/wecc-outages/WeccOutagesForm";

const WeccOutages = () => {
  return (
    <Grid container spacing={2} p={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" component="div" align="center">
          Wecc Outages
        </Typography>
      </Grid>

      <Grid item sx={{ width: "100%", height: "100%" }}>
        <WeccOutagesForm />
      </Grid>
    </Grid>
  );
};

export default WeccOutages;
