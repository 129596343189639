import React, { Fragment, useState } from "react";
import TableauEmbed from "../components/tableau-integration/TableauEmbed";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { CardActionArea, Modal } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const TableauHomePage: React.FC = () => {
  const tableauDashboards = {
    "Generation Summary":
      "https://us-west-2b.online.tableau.com/t/guzmanenergy/views/GenerationDashboard/GenerationSummary",
    "Curtailment Summary":
      "https://us-west-2b.online.tableau.com/t/guzmanenergy/views/HCEMissileSiteCurtailmentSummaryNewLogic/CurtailmentSummary",
    "Generation Download":
      "https://us-west-2b.online.tableau.com/t/guzmanenergy/views/GenerationDashboard/ExcelDownload",
    "Curtailment Download":
      "https://us-west-2b.online.tableau.com/t/guzmanenergy/views/HCEMissileSiteCurtailmentSummaryNewLogic/excel_download",
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [modalUrl, setModalUrl] = useState<string>("");

  const handleOpen = (url: string) => {
    setModalUrl(url);
    setModalVisible(true);
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  return (
    <Fragment>
      <Grid container spacing={4} padding={4}>
        <Grid
          item
          container
          xs={12}
          alignContent={"center"}
          justifyContent={"center"}
        >
          <Typography variant="h3" component="div" align="center">
            Tableau Dashboards
          </Typography>
        </Grid>
        {Object.entries(tableauDashboards).map(([name, url]) => (
          <Grid item container xs={3}>
            <Card sx={{ width: "100%" }}>
              <CardActionArea onClick={() => handleOpen(url)}>
                <CardHeader
                  title={name}
                  titleTypographyProps={{ textAlign: "center" }}
                />
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Modal open={modalVisible} onClose={handleClose}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          onClick={handleClose}
        >
          <Box
            width="90%"
            bgcolor="background.paper"
            boxShadow={24}
            p={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={"90%"}
          >
            <TableauEmbed resourceUrl={modalUrl} />
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default TableauHomePage;
