import { useState, useEffect, useCallback } from "react";

import TimeSeriesPlotCard from "../data-visualization/TimeSeriesPlotCard";
import {
  getDefaultDate,
  dateToDatetimeString,
  computeRmse,
  computeMae,
  computeMape,
} from "../../utils/shared";
import { tabBlue, tabOrange } from "../../utils/style";
import fetchGCP from "../../utils/fetchGCP";

const metaData = [
  { name: "actualLoad", color: tabBlue },
  { name: "scheduleLoad", color: tabOrange },
];

const WeisActualScheduleLoad: React.FC<{ entity: string }> = (props) => {
  const [start, setStart] = useState<Date | null>(getDefaultDate(-2 * 7));
  const [end, setEnd] = useState<Date | null>(getDefaultDate(-7));
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rmse, setRmse] = useState<number>();
  const [mae, setMae] = useState<number>();
  const [mape, setMape] = useState<number>();

  const fetchActualScheduleLoad = useCallback(async () => {
    setIsLoading(true);

    const url = `${
      process.env.REACT_APP_ENDPOINTS_URL
    }/energy-imbalance/actual-schedule-load?entity=${
      props.entity
    }&start=${start?.toISOString()}&end=${end?.toISOString()}`;

    const reponse = await fetchGCP(url);
    const fetchedData = await reponse.json();

    const trues: number[] = [];
    const preds: number[] = [];
    fetchedData.forEach(
      (element: {
        index: string | Date;
        actualLoad: number;
        scheduleLoad: number;
      }) => {
        element.index = dateToDatetimeString(new Date(element.index));
        if (element.actualLoad !== null && element.scheduleLoad !== null) {
          trues.push(element.actualLoad);
          preds.push(element.scheduleLoad);
        }
      },
    );

    setRmse(computeRmse(trues, preds));
    setMae(computeMae(trues, preds));
    setMape(computeMape(trues, preds));
    setData(fetchedData);
    setIsLoading(false);
  }, [start, end, props.entity]);

  useEffect(() => {
    fetchActualScheduleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TimeSeriesPlotCard
      title="WEIS actual load vs. schedule load"
      subheader={`RMSE: ${Math.round(rmse!)}KW, MAE: ${Math.round(
        mae!,
      )}KW, MAPE: ${(mape! * 100).toFixed(1)}%`}
      isLoading={isLoading}
      start={start}
      setStart={setStart}
      end={end}
      setEnd={setEnd}
      data={data}
      metaData={metaData}
      fetchData={fetchActualScheduleLoad}
    />
  );
};

export default WeisActualScheduleLoad;
