import Grid from "@mui/material/Grid";

import CurtailmentDetail from "../../components/operation/crypto-curtailment/curtailment-detail";

const SubmitCurtailmentPage = () => {
  return (
    <Grid container justifyContent="center" p={2}>
      <Grid item xs={12} md={6}>
        <CurtailmentDetail />
      </Grid>
    </Grid>
  );
};

export default SubmitCurtailmentPage;
