import React, { useState, useEffect } from "react";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { firebaseApp } from "../../initializeFirebase";
import Papa from "papaparse";
import { Polygon, Popup, useMapEvent } from "react-leaflet";

/** Pulling in Load service areas from Firebase to display on map  */

interface LoadAreasProps {
  load: string;
  setLoad: React.Dispatch<React.SetStateAction<string>>;
  paths: { [key: string]: string[] }[];
  setPaths: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }[]>>;
  substations: { [key: string]: [number, number] };
  setSubstations: React.Dispatch<
    React.SetStateAction<{ [key: string]: [number, number] }>
  >;
}

const LoadAreas: React.FC<LoadAreasProps> = ({
  load,
  setLoad,
  paths,
  setPaths,
  substations,
  setSubstations,
}) => {
  type LoadColorKeys = keyof typeof loadColors;

  type SelectedArea = {
    name: string;
    coords: [number, number][][];
    isHCEA?: boolean;
    isUnited?: boolean;
  } | null;

  const [loadData, setLoadData] = useState<
    { loadName: string; coords: [number, number][] }[]
  >([]);
  const [selectedArea, setSelectedArea] = useState<SelectedArea>(null);

  const loadColors = {
    United: "#ce7451",
    HolyCross: "#a97e15",
    DMEA: "#51684e",
    KitCarson: "#1a4a4e",
    ARPA: "#53304e",
    Fountain: "#562e19",
    Gallup: "#9f6210",
    Raton: "#79bedb",
    Aztec: "#b4aa8d",
    Jicarilla: "#79bedb",
    Acoma: "#bbcdb5",
    LaPlata: "#8da8a1",
    GrandValley: "#ce7451",
    YampaValley: "#60626b",
    MountainPark: "#384e1d",
  };

  const loadZones = {
    "CO W": {
      color: "red",
      loads: [
        "DMEA",
        "MountainPark",
        "YampaValley",
        "GrandValley",
        "LaPlata",
        "HolyCross",
      ],
    },
    "CO E": {
      color: "blue",
      loads: ["United"],
    },
    "CO S": {
      color: "green",
      loads: ["Fountain", "ARPA", "Raton"],
    },
    NM: {
      color: "purple",
      loads: ["Acoma", "KitCarson", "Jicarilla", "Aztec", "Gallup"],
    },
  };

  // handling load region separation
  const getBorderColor = (loadName: string): string => {
    for (const zone of Object.values(loadZones)) {
      if (zone.loads.includes(loadName)) {
        return zone.color;
      }
    }
    return "black";
  };

  const handleSelectLoad = (
    loadName: string,
    coords: [number, number][][],
    isHCEA: boolean = false,
    isUnited: boolean = false,
  ) => {
    setSelectedArea({ name: loadName, coords, isHCEA, isUnited });
    setLoad(loadName);
  };

  const handleDeselectLoad = () => {
    setSelectedArea(null);
    setLoad("");
    setPaths([]);
    setSubstations({});
  };

  useEffect(() => {
    const fetchLoadData = async () => {
      try {
        const storage = getStorage(firebaseApp);
        const listRef = ref(storage, "map-data/load-areas");
        const result = await listAll(listRef);

        // Iterating through different loads
        const csvContents = await Promise.all(
          result.items.map(async (item) => {
            const url = await getDownloadURL(item);
            const response = await fetch(url);
            const text = await response.text();
            return {
              csvContent: text,
              loadName: item.name.split("-Load-Area")[0],
            };
          }),
        );

        const parsedData: { loadName: string; coords: [number, number][] }[] =
          [];

        // parsing lat + long
        for (const { csvContent, loadName } of csvContents) {
          const coords: [number, number][] = [];
          Papa.parse(csvContent, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
              results.data.forEach((row: any) => {
                const lat = parseFloat(row.lat);
                const long = parseFloat(row.long);
                coords.push([lat, long]);
              });
            },
            error: (error: Error) => {
              console.error(`Error parsing CSV for ${loadName}`, error);
            },
          });
          parsedData.push({ loadName, coords });
        }

        setLoadData(parsedData);
      } catch (error: any) {
        console.error("Error fetching load data", error);
      }
    };

    fetchLoadData();
  }, []);

  // handling HCEA and United which have multiple load areas
  const getGroupedAreas = (loadName: string): [number, number][][] => {
    return loadData
      .filter((load) => load.loadName === loadName)
      .map((load) => load.coords);
  };

  useMapEvent("click", () => {
    handleDeselectLoad();
  });

  return (
    <div>
      {selectedArea
        ? // Handling rendering with selected Load
          selectedArea.coords.map((coords, index) => (
            <Polygon
              key={`${selectedArea.name}-${index}`}
              pathOptions={{
                color: getBorderColor(selectedArea.name),
                fillColor: loadColors[selectedArea.name as LoadColorKeys],
                weight: 3,
                opacity: 1,
                fillOpacity: 0.7,
              }}
              positions={coords}
            >
              <Popup>
                <h4>{selectedArea.name + " Load Area"}</h4>
              </Popup>
            </Polygon>
          ))
        : loadData.map((load, index) => (
            <Polygon
              key={`${load.loadName}-${index}`}
              pathOptions={{
                color: getBorderColor(load.loadName),
                fillColor: loadColors[load.loadName as LoadColorKeys],
                weight: 3,
                opacity: 1,
                fillOpacity: 0.7,
              }}
              positions={load.coords}
              eventHandlers={{
                click: () => {
                  if (
                    load.loadName === "HolyCross" ||
                    load.loadName === "United"
                  ) {
                    handleSelectLoad(
                      load.loadName,
                      getGroupedAreas(load.loadName),
                    );
                  } else {
                    handleSelectLoad(load.loadName, [load.coords]);
                  }
                },
              }}
            >
              <Popup>
                <h4>{load.loadName + " Load Area"}</h4>
              </Popup>
            </Polygon>
          ))}
    </div>
  );
};

export default LoadAreas;
