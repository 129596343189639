import { useState, useEffect } from "react";

import RadarChartCard from "../data-visualization/RadarChartCard";
import { capitalizeFirstLetter } from "../../utils/shared";
import fetchGCP from "../../utils/fetchGCP";

interface lossRadarData {
  name: string;
  entity: string;
  lossPercent: number;
}

const Loss = () => {
  const [data, setData] = useState<lossRadarData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const url = `${process.env.REACT_APP_ENDPOINTS_URL}/parameter`;
      const response = await fetchGCP(url);
      const fetchedData = await response.json();

      const transformedData: lossRadarData[] = [];
      fetchedData.forEach(
        (element: { entity: string; lossPercent: number }) => {
          transformedData.push({
            name: ["hce", "dmea", "kcec"].includes(element.entity)
              ? element.entity.toUpperCase()
              : capitalizeFirstLetter(element.entity),
            ...element,
          });
        },
      );

      setData(transformedData);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <RadarChartCard
      title="Loss percent on load"
      isLoading={isLoading}
      data={data}
      name="name"
      value="lossPercent"
    />
  );
};

export default Loss;
