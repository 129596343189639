import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { CSVLink } from "react-csv";

import TimeSeriesRangePlot from "../data-visualization/TimeSeriesRangePlot";
import {
  getDefaultDate,
  dateToDatetimeString,
  getUTCDateStringLocalOffset,
} from "../../utils/datetime";
import {
  computeRmse,
  computeMae,
  computeMapeAvgDividedByAvg,
} from "../../utils/math";
import {
  tabBlue,
  tabOrange,
  tabGreen,
  tabRed,
  lightTabRed,
} from "../../utils/style";

const metaData = [
  {
    name: "latestForecastLoad",
    color: tabRed,
    dash: "3 3",
    lineWidth: 3,
    chart: "line",
  },
  { name: "actualLoad", color: tabBlue, chart: "line" },
  {
    name: "hourAheadForecastLoad",
    color: tabOrange,
    dash: "3 3",
    chart: "line",
    lineWidth: 1,
  },
  {
    name: "dayAheadForecastLoad",
    color: tabGreen,
    dash: "3 3",
    chart: "line",
    lineWidth: 1,
  },
  { name: "probabilityBand", color: lightTabRed, chart: "range" },
];

const names = [
  "latestForecastLoad",
  "actualLoad",
  "hourAheadForecastLoad",
  "dayAheadForecastLoad",
  "probabilityBand",
];

interface Props {
  entity: string;
}

// Aztec forecast page is the only one usin this right now
// Might be worth moving it over like the rest
const ShortTermLoadRangeForecast: React.FC<Props> = (props) => {
  const [start, setStart] = useState<Date | null>(getDefaultDate(-7));
  const [end, setEnd] = useState<Date | null>(getDefaultDate(7));
  const [data, setData] = useState<
    { index: string; latestForecastLoad: number; probabilityBand: number[] }[]
  >([]);
  const [downloadData, setDownloadData] = useState<any[]>([]);
  const [rmse, setRmse] = useState<number>();
  const [mae, setMae] = useState<number>();
  const [mape, setMape] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const initializeData = async () => {
      setIsLoading(true);

      // Set parameters
      const endpoint = process.env.REACT_APP_AWS_API_GATEWAY_URL;
      const now = new Date();
      now.setMinutes(0);
      now.setSeconds(0);
      now.setMilliseconds(0);
      const n = now.toISOString();
      const s = getDefaultDate(-7).toISOString();
      const e = getDefaultDate(7).toISOString();

      const urls = [
        // Latest forecast load
        `${endpoint}/loads/forecasts?entity=${props.entity}&start=${n}&end=${e}`,
        // Actual load
        `${endpoint}/loads/actuals?entity=${props.entity}&source=ge&start=${s}&end=${n}`,
        // Hour ahead historical forecast load
        `${endpoint}/loads/forecasts?entity=${props.entity}&start=${s}&end=${n}&hour-ahead=1`,
        // Day ahead historical forecast load
        `${endpoint}/loads/forecasts?entity=${props.entity}&start=${s}&end=${n}&hour-ahead=24`,
        // Latest forecast load range
        `${endpoint}/loads/forecasts/ranges?entity=${props.entity}&start=${s}&end=${e}`,
      ];

      const map = new Map();
      const mapDownloadData = new Map();
      // Metrics
      const trues: number[] = [];
      const preds: number[] = [];
      await Promise.all(
        urls.map(async (url, index) => {
          const response = await fetch(url, {
            method: "GET",
            headers: {
              "x-api-key": process.env.REACT_APP_AWS_API_GATEWAY_API_KEY!,
            },
          });
          const fetchedData = await response.json();

          fetchedData.forEach((data: any) => {
            // Metrics
            if (names[index] === "actualLoad") {
              trues.push(data["value"]);
            } else if (names[index] === "hourAheadForecastLoad") {
              preds.push(data["value"]);
            }

            data[names[index]] = data["value"];
            delete data["value"];

            map.set(data["index"], { ...map.get(data["index"]), ...data });
            mapDownloadData.set(data["index"], {
              ...mapDownloadData.get(data["index"]),
              ...data,
            });
          });

          return fetchedData;
        }),
      );

      const mapAsc = new Map([...map.entries()].sort());
      const merged = Array.from(mapAsc.values());

      mapDownloadData.forEach(
        (element) =>
          (element["index"] = getUTCDateStringLocalOffset(element["index"])),
      );

      const mapAscDownloadData = new Map([...mapDownloadData.entries()].sort());
      const mergedDownloadData = Array.from(mapAscDownloadData.values());
      // Convert UTC index to local datetime string
      merged.forEach(
        (data) =>
          (data["index"] = dateToDatetimeString(new Date(data["index"]))),
      );

      setRmse(computeRmse(trues, preds));
      setMae(computeMae(trues, preds));
      setMape(computeMapeAvgDividedByAvg(trues, preds));
      setDownloadData(mergedDownloadData);
      setData(merged);
      setIsLoading(false);
    };

    initializeData();
  }, [props.entity]);

  const handleStartChange = (newValue: Date | null) => {
    setStart(newValue);
  };

  const handleEndChange = (newValue: Date | null) => {
    setEnd(newValue);
  };

  const updateData = async () => {
    setIsLoading(true);

    // Set parameters
    const endpoint = process.env.REACT_APP_AWS_API_GATEWAY_URL;
    const s = start!.toISOString();
    const e = end!.toISOString();
    const now = new Date();
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    const n = now.toISOString();

    const urls = [
      // Latest forecast load
      `${endpoint}/loads/forecasts?entity=${props.entity}&start=${n}&end=${e}`,
      // Actual load
      `${endpoint}/loads/actuals?entity=${props.entity}&source=ge&start=${s}&end=${e}`,
      // Hour ahead historical forecast load
      `${endpoint}/loads/forecasts?entity=${props.entity}&start=${s}&end=${e}&hour-ahead=1`,
      // Day ahead historical forecast load
      `${endpoint}/loads/forecasts?entity=${props.entity}&start=${s}&end=${e}&hour-ahead=24`,
      // Latest forecast load range
      `${endpoint}/loads/forecasts/ranges?entity=${props.entity}&start=${s}&end=${e}`,
    ];

    const map = new Map();
    const mapDownloadData = new Map();
    // Metrics
    const trues: number[] = [];
    const preds: number[] = [];
    await Promise.all(
      urls.map(async (url, index) => {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_AWS_API_GATEWAY_API_KEY!,
          },
        });
        const fetchedData = await response.json();

        fetchedData.forEach((data: any) => {
          // Metrics
          if (names[index] === "actualLoad") {
            trues.push(data["value"]);
          } else if (names[index] === "hourAheadForecastLoad") {
            preds.push(data["value"]);
          }

          data[names[index]] = data["value"];
          delete data["value"];

          map.set(data["index"], { ...map.get(data["index"]), ...data });
          mapDownloadData.set(data["index"], {
            ...mapDownloadData.get(data["index"]),
            ...data,
          });
        });

        return fetchedData;
      }),
    );

    const mapAsc = new Map([...map.entries()].sort());
    const merged = Array.from(mapAsc.values());

    const mapAscDownloadData = new Map([...mapDownloadData.entries()].sort());
    const mergedDownloadData = Array.from(mapAscDownloadData.values());
    // Convert UTC index to local datetime string
    merged.forEach(
      (data) => (data["index"] = dateToDatetimeString(new Date(data["index"]))),
    );

    setRmse(computeRmse(trues, preds));
    setMae(computeMae(trues, preds));
    // setMape(computeMape(trues, preds));
    setMape(computeMapeAvgDividedByAvg(trues, preds));
    setDownloadData(mergedDownloadData);
    setData(merged);
    setIsLoading(false);
  };

  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardHeader
        title="Short-term load forecast"
        subheader={`RMSE: ${Math.round(rmse!)}KW, MAE: ${Math.round(
          mae!,
        )}KW, MAPE: ${(mape! * 100).toFixed(1)}%`}
      />
      <CardContent>
        <TimeSeriesRangePlot data={data} metaData={metaData} />
      </CardContent>
      <CardActions>
        <Stack direction="row" spacing={2} alignItems="center">
          <DesktopDatePicker
            label="Start"
            inputFormat="MM/dd/yyyy"
            value={start}
            onChange={handleStartChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <DesktopDatePicker
            label="End"
            inputFormat="MM/dd/yyyy"
            value={end}
            onChange={handleEndChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <Button variant="outlined" onClick={updateData}>
            Update
          </Button>
          <CSVLink
            data={downloadData}
            filename="data.csv"
            className="csvLinkNoStyle"
          >
            <Button variant="contained">Download</Button>
          </CSVLink>
        </Stack>
      </CardActions>
      {isLoading && <LinearProgress />}
    </Card>
  );
};

export default ShortTermLoadRangeForecast;
