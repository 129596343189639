import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import {
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Button,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  TableContainer,
  LinearProgress,
} from "@mui/material";
import { getDefaultDate } from "../../utils/shared";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import fetchGCP from "../../utils/fetchGCP";

const TransmissionPathToolForm: React.FC = () => {
  var sourceTemplate = [{ name: "", value: "" }];

  // Source and sink do not have to overlap so also need to pull those in
  var sinkTemplate = [{ name: "", value: "" }];

  const incrementTemplate = [
    { name: "HOURLY", value: "HOURLY" },
    { name: "DAILY", value: "DAILY" },
    { name: "WEEKLY", value: "WEEKLY" },
    { name: "MONTHLY", value: "MONTHLY" },
    { name: "YEARLY", value: "YEARLY" },
  ];

  const pathTemplate = [
    [
      {
        cost: 0,
        path: [{ capacity: 0.0, cost: 0, name: "", sink: "", source: "" }],
      },
    ],
  ];

  const [sourceList, setSourceList] = useState(sourceTemplate);
  const [source, setSource] = useState(sourceTemplate[0].value);
  const [sinkList, setSinkList] = useState(sinkTemplate);
  const [sink, setSink] = useState<string>(sinkTemplate[0].value);
  const [increment, setIncrement] = useState(incrementTemplate[0].value);
  const [minCap, setMinCap] = useState(0);
  const [minPrio, setMinPrio] = useState(0); //priority is the same as nerc curtailment
  const [numPaths, setNumPaths] = useState(0); //The number we want returned from gcf
  const [dateTime, setDateTime] = useState<Date | null>(getDefaultDate(1));
  const [paths, setPaths] = useState(pathTemplate);

  const [isLoading, setIsLoading] = useState(false);

  const [readyToShow, setReadyToShow] = useState(false);

  const handleSourceChange = (event: SelectChangeEvent) => {
    const newSource = event.target.value as string;
    setSource(newSource);
  };

  const handleSinkChange = (event: SelectChangeEvent) => {
    const newSink = event.target.value as string;
    setSink(newSink);
  };

  const handleIncrementChange = (event: SelectChangeEvent) => {
    const newIncrement = event.target.value as string;
    setIncrement(newIncrement);
  };

  const handleMinCapChange = (event: { target: { value: string } }) => {
    const newMinCap = event.target.value as string;
    if (Number.isNaN(parseInt(newMinCap))) {
      setMinCap(0);
    } else {
      setMinCap(parseInt(newMinCap));
    }
  };

  const handleMinPrioChange = (event: { target: { value: string } }) => {
    const newMinPrio = parseInt(event.target.value as string);
    if (Number.isNaN(newMinPrio)) {
      setMinPrio(0);
    } else if (newMinPrio > 7) {
      setMinPrio(7);
    } else {
      setMinPrio(newMinPrio);
    }
  };

  const handleDateTimeChange = (newValue: Date | null) => {
    setDateTime(newValue);
  };

  const handleNumPathsChange = (event: { target: { value: string } }) => {
    const newNumPath = parseInt(event.target.value as string);
    if (Number.isNaN(newNumPath)) {
      setNumPaths(0);
    } else if (newNumPath > 2) {
      setNumPaths(2);
    } else {
      setNumPaths(newNumPath);
    }
  };

  const findRouteButtonPushed = async () => {
    setIsLoading(true);
    const tempDateString =
      dateTime!.getUTCFullYear().toString() +
      (dateTime!.getUTCMonth() + 1).toString().padStart(2, "0") +
      dateTime?.getUTCDate().toString().padStart(2, "0") +
      dateTime?.getUTCHours().toString().padStart(2, "0") +
      dateTime?.getUTCMinutes().toString().padStart(2, "0");

    const url = `${process.env.REACT_APP_ENDPOINTS_URL}/oasis-pathfinder?source=${source}&sink=${sink}&datetime=${tempDateString}&increment=${increment}&minimum_capacity=${minCap}&minimum_priority=${minPrio}&num_of_paths=${numPaths}`;

    //This will 500 error if there is no path. Data available so we need to update this to show real output
    const response = await fetchGCP(url);

    if (response.status !== 200) {
      console.log(`We got error ${response.status} from the server`);
    } else {
      const fetchedData = await response.json();
      setPaths([fetchedData]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const get_BQ_POD_and_POR = async () => {
      setIsLoading(true);
      //get the POD data
      const urlPOD = `${process.env.REACT_APP_ENDPOINTS_URL}/transmission-path-query?point_to_query=POINT_OF_DELIVERY`;
      const response1 = await fetchGCP(urlPOD);
      if (response1.status !== 200) {
        console.log(
          `We got error ${response1.status} from the server in the POD fetch`,
        );
      } else {
        const fetchedData = await response1.json();
        Object.keys(fetchedData).forEach((key) => {
          Object.keys(fetchedData[key]).forEach((value: string) => {
            sourceTemplate.push({
              name: fetchedData[key][value],
              value: fetchedData[key][value],
            });
          });
        });
        setSinkList(sourceTemplate);
      }

      //get the POR data
      const urlPOR = `${process.env.REACT_APP_ENDPOINTS_URL}/transmission-path-query?point_to_query=POINT_OF_RECEIPT`;
      const response2 = await fetchGCP(urlPOR);
      if (response2.status !== 200) {
        console.log(
          `We got error ${response2.status} from the server in the POR fetch`,
        );
      } else {
        const fetchedData = await response2.json();
        Object.keys(fetchedData).forEach((key) => {
          Object.keys(fetchedData[key]).forEach((value: string) => {
            sinkTemplate.push({
              name: fetchedData[key][value],
              value: fetchedData[key][value],
            });
          });
        });

        setSourceList(sinkTemplate);
      }
      setIsLoading(false);
      setReadyToShow(true);
    };
    if (!readyToShow) {
      get_BQ_POD_and_POR();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card>
        <CardHeader title="Path Tool" />
        <CardContent>
          <Stack direction="column" spacing={2} justifyContent="center">
            {/* date time value */}
            <DateTimePicker
              label="DateTime"
              inputFormat="MM/dd/yyyy hh:mm aa"
              value={dateTime}
              onChange={handleDateTimeChange}
              renderInput={(params) => <TextField {...params} />}
            ></DateTimePicker>
            {/* source value */}
            <FormControl>
              <InputLabel id="source-label">Source</InputLabel>
              <Select
                labelId="source-label"
                value={source}
                label="Source"
                onChange={handleSourceChange}
              >
                {sourceList.map((element) => (
                  <MenuItem value={element.value} key={element.value}>
                    {element.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* sink value */}
            <FormControl>
              <InputLabel id="sink-label">Sink</InputLabel>
              <Select
                labelId="sink-label"
                value={sink}
                label="Sink"
                onChange={handleSinkChange}
              >
                {sinkList.map((element) => (
                  <MenuItem value={element.value} key={element.value}>
                    {element.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* increment value */}
            <FormControl>
              <InputLabel id="increment-label">Increment</InputLabel>
              <Select
                labelId="increment-label"
                value={increment}
                label="Increment"
                onChange={handleIncrementChange}
              >
                {incrementTemplate.map((element) => (
                  <MenuItem value={element.value} key={element.value}>
                    {element.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="outlined-number"
              label="Minimum Capacity"
              type="number"
              value={minCap}
              onChange={handleMinCapChange}
              InputProps={{
                inputMode: "numeric",
                inputProps: { min: 0 },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* For the min prio we want a number input that is capped */}
            <TextField
              id="outlined-number"
              label="Nerc Curtailment Priority"
              type="number"
              value={minPrio}
              onChange={handleMinPrioChange}
              InputProps={{
                inputProps: { min: 0, max: 7 },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* For the number of paths we want returned */}
            <TextField
              id="outlined-number"
              label="Number of Extra Paths"
              type="number"
              value={numPaths}
              onChange={handleNumPathsChange}
              InputProps={{
                inputProps: { min: 0, max: 5 },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Stack>
        </CardContent>
        <CardActions>
          <Button variant="contained" onClick={findRouteButtonPushed}>
            Find Route
          </Button>
        </CardActions>
      </Card>
      {isLoading && <LinearProgress />}

      {/* Path return card */}
      <Card>
        <CardContent>
          {paths.map((path) => (
            <>
              {path.map((element) => (
                <>
                  {"Total path cost: " + element.cost}
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow key={element.cost}>
                          <TableCell>POD</TableCell>
                          <TableCell>POR</TableCell>
                          <TableCell>Cap</TableCell>
                          <TableCell>Cost</TableCell>
                          <TableCell width={"40%"}>Name</TableCell>
                        </TableRow>
                      </TableHead>
                      {element.path.map((pth) => (
                        <TableBody>
                          <TableRow
                            key={"Row" + pth.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell>{pth.source}</TableCell>
                            <TableCell>{pth.sink}</TableCell>

                            <TableCell>{pth.capacity}</TableCell>
                            <TableCell>{pth.cost}</TableCell>

                            <TableCell>{pth.name}</TableCell>
                          </TableRow>
                        </TableBody>
                      ))}
                    </Table>
                  </TableContainer>
                </>
              ))}
            </>
          ))}
        </CardContent>
      </Card>
    </>
  );
};

export default TransmissionPathToolForm;
