import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FeatureRequestsForm from "../components/feature-requests/FeatureRequestsForm";

const FeatureRequest = () => {
  return (
    <Grid container spacing={2} p={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" component="div" align="center">
          Feature Request
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <FeatureRequestsForm />
      </Grid>
    </Grid>
  );
};

export default FeatureRequest;
