import React from "react";
import Grid from "@mui/material/Grid";
import PriceHourRow from "./PriceHourRow";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

interface HourlyInputFormProps {
  onPriceChange: (he: number, price: number) => void;
  priceData: Map<number, number | null>;
  onSave: () => void;
  saveLoading: boolean;
  dataLoading: boolean;
  inputLabel?: string;
  inputAdornment?: string;
}

const HourlyInputForm: React.FC<HourlyInputFormProps> = ({
  onPriceChange,
  priceData,
  onSave,
  saveLoading,
  dataLoading,
  inputLabel,
  inputAdornment,
}) => {
  return (
    <Card sx={{ mx: { md: 8 }, px: { md: 2 }, pt: 2 }}>
      <CardContent>
        {dataLoading ? (
          <Grid item container xs={12} justifyContent={"center"}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={"center"} spacing={4}>
            <Grid container item xs={12} columnSpacing={4} rowSpacing={2}>
              {[0, 12].map((hourEndStart) => {
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    columnSpacing={4}
                    rowSpacing={2}
                    key={hourEndStart}
                  >
                    {[0, 6].map((increment) => {
                      return (
                        <Grid
                          container
                          item
                          xs={12}
                          lg={6}
                          spacing={2}
                          key={increment}
                        >
                          {[...priceData.keys()]
                            .sort((a, b) => a - b)
                            .filter(
                              (hourEnd) =>
                                hourEndStart + increment < hourEnd &&
                                hourEnd < hourEndStart + increment + 7,
                            )
                            .map((hourEnd) => (
                              <PriceHourRow
                                priceHour={{
                                  he: hourEnd,
                                  price: priceData.get(hourEnd) ?? null,
                                }}
                                onChangePrice={(newPrice: number) => {
                                  onPriceChange(hourEnd, newPrice);
                                }}
                                title={inputLabel}
                                adornment={inputAdornment}
                                key={hourEnd}
                              />
                            ))}
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              })}
            </Grid>
            {saveLoading ? (
              <Grid item container xs={12} justifyContent={"center"}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                md={4}
                lg={3}
                container
                justifyContent={"center"}
              >
                <Button
                  variant={"contained"}
                  sx={{ width: "80%" }}
                  onClick={onSave}
                >
                  Save
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default HourlyInputForm;
