import {
  ResponsiveContainer,
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";

interface TimeSeriesRangePlotProps {
  data: {
    index: string;
    latestForecastLoad?: number;
    actualLoad?: number;
    hourAheadForecastLoad?: number;
    dayAheadForecastLoad?: number;
    probabilityBand?: number[];
  }[];
  metaData: {
    name: string;
    color: string;
    chart: string;
    dash?: string;
    lineWidth?: number;
  }[];
}

const TimeSeriesRangePlot: React.FC<TimeSeriesRangePlotProps> = (props) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart
        data={props.data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="index" />
        <YAxis label={{ value: "KW", angle: -90, position: "left" }} />
        {props.metaData.map((element) =>
          element.chart === "line" ? (
            <Line
              key={element.name}
              dataKey={element.name}
              type="monotone"
              stroke={element.color}
              strokeDasharray={element.dash ? element.dash : ""}
              dot={false}
              strokeWidth={element.lineWidth ? element.lineWidth : 1}
            />
          ) : (
            <Area
              key={element.name}
              dataKey={element.name}
              stroke={element.color}
              fill={element.color}
            />
          ),
        )}
        <Tooltip />
        <Legend />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default TimeSeriesRangePlot;
