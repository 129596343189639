import { useState, useCallback, useEffect } from "react";

import TimeSeriesPlotCard from "../data-visualization/TimeSeriesPlotCard";
import { getDefaultDate, dateToDatetimeString } from "../../utils/shared";
import { tabBlue, tabOrange, tabGreen } from "../../utils/style";
import fetchGCP from "../../utils/fetchGCP";

const metaData = [
  { name: "s7ActualLoad", color: tabBlue },
  { name: "s53ActualLoad", color: tabOrange },
  { name: "s120ActualLoad", color: tabGreen },
];

const WeisLoadBySettlementType: React.FC<{ entity: string }> = (props) => {
  const [start, setStart] = useState<Date | null>(getDefaultDate(-124 - 7));
  const [end, setEnd] = useState<Date | null>(getDefaultDate(-124));
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const url = `${
      process.env.REACT_APP_ENDPOINTS_URL
    }/energy-imbalance/settlement-type-load?entity=${
      props.entity
    }&start=${start?.toISOString()}&end=${end?.toISOString()}`;
    const response = await fetchGCP(url);
    const fetchedData = await response.json();
    fetchedData.forEach((element: { index: string }) => {
      element.index = dateToDatetimeString(new Date(element.index));
    });

    setData(fetchedData);
    setIsLoading(false);
  }, [start, end, props.entity]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TimeSeriesPlotCard
      title="WEIS actual load by settlement type"
      isLoading={isLoading}
      start={start}
      setStart={setStart}
      end={end}
      setEnd={setEnd}
      data={data}
      metaData={metaData}
      fetchData={fetchData}
    />
  );
};

export default WeisLoadBySettlementType;
