import { useEffect, useState } from "react";

import ScenarioForecastPlotCard from "../data-visualization/ScenarioForecastPlotCard";
import { tabBlue } from "../../utils/style";
import fetchGCP from "../../utils/fetchGCP";

const metaData = [{ name: "peakLoad", color: tabBlue }];

const LongTermLoadMonthlyPeak: React.FC<{ entity: string }> = (props) => {
  const [scenario, setScenario] = useState("medium");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const url = `${process.env.REACT_APP_ENDPOINTS_URL}/forecast/long-term-load?data=monthly-peak&entity=${props.entity}&scenario=${scenario}`;
      const response = await fetchGCP(url);
      const fetchedData = await response.json();

      setData(fetchedData);
      setIsLoading(false);
    };

    fetchData();
  }, [scenario, props.entity]);

  return (
    <ScenarioForecastPlotCard
      title="Monthly peak load"
      isLoading={isLoading}
      scenario={scenario}
      setScenario={setScenario}
      data={data}
      metaData={metaData}
    />
  );
};

export default LongTermLoadMonthlyPeak;
