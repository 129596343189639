import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";

import CurtailmentDetail from "../../components/operation/crypto-curtailment/curtailment-detail";

const UpdateCurtailmentPage = () => {
  const { id } = useParams();

  return (
    <Grid container justifyContent="center" p={2}>
      <Grid item xs={12} md={6}>
        <CurtailmentDetail mode="update" id={id} />
      </Grid>
    </Grid>
  );
};

export default UpdateCurtailmentPage;
