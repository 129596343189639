import { Fragment } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import GenerationForecast from "../../components/forecast/GenerationForecast";

const HydroForecast = () => {
  return (
    <Fragment>
      <Grid container justifyContent="center" p={2} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" component="div" align="center">
            Smith Creek
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <GenerationForecast
            entity="smith-creek"
            power="hydro"
            title="Hydro forecast"
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default HydroForecast;
