import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import ModelEvaluationCard from "../data-visualization/ModelEvaluationCard";
import React, { useEffect, useState } from "react";

interface Props {
  settingData: any; //{ entity: "", load: "", model: "", scenario: [], start: '', end: '' };
}

const ModelEvaluation: React.FC<Props> = (props) => {
  const [entity, setEntity] = useState<string>(props.settingData.entity);
  const [hideModelEval, setHideModeleEval] = useState(false);
  const firebaseModelEval = require("./LongTermModelEvaluation.json");

  const refresh = () => {
    const t = props.settingData.entity.split(",");
    if (t.length === 1 && t[0].length > 1) {
      setEntity(t[0]);
      setHideModeleEval(true);
    } else {
      setEntity(t[0]);
      setHideModeleEval(false);
    }
  };

  const lookupString = () => {
    return entity + "-" + props.settingData.load + "-load";
  };

  useEffect(() => {
    refresh();
  });

  return hideModelEval ? (
    <Card>
      <CardHeader title={`Model evaluation ${entity}`} />
      <CardContent>
        Show feature importances and partial dependency plot of each entity
        model with PNG image file. Directly proceed to production.
      </CardContent>
      <ModelEvaluationCard
        entity={entity}
        title={firebaseModelEval[lookupString()]["title"]}
        data={firebaseModelEval[lookupString()]["data"]}
        folder={firebaseModelEval[lookupString()]["folder"]}
        images={firebaseModelEval[lookupString()]["images"]}
        showStatsDetails={false}
      />
    </Card>
  ) : (
    <></>
  );
};

export default ModelEvaluation;
