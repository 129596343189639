import { firebaseAuth } from "../initializeFirebase";

const fetchGCP = async (
  input: RequestInfo | URL,
  init?: RequestInit,
): Promise<Response> => {
  const user = firebaseAuth.currentUser;
  if (!user) {
    throw new Error("No user logged in");
  }

  const token = await user.getIdToken();
  if (!token) {
    throw new Error("Unable to fetch id token");
  }

  const defaultHeaders = { Authorization: `Bearer ${token}` };

  if (!init) {
    init = { headers: defaultHeaders };
  } else if (!init.headers) {
    init.headers = defaultHeaders;
  } else {
    if (Array.isArray(init.headers)) {
      init.headers = [...init.headers, ["Authorization", `Bearer ${token}`]];
    } else if (init.headers instanceof Headers) {
      init.headers.set("Authorization", `Bearer ${token}`);
    } else {
      init.headers = { ...init.headers, Authorization: `Bearer ${token}` };
    }
  }

  const res = await fetch(input, init);

  return res;
};

export default fetchGCP;
