import React from "react";
import Grid from "@mui/material/Grid";

import LoginForm from "../components/LoginForm";

const Login: React.FC = () => {
  return (
    <Grid container mt={2} justifyContent="center">
      <Grid item xs={12} md={6}>
        <LoginForm />
      </Grid>
    </Grid>
  );
};

export default Login;
