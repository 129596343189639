import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BroncoDownloadForm from "../components/download-data/BroncoDownload";

const BroncoDownload = () => {
  return (
    <Grid container spacing={2} p={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" component="div" align="center">
          Bronco Download
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <BroncoDownloadForm />
      </Grid>
    </Grid>
  );
};

export default BroncoDownload;
