import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ShortTermLoadRangeForecast from "../../components/forecast/ShortTermLoadRangeForecast";
import ModelEvaluationCard from "../../components/data-visualization/ModelEvaluationCard";

const Aztec = () => {
  return (
    <Grid container p={2} spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" component="div" align="center">
          Aztec
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ShortTermLoadRangeForecast entity="aztec" />
      </Grid>

      <Grid item xs={12} md={6}>
        <ModelEvaluationCard
          entity="aztec"
          title="Load forecast model evaluation"
          data="load"
          folder="aztec_short_term_load_forecast"
          images={[
            {
              summary: "Feature importance",
              name: "shap_feature_importance.png",
            },
            { summary: "Temperature effect", name: "shap_pdp_temperature.png" },
            { summary: "Hour effect", name: "shap_pdp_cosine_of_hour.png" },
            { summary: "Holiday effect", name: "shap_pdp_holiday.png" },
            { summary: "Date effect", name: "shap_pdp_sine_of_date.png" },
            { summary: "Humidity effect", name: "shap_pdp_humidity.png" },
            { summary: "Year effect", name: "shap_pdp_year.png" },
            { summary: "Cloud cover effect", name: "shap_pdp_cloud_cover.png" },
            { summary: "Wind speed effect", name: "shap_pdp_wind_speed.png" },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default Aztec;
