import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridEventListener } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";

import {
  getLocalDateStringFromDate,
  getMtHourFromUtcDate,
} from "../../../utils/datetime";

const columns: GridColDef[] = [
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "hour",
    headerName: "Hour (MT)",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "amount",
    headerName: "Amount (MW)",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "action",
    headerName: "Action",
    renderCell: (params) => {
      return (
        <Button color="warning" variant="text">
          Update
        </Button>
      );
    },
    headerAlign: "center",
    align: "center",
  },
];

interface rowType {
  id: string;
  date: string;
  hour: number;
  amount: number;
}

const CurtailmentTable = () => {
  const [rows, setRows] = useState<rowType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const url = `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/curtailments/schedules`;
      const response = await fetch(url);
      const fetchedData = await response.json();

      const transformedData: rowType[] = [];
      fetchedData.forEach((data: { index: string; value: number }) => {
        const date = getLocalDateStringFromDate(new Date(data.index));
        const hour = getMtHourFromUtcDate(data.index);

        transformedData.push({
          id: data.index,
          date: date,
          hour: hour,
          amount: data.value / 1000,
        });
      });
      setRows(transformedData);

      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    navigate(`/operation/crypto-curtailment/update/${params.row.id}`);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        autoHeight={true}
        rows={rows}
        columns={columns}
        pageSize={100}
        components={{
          LoadingOverlay: LinearProgress,
        }}
        loading={isLoading}
        onRowClick={handleRowClick}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default CurtailmentTable;
