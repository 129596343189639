import Typography from "@mui/material/Typography";
import React, { Fragment, useEffect, useState } from "react";
import NodeDateSelectForm from "../components/price-capture/NodeDateSelectForm";
import { DayAheadResults } from "../models/priceCapture";
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import PriceCaptureTabMenu from "../components/price-capture/PriceCaptureTabMenu";
import HeavyLightForm from "../components/price-capture/HeavyLightForm";
import RealtimePriceCaptureForm from "../components/price-capture/RealtimePriceCaptureForm";
import fetchGCP from "../utils/fetchGCP";

interface PriceCaptureProps {
  autoSaveInterval: number;
}

const PriceCapture: React.FC<PriceCaptureProps> = ({ autoSaveInterval }) => {
  const [tabValue, setTabValue] = useState(0);
  const [selectedNode, setSelectedNode] = useState("");
  const [date, setDate] = useState<Date | null>(new Date());
  const [timezone, setTimezone] = useState("");
  const [dayAheadPriceData, setDayAheadPriceData] = useState<
    Map<string, number | null>
  >(new Map());
  const [dayAheadDataLoading, setDayAheadDataLoading] = useState(false);
  const [dayAheadSaveLoading, setDayAheadSaveLoading] = useState(false);
  const [changedDayAhead, setChangedDayAhead] = useState<Set<string>>(
    new Set(),
  );
  const [dayAheadTimeoutId, setDayAheadTimeoutId] =
    useState<ReturnType<typeof setTimeout>>();

  const submitDayAheadPrices = async () => {
    if (!date) {
      throw new Error("Invalid date");
    }

    setDayAheadSaveLoading(true);

    const requestBody = {
      mode: "dayAhead",
      submissions: [...dayAheadPriceData].map((pair) => ({
        label: pair[0],
        price: pair[1],
      })),
      date: `${date?.getMonth() + 1}/${date?.getDate()}/${date?.getFullYear()}`,
    };

    const apiUrl = `${process.env.REACT_APP_ENDPOINTS_URL}/rockies-price-capture`;

    const res = await fetchGCP(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!res.ok) {
      throw new Error("Unable to sumbit day ahead data");
    }

    setChangedDayAhead(new Set());

    setDayAheadSaveLoading(false);
  };

  const autoSaveDayAhead = () => {
    if (!dayAheadSaveLoading && changedDayAhead.size > 0) {
      submitDayAheadPrices();
    }
  };

  const handleDayAheadPriceChanged = (label: string, newPrice: number) => {
    if (dayAheadPriceData.get(label) === newPrice) {
      return;
    }

    clearTimeout(dayAheadTimeoutId);

    setChangedDayAhead(new Set([...changedDayAhead, label]));

    setDayAheadPriceData(new Map([...dayAheadPriceData, [label, newPrice]]));

    setDayAheadTimeoutId(setTimeout(autoSaveDayAhead, autoSaveInterval));
  };

  const handleSavePressed = (
    changedSet: Set<string | number>,
    saveFn: () => void,
  ) => {
    if (changedSet.size > 0) {
      saveFn();
    }
  };

  const handleTabChanged = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);

    //switching off of hourly
    if (newValue === 1) {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      setDate(tomorrow);
    }

    if (newValue === 0) {
      setDate(new Date());
    }
  };

  useEffect(() => {
    const tz = new Date()
      .toLocaleString("en", { timeZoneName: "short" })
      .split(" ")
      .pop();
    if (tz && ["CST", "CDT", "MST", "MDT", "PST", "PDT"].includes(tz)) {
      setTimezone(tz);
    }
  }, []);

  useEffect(() => {
    const getEncodedDate = () => {
      if (!date) {
        return "";
      }

      return encodeURIComponent(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
      );
    };

    const fetchDayAheadPriceData = async () => {
      if (!date) {
        return;
      }

      setDayAheadDataLoading(true);

      const encodedDate = getEncodedDate();

      const queryString = `?mode=dayAhead&date=${encodedDate}`;

      const apiUrl = `${process.env.REACT_APP_ENDPOINTS_URL}/rockies-price-capture`;

      const res = await fetchGCP(apiUrl + queryString);

      if (!res.ok) {
        throw new Error("Unable to fetch day ahead price data");
      }

      const resJson = (await res.json()) as DayAheadResults;

      setDayAheadPriceData(
        new Map(
          Object.entries(resJson).map((entry) => [
            entry[0],
            parseFloat(entry[1].toFixed(2)),
          ]),
        ),
      );

      setDayAheadDataLoading(false);
    };

    if (tabValue === 1) {
      fetchDayAheadPriceData();
    }
  }, [selectedNode, date, timezone, tabValue]);

  return (
    <Stack p={2} spacing={4} alignItems={"center"} justifyContent={"center"}>
      <Box sx={{ width: "100%" }}>
        <Typography variant="h3" component="div" align="center">
          {`Rockies ${tabValue === 1 ? "Day Ahead" : ""} Price Capture`}
        </Typography>
      </Box>

      <Box>
        <PriceCaptureTabMenu value={tabValue} onChange={handleTabChanged} />
      </Box>

      {tabValue === 0 && (
        <Fragment>
          <Box sx={{ width: { xs: "100%", md: "83.33%", lg: "50%" } }}>
            <NodeDateSelectForm
              onChangeNode={(node) => setSelectedNode(node)}
              onChangeDate={(date) => setDate(date)}
              onChangeTimezone={(tz) => setTimezone(tz)}
              node={selectedNode}
              date={date}
              timezone={timezone}
            />
          </Box>
          <Box>
            <RealtimePriceCaptureForm
              autoSaveInterval={autoSaveInterval}
              date={date}
              location={selectedNode}
              timezone={timezone}
            />
          </Box>
        </Fragment>
      )}

      {tabValue === 1 && (
        <Fragment>
          <Box sx={{ width: { xs: "100%", md: "83.33%", lg: "50%" } }}>
            <NodeDateSelectForm
              onChangeNode={(node) => {}}
              onChangeDate={(date) => setDate(date)}
              onChangeTimezone={(tz) => setTimezone(tz)}
              node={""}
              date={date}
              timezone={timezone}
              showNode={false}
            />
          </Box>
          <Box sx={{ width: { xs: "100%", md: "83.33%", lg: "66.67%" } }}>
            <HeavyLightForm
              priceData={dayAheadPriceData}
              onPriceChange={handleDayAheadPriceChanged}
              onSave={() =>
                handleSavePressed(changedDayAhead, submitDayAheadPrices)
              }
              dataLoading={dayAheadDataLoading}
              saveLoading={dayAheadSaveLoading}
            />
          </Box>
        </Fragment>
      )}
    </Stack>
  );
};

export default PriceCapture;
