import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ShortTermLoadForecast from "../../components/forecast/ShortTermLoadForecast";
import LongTermLoadMonthlyPeak from "../../components/forecast/LongTermLoadMonthlyPeak";
import LongTermLoadHourlyShapeBySeason from "../../components/forecast/LongTermLoadHourlyShapeBySeason";

const Raton = () => {
  return (
    <Grid container p={2} spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" component="div" align="center">
          Raton
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ShortTermLoadForecast entity="raton" />
      </Grid>

      <Grid item xs={12} md={6}>
        <LongTermLoadMonthlyPeak entity="raton" />
      </Grid>

      <Grid item xs={12} md={6}>
        <LongTermLoadHourlyShapeBySeason entity="raton" season="summer" />
      </Grid>

      <Grid item xs={12} md={6}>
        <LongTermLoadHourlyShapeBySeason entity="raton" season="winter" />
      </Grid>
    </Grid>
  );
};

export default Raton;
