import { CardContent } from "@mui/material";
import Card from "@mui/material/Card";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import RealtimeCaptureRow from "./RealtimeCaptureRow";
import Button from "@mui/material/Button";
import { BLFetchResults } from "../../models/priceCapture";
import fetchGCP from "../../utils/fetchGCP";

interface RealtimePriceCaptureFormProps {
  location: string;
  date: Date | null;
  timezone: string;
  autoSaveInterval: number;
}

export interface RealtimePriceData {
  bid: number | null;
  offer: number | null;
}

const RealtimePriceCaptureForm: React.FC<RealtimePriceCaptureFormProps> = ({
  location,
  date,
  timezone,
  autoSaveInterval,
}) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [priceData, setPriceData] = useState<Map<number, RealtimePriceData>>(
    new Map(),
  );
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout>>();
  const [changedHours, setChangedHours] = useState<Set<number>>(new Set());

  const handlePriceChanged = (
    hourEnd: number,
    newBid: number,
    newOffer: number,
  ) => {
    if (
      priceData.get(hourEnd)?.bid === newBid &&
      priceData.get(hourEnd)?.offer === newOffer
    ) {
      return;
    }
    clearTimeout(timeoutId);

    const newChangedHours = new Set([...changedHours, hourEnd]);
    setChangedHours(newChangedHours);

    const newPriceData = new Map([...priceData]);
    newPriceData.set(hourEnd, { bid: newBid, offer: newOffer });
    setPriceData(newPriceData);

    setTimeoutId(setTimeout(autoSave, autoSaveInterval));
  };

  const submitPriceCaptures = async () => {
    if (!date) {
      throw new Error("Invalid date");
    }

    setSaveLoading(true);

    const captureSubmissions = [...changedHours].map((hourEnd) => {
      return {
        node: location,
        date: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
        hourEnd: hourEnd,
        timezone: timezone,
        bid: priceData.get(hourEnd)?.bid,
        offer: priceData.get(hourEnd)?.offer,
      };
    });

    const requestBody = {
      submissions: captureSubmissions,
    };

    const apiUrl = `${process.env.REACT_APP_ENDPOINTS_URL}/rockies-price-capture`;

    const res = await fetchGCP(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (res.ok) {
      setChangedHours(new Set());
    }

    setSaveLoading(false);
  };

  const autoSave = () => {
    if (!saveLoading && changedHours.size > 0) {
      submitPriceCaptures().catch(console.error);
    }
  };

  useEffect(() => {
    const getEncodedDate = () => {
      if (!date) {
        return "";
      }

      return encodeURIComponent(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
      );
    };

    const fetchHourlyPriceData = async () => {
      if (!location || !date || !timezone) {
        return;
      }

      setDataLoading(true);

      const encodedDate = getEncodedDate();

      const queryString = `?date=${encodedDate}&node=${location}&timezone=${timezone}`;

      const apiUrl = `${process.env.REACT_APP_ENDPOINTS_URL}/rockies-price-capture`;

      const res = await fetchGCP(apiUrl + queryString);

      const resJson = (await res.json()) as BLFetchResults;

      const fetchedData = resJson.results;

      const fetchedMap = new Map();

      fetchedData.forEach((hour) => {
        const bid = hour.bid;
        const offer = hour.offer;

        const hourObj: RealtimePriceData = {
          bid: bid ? parseFloat(bid.toFixed(2)) : null,
          offer: offer ? parseFloat(offer.toFixed(2)) : null,
        };

        fetchedMap.set(hour.he, hourObj);
      });

      Array.from({ length: 24 }, (_, i) => i + 1).forEach(
        (i) =>
          !fetchedMap.has(i) && fetchedMap.set(i, { bid: null, offer: null }),
      );

      setPriceData(fetchedMap);

      setDataLoading(false);
    };

    fetchHourlyPriceData().catch(console.error);
  }, [location, date, timezone]);

  return (
    <Card>
      <CardContent>
        {dataLoading ? (
          <Grid item container xs={12} justifyContent={"center"}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container justifyContent={"center"} spacing={4}>
            <Grid container item xs={12} columnSpacing={4} rowSpacing={2}>
              {[0, 12].map((hourEndStart) => {
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    columnSpacing={4}
                    rowSpacing={2}
                    key={hourEndStart}
                  >
                    {[0, 6].map((increment) => {
                      return (
                        <Grid
                          container
                          item
                          xs={12}
                          lg={6}
                          spacing={2}
                          key={increment}
                        >
                          {[...priceData.keys()]
                            .sort((a, b) => a - b)
                            .filter(
                              (hourEnd) =>
                                hourEndStart + increment < hourEnd &&
                                hourEnd < hourEndStart + increment + 7,
                            )
                            .map((hourEnd) => (
                              <RealtimeCaptureRow
                                data={
                                  priceData.get(hourEnd) ?? { bid: 0, offer: 0 }
                                }
                                hour={hourEnd}
                                onChange={(newBid, newOffer) =>
                                  handlePriceChanged(hourEnd, newBid, newOffer)
                                }
                              />
                            ))}
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              })}
            </Grid>
            {saveLoading ? (
              <Grid item container xs={12} justifyContent={"center"}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                md={4}
                lg={3}
                container
                justifyContent={"center"}
              >
                <Button variant={"contained"} sx={{ width: "80%" }}>
                  Save
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default RealtimePriceCaptureForm;
