import Stack from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import HourlyInputForm from "../components/price-capture/HourlyInputForm";
import NodeDateSelectForm from "../components/price-capture/NodeDateSelectForm";
import Box from "@mui/material/Box";
import fetchGCP from "../utils/fetchGCP";

interface LoadActualsProps {
  autoSaveInterval?: number;
}

interface GetRequestResponse {
  message: string;
  results: { entity: string; date: Date; he: number; load: number }[];
}

const LoadActuals: React.FC<LoadActualsProps> = ({
  autoSaveInterval = 10000,
}) => {
  const [loadData, setLoadData] = useState<Map<number, number | null>>(
    new Map(),
  );
  const [saveLoading, setSaveLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [load, setLoad] = useState("");
  const [date, setDate] = useState(new Date());
  const [timezone, setTimezone] = useState("");
  const [changedHours, setChangedHours] = useState<Set<number>>(new Set());
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout>>();

  const handleLoadValueChanged = (hourEnd: number, newLoadValue: number) => {
    if (loadData.get(hourEnd) === newLoadValue) {
      return;
    }
    clearTimeout(timeoutId);
    setLoadData(new Map([...loadData, [hourEnd, newLoadValue]]));
    setChangedHours(new Set([...changedHours, hourEnd]));
    setTimeoutId(setTimeout(submitLoadCaptures, autoSaveInterval));
  };

  const submitLoadCaptures = async () => {
    if (!date) {
      throw new Error("Invalid date");
    }

    clearTimeout(timeoutId);

    setSaveLoading(true);

    const captureSubmissions = [...changedHours].map((hourEnd) => {
      return {
        entity: load,
        date: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
        hourEnd: hourEnd,
        timezone: timezone,
        load: loadData.get(hourEnd),
      };
    });

    const requestBody = {
      submissions: captureSubmissions,
    };

    const apiUrl = `${process.env.REACT_APP_ENDPOINTS_URL}/load-actuals-capture`;

    const res = await fetchGCP(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (res.ok) {
      setChangedHours(new Set());
    }

    setSaveLoading(false);
  };

  useEffect(() => {
    const tz = new Date()
      .toLocaleString("en", { timeZoneName: "short" })
      .split(" ")
      .pop();
    if (tz && ["CST", "CDT", "MST", "MDT", "PST", "PDT"].includes(tz)) {
      setTimezone(tz);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!date || !timezone || !load) return;

      setDataLoading(true);

      const encodedDateString = encodeURIComponent(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
      );

      const apiUrl = `${process.env.REACT_APP_ENDPOINTS_URL}/load-actuals-capture?date=${encodedDateString}&timezone=${timezone}&entity=${load}`;

      const res = await fetchGCP(apiUrl);

      if (!res.ok) {
        setDataLoading(false);
        throw new Error("Failed to fetch data");
      }

      const resJson = (await res.json()) as GetRequestResponse;

      const resultsMap = new Map(
        resJson.results.map((result) => [result.he, result.load]),
      );

      setLoadData(
        new Map(
          Array.from({ length: 24 }, (_, i) => i + 1).map((i) => [
            i,
            resultsMap.get(i) ?? null,
          ]),
        ),
      );

      setDataLoading(false);
    };

    fetchData();
  }, [date, load, timezone]);

  return (
    <Stack p={2} spacing={4} alignItems={"center"} justifyContent={"center"}>
      <Typography variant={"h3"}>Load Actuals Tracker</Typography>
      <Box sx={{ width: { xs: "100%", md: "83.33%", lg: "50%" } }}>
        <NodeDateSelectForm
          onChangeNode={setLoad}
          onChangeDate={setDate}
          onChangeTimezone={setTimezone}
          date={date}
          timezone={timezone}
          nodeList={["KCEC", "JANPA", "ACOMA"]}
        />
      </Box>
      {(loadData.size > 0 || dataLoading) && (
        <Box sx={{ width: "100%" }}>
          <HourlyInputForm
            onPriceChange={handleLoadValueChanged}
            priceData={loadData}
            onSave={submitLoadCaptures}
            saveLoading={saveLoading}
            dataLoading={dataLoading}
            inputLabel={"Load"}
            inputAdornment={"MWh"}
          />
        </Box>
      )}
    </Stack>
  );
};

export default LoadActuals;
