import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CaisoBidSubmitForm from "../../components/bid-submission/CaisoBidSubmit";

const CaisoBidSubmit = () => {
  return (
    <Grid container spacing={2} p={2} justifyContent="center">
      <Grid item xs={12}>
        <CaisoBidSubmitForm />
      </Grid>
    </Grid>
  );
};

export default CaisoBidSubmit;
