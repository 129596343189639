import { useState, useRef } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import CircularProgress from "@mui/material/CircularProgress";

interface DownloadedData {
  index: string;
  value: number;
}

interface Props {
  settingData: any; //{ entity: "", load: "", model: "", scenario: [], start: '', end: '' };
  isLoading: boolean;
  setIsLoading: any;
}

const LongTermDownloadData: React.FC<Props> = (props) => {
  const [csvData, setCsvData] = useState<DownloadedData[]>([]);

  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const createURLS = () => {
    const entities: string = props.settingData.entity;
    const selectedEntities: string[] = entities.split(",");

    const s = props.settingData.start;
    const e = props.settingData.end;

    const baseUrl = process.env.REACT_APP_AWS_API_GATEWAY_URL;
    const scenarioParam = props.settingData.scenario[0];
    const loadType = props.settingData.load;
    const model = props.settingData.model;

    const urls = [];
    for (let index = 0; index < selectedEntities.length; index++) {
      const entity = selectedEntities[index];
      const c = [
        // Future forecast monthly total
        `${baseUrl}/loads/forecasts/long-term?forecast=future&entity=${entity}&load=${loadType}&model=${model}&scenario=${scenarioParam}&start=${s}&end=${e}&tz=mt`,
      ];

      urls.push(...c);

      for (let index = 1; index < props.settingData.scenario.length; index++) {
        const element = props.settingData.scenario[index];
        urls.push(
          `${baseUrl}/loads/forecasts/long-term?forecast=future&entity=${entity}&load=${loadType}&model=${model}&scenario=${element}&start=${s}&end=${e}&tz=mt`,
        );
      }
    }
    return urls;
  };

  const download = async () => {
    console.log("we started downloading");
    props.setIsLoading(true);

    const entities: string = props.settingData.entity;
    const entitiesArray: string[] = entities.split(",");
    if (entitiesArray[0] === "") {
      return;
    }

    //This download wants to be the Hourly Timeseries instead of the aggregate data
    //Get the URLS for every scenario
    var urls: string[] = [];
    urls.push(...createURLS());

    const mapTotal = new Map();

    const names: string[] = [];
    for (let i = 0; i < entitiesArray.length; i++) {
      names.push(
        ...[`${entitiesArray[i]} Forecast ${props.settingData.scenario[0]}`],
      );

      for (let index = 1; index < props.settingData.scenario.length; index++) {
        names.push(
          `${entitiesArray[i]} Forecast ${props.settingData.scenario[index]}`,
        );
      }
    }

    await Promise.all(
      urls.map(async (url, index) => {
        // console.log(`getting url : ${url}`);
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_AWS_API_GATEWAY_API_KEY!,
          },
        });
        const fetchedData = await response.json();

        if (!response.ok) {
          props.setIsLoading(false);
          throw new Error(fetchedData.error.message);
        }

        // Rename
        fetchedData.forEach((data: any) => {
          data["Model"] = props.settingData.model;
          data["Datetime"] = data["index"];
          data[names[index]] = data["value"];

          delete data["value"];
          delete data["index"];
        });

        fetchedData.forEach((item: any) => {
          mapTotal.set(item.Datetime, {
            ...mapTotal.get(item.Datetime),
            ...item,
          });

          mapTotal.set(item.Datetime, { ...mapTotal.get(item.Datetime) });
        });

        return fetchedData;
      }),
    );

    const mapTotalAsc = new Map([...mapTotal.entries()].sort());
    const mergedTotal = Array.from(mapTotalAsc.values());

    setCsvData(mergedTotal);

    //Need to wait for csv to grab memory
    await new Promise((r) => setTimeout(r, 1000));

    props.setIsLoading(false);

    csvLink?.current?.link.click();
  };

  return (
    <Card>
      <CardHeader title="Select the data to download" />
      <CardContent></CardContent>
      <CardActions>
        <CSVLink
          data={csvData}
          filename={"data.csv"}
          className="csvLinkNoStyle"
          ref={csvLink}
          uFEFF={false}
        />
        {!props.isLoading && (
          <Button variant="contained" onClick={download}>
            Download
          </Button>
        )}
        {props.isLoading && (
          <Button disabled variant="contained">
            <CircularProgress size={24} />
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default LongTermDownloadData;
