import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ShortTermLoadForecast from "../../components/forecast/ShortTermLoadForecast";
import GenerationForecast from "../../components/forecast/GenerationForecast";
import LongTermLoadMonthlyPeak from "../../components/forecast/LongTermLoadMonthlyPeak";
import LongTermLoadHourlyShapeBySeason from "../../components/forecast/LongTermLoadHourlyShapeBySeason";
import ModelEvaluationCard from "../../components/data-visualization/ModelEvaluationCard";

const Kcec = () => {
  return (
    <Grid container p={2} spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" component="div" align="center">
          KCEC
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ShortTermLoadForecast entity="kcec" />
      </Grid>

      <Grid item xs={12} md={6}>
        <GenerationForecast
          entity="taos"
          power="solar"
          title="Taos solar generation forecast"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ModelEvaluationCard
          entity="kcec"
          title="Load forecast 1 hour ahead model evaluation"
          data="load"
          hourAhead={1}
          folder="kcec_short_term_load_forecast"
          images={[
            {
              summary: "Feature importance",
              name: "shap_feature_importance_1_hour_ahead_model.png",
            },
            {
              summary: "Current load effect",
              name: "shap_pdp_current_load_1_hour_ahead_model.png",
            },
            {
              summary: "Temperature effect",
              name: "shap_pdp_temperature_1_hour_ahead_model.png",
            },
            {
              summary: "Solar radiation effect",
              name: "shap_pdp_solar_radiation_1_hour_ahead_model.png",
            },
            {
              summary: "Hour effect",
              name: "shap_pdp_cosine_of_hour_1_hour_ahead_model.png",
            },
            {
              summary: "Date effect",
              name: "shap_pdp_cosine_of_date_1_hour_ahead_model.png",
            },
            {
              summary: "Taos solar effect",
              name: "shap_pdp_huge_solar_1_hour_ahead_model.png",
            },
          ]}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <ModelEvaluationCard
          entity="kcec"
          title="Load forecast 7 days ahead model evaluation"
          data="load"
          hourAhead={168}
          folder="kcec_short_term_load_forecast"
          images={[
            {
              summary: "Feature importance",
              name: "shap_feature_importance_168_hour_ahead_model.png",
            },
            {
              summary: "Current load effect",
              name: "shap_pdp_current_load_168_hour_ahead_model.png",
            },
            {
              summary: "Temperature effect",
              name: "shap_pdp_temperature_168_hour_ahead_model.png",
            },
            {
              summary: "Solar radiation effect",
              name: "shap_pdp_solar_radiation_168_hour_ahead_model.png",
            },
            {
              summary: "Hour effect",
              name: "shap_pdp_cosine_of_hour_168_hour_ahead_model.png",
            },
            {
              summary: "Date effect",
              name: "shap_pdp_cosine_of_date_168_hour_ahead_model.png",
            },
            {
              summary: "Taos solar effect",
              name: "shap_pdp_huge_solar_168_hour_ahead_model.png",
            },
          ]}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <LongTermLoadMonthlyPeak entity="kcec" />
      </Grid>

      <Grid item xs={12} md={6}>
        <LongTermLoadHourlyShapeBySeason entity="kcec" season="summer" />
      </Grid>

      <Grid item xs={12} md={6}>
        <LongTermLoadHourlyShapeBySeason entity="kcec" season="winter" />
      </Grid>
    </Grid>
  );
};

export default Kcec;
