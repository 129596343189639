import { PriceHour } from "../../models/priceCapture";
import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PriceInput from "./PriceInput";
import { Paper } from "@mui/material";

export interface PriceHourRowProps {
  priceHour?: PriceHour;
  onChangePrice?: (newPrice: number) => void;
  label?: string;
  price?: number | null;
  title?: string;
  adornment?: string;
}

const PriceHourRow: React.FC<PriceHourRowProps> = ({
  priceHour,
  onChangePrice,
  label,
  price,
  title,
  adornment,
}) => {
  return (
    <Grid container item xs={12} alignItems={"center"}>
      <Grid
        item
        xs={3}
        sx={{
          border: "1px solid lightgray",
          height: "100%",
          borderRadius: "6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant={"h6"} align={"center"} color={"gray"}>
          {label ? label : priceHour && `${priceHour.he}:00`}
        </Typography>
      </Grid>

      <Grid item xs={1}>
        <Paper
          elevation={0}
          sx={{ height: "1px", backgroundColor: "lightgray" }}
        />
      </Grid>

      <Grid item xs={8}>
        <PriceInput
          onPriceChange={(newPrice) => onChangePrice && onChangePrice(newPrice)}
          defaultValue={(priceHour ? priceHour.price : price) ?? 0}
          label={title}
          adornment={adornment}
        />
      </Grid>
    </Grid>
  );
};

export default PriceHourRow;
