import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Dialog from "@mui/material/Dialog";
import { Stack, Button, TextField, DialogTitle } from "@mui/material";
import { SetStateAction, useState } from "react";
import fetchGCP from "../../utils/fetchGCP";

const FeatureRequest = () => {
  const [summary, setSummary] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [submiterName, setSubmiterName] = useState<string>("");
  const [showSubmitalDialog, setShowSubmitalDialog] = useState(false);
  const [dialogString, setDialogString] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const submitJiraRequest = async () => {
    setSubmitDisabled(true);
    const url = `${
      process.env.REACT_APP_ENDPOINTS_URL
    }/jira-task-request?summary=${summary}&description=${description + " - " + submiterName}`;

    const response = await fetchGCP(url);

    //Request has been made so time to clean up
    setSubmitDisabled(false);

    // Check the response status code
    if (response.status === 200) {
      setSummary("");
      setDescription("");
      setDialogString("Thanks for submiting!");
      setShowSubmitalDialog(true);
      // console.log("Issue created successfully!");
    } else {
      setDialogString("Error creating issue:" + response.status);
      setShowSubmitalDialog(true);
      // console.log("Error creating issue:", response.status);
    }
  };

  const handleSummaryChange = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setSummary(e.target.value);
  };

  const handleDescriptionChange = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setDescription(e.target.value);
  };

  const handleSubmiterNameChange = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setSubmiterName(e.target.value);
  };

  const handleDialogClose = () => {
    setShowSubmitalDialog(false);
  };

  return (
    <Card>
      <CardHeader />
      <CardContent>
        <Stack direction="column" spacing={2} justifyContent="center">
          <TextField
            name="summary"
            required
            label="Summary"
            value={summary}
            onChange={handleSummaryChange}
          />
          <TextField
            name="description"
            required
            label="Description"
            value={description}
            onChange={handleDescriptionChange}
            multiline
          />
          <TextField
            name="name"
            required
            label="Name"
            value={submiterName}
            onChange={handleSubmiterNameChange}
            multiline
          />
          <Dialog open={showSubmitalDialog} onClose={handleDialogClose}>
            <DialogTitle>{dialogString}</DialogTitle>
            <Button variant="contained" onClick={handleDialogClose}>
              Close
            </Button>
          </Dialog>
        </Stack>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          disabled={submitDisabled}
          onClick={submitJiraRequest}
        >
          Submit
        </Button>
      </CardActions>
    </Card>
  );
};

export default FeatureRequest;
