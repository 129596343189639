import { useState, useEffect, useCallback } from "react";

import TimeSeriesPlotCard from "../data-visualization/TimeSeriesPlotCard";
import {
  getDefaultDate,
  dateToDatetimeString,
  computeRmse,
  computeMae,
} from "../../utils/shared";
import { computeMapeAvgDividedByAvg } from "../../utils/math";
import { tabBlue, tabOrange, tabGreen, tabRed } from "../../utils/style";
import { getUTCDateStringLocalOffset } from "../../utils/datetime";

const metaData = [
  { name: "actualLoad", color: tabBlue },
  { name: "hourAheadForecastLoad", color: tabOrange, dash: "3 3" },
  { name: "dayAheadForecastLoad", color: tabGreen, dash: "3 3" },
  { name: "latestForecastLoad", color: tabRed, dash: "3 3" },
];

const metaDataHce = [
  { name: "actualLoad", color: tabBlue },
  { name: "latestForecastLoad", color: tabRed, dash: "3 3" },
];

const names = [
  "latestForecastLoad",
  "actualLoad",
  "hourAheadForecastLoad",
  "dayAheadForecastLoad",
];

const namesHce = ["latestForecastLoad", "actualLoad"];

const ShortTermLoadForecast: React.FC<{ entity: string }> = (props) => {
  const [start, setStart] = useState<Date | null>(getDefaultDate(-7));
  const [end, setEnd] = useState<Date | null>(getDefaultDate(7));
  const [data, setData] = useState<any[]>([]);
  const [downloadData, setDownloadData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rmse, setRmse] = useState<number>();
  const [mae, setMae] = useState<number>();
  const [mape, setMape] = useState<number>();

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const now = new Date();
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);

    let urls = [
      `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/loads/forecasts?entity=${
        props.entity
      }&start=${now.toISOString()}&end=${end?.toISOString()}`,
      `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/loads/actuals?entity=${
        props.entity
      }&start=${start?.toISOString()}&end=${now.toISOString()}`,
      `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/loads/forecasts?entity=${
        props.entity
      }&start=${start?.toISOString()}&end=${now.toISOString()}&hour-ahead=1`,
      `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/loads/forecasts?entity=${
        props.entity
      }&start=${start?.toISOString()}&end=${now.toISOString()}&hour-ahead=24`,
    ];
    // We don't have new forecast every hour in HCE because HCE gives us the forecast once per day
    if (props.entity === "hce") {
      urls = [
        `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/loads/forecasts?entity=${
          props.entity
        }&start=${start?.toISOString()}&end=${end?.toISOString()}`,
        `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/loads/actuals?entity=${
          props.entity
        }&start=${start?.toISOString()}&end=${now.toISOString()}`,
      ];
    }

    const map = new Map();
    const mapDownloadData = new Map();
    // Array for computing stats, not for plot
    const trues: number[] = [];
    const preds: number[] = [];
    await Promise.all(
      urls.map(async (url, index) => {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_AWS_API_GATEWAY_API_KEY!,
          },
        });
        const fetchedData = await response.json();

        fetchedData.forEach((data: any) => {
          if (props.entity === "hce") {
            // Save actual load to compute stats
            if (namesHce[index] === "actualLoad") {
              trues.push(data["value"]);
            }
            // Save hour ahead load to compute stats
            else if (namesHce[index] === "latestForecastLoad") {
              preds.push(data["value"]);
            }
          } else {
            // Save actual load to compute stats
            if (names[index] === "actualLoad") {
              trues.push(data["value"]);
            }
            // Save hour ahead load to compute stats
            else if (names[index] === "hourAheadForecastLoad") {
              preds.push(data["value"]);
            }
          }

          // Rename the value key name to merge
          data[names[index]] = data["value"];
          delete data["value"];
        });

        // Merge
        fetchedData.forEach((item: any) =>
          map.set(item.index, { ...map.get(item.index), ...item }),
        );

        // Merge
        fetchedData.forEach((item: any) =>
          mapDownloadData.set(item.index, {
            ...mapDownloadData.get(item.index),
            ...item,
          }),
        );

        return fetchedData;
      }),
    );

    // Extract array of objects and sort by index
    const mapAsc = new Map([...map.entries()].sort());
    const merged = Array.from(mapAsc.values());

    mapDownloadData.forEach(
      (element) =>
        (element["index"] = getUTCDateStringLocalOffset(element["index"])),
    );

    const mapAscDownloadData = new Map([...mapDownloadData.entries()].sort());
    const mergedDownloadData = Array.from(mapAscDownloadData.values());

    setDownloadData(mergedDownloadData);

    // Convert UTC index to local datetime string
    merged.forEach(
      (data) => (data["index"] = dateToDatetimeString(new Date(data["index"]))),
    );

    setRmse(computeRmse(trues, preds));
    setMae(computeMae(trues, preds));
    setMape(computeMapeAvgDividedByAvg(trues, preds));
    setData(merged);

    setIsLoading(false);
  }, [start, end, props.entity]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <TimeSeriesPlotCard
      title="Short-term load forecast"
      subheader={`RMSE: ${Math.round(rmse!)}KW, MAE: ${Math.round(
        mae!,
      )}KW, MAPE: ${(mape! * 100).toFixed(1)}%`}
      isLoading={isLoading}
      start={start}
      setStart={setStart}
      end={end}
      setEnd={setEnd}
      data={data}
      metaData={props.entity === "hce" ? metaDataHce : metaData}
      fetchData={fetchData}
      downloadData={downloadData}
    />
  );
};

export default ShortTermLoadForecast;
