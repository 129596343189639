import { Grid } from "@mui/material";
import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";

interface NodeDateSelectFormProps {
  onChangeNode: (newNode: string) => void;
  onChangeDate: (newDate: Date) => void;
  onChangeTimezone: (newTimezone: string) => void;
  node?: string;
  date: Date | null;
  timezone: string;
  showNode?: boolean;
  nodeList?: string[];
}

const NodeDateSelectForm: React.FC<NodeDateSelectFormProps> = ({
  onChangeNode,
  onChangeDate,
  onChangeTimezone,
  node,
  date,
  timezone,
  showNode = true,
  nodeList = ["MIDC-BL-RT", "SW-BL-RT", "ROX-BL-RT"],
}) => {
  return (
    <Card>
      <CardHeader
        title={`${showNode ? "Location & " : ""}Date`}
        titleTypographyProps={{ textAlign: "center" }}
      />
      <CardContent>
        <Grid container p={0} spacing={4}>
          {showNode && (
            <Grid item xs={4} container justifyContent={"center"}>
              <FormControl fullWidth sx={{ width: "80%", align: "center" }}>
                <InputLabel id="node-select-label">Location</InputLabel>
                <Select
                  labelId="node-select-label"
                  id="node-select"
                  label="Location"
                  value={node}
                  onChange={(e: SelectChangeEvent) =>
                    onChangeNode(e.target.value as string)
                  }
                >
                  {nodeList.map((node) => (
                    <MenuItem value={node}>{node}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={showNode ? 4 : 6} container justifyContent={"center"}>
            <DatePicker
              label={"Date"}
              onChange={(newDate) => newDate && onChangeDate(newDate)}
              value={date}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: "80%" }} />
              )}
            />
          </Grid>

          <Grid item xs={showNode ? 4 : 6} container justifyContent={"center"}>
            <FormControl fullWidth sx={{ width: "80%", align: "center" }}>
              <InputLabel id="timezone-select-label">Timezone</InputLabel>
              <Select
                labelId="timezone-select-label"
                id="node-select"
                label="Timezone"
                value={timezone}
                onChange={(e: SelectChangeEvent) =>
                  onChangeTimezone(e.target.value as string)
                }
              >
                <MenuItem value={"CST"}>CST</MenuItem>
                <MenuItem value={"CDT"}>CDT</MenuItem>
                <MenuItem value={"MST"}>MST</MenuItem>
                <MenuItem value={"MDT"}>MDT</MenuItem>
                <MenuItem value={"PST"}>PST</MenuItem>
                <MenuItem value={"PDT"}>PDT</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default NodeDateSelectForm;
