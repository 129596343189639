import { TextField, InputAdornment } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

interface PriceInputProps {
  onPriceChange?: (price: number) => void;
  defaultValue?: number;
  label?: string;
  adornment?: string;
}

const formatRawInput = (input: string) => {
  const negative = input[0] === "-";
  if (negative) input = input.slice(1);

  const decimalPart = input.slice(-2).padStart(2, "0");
  let integralPart = input.slice(0, -2);

  while (integralPart[0] === "0") {
    integralPart = integralPart.substring(1);
  }

  integralPart = integralPart.padStart(1, "0");

  return `${negative ? "-" : ""}${integralPart}.${decimalPart}`;
};

const PriceInput: React.FC<PriceInputProps> = ({
  onPriceChange,
  defaultValue,
  label,
  adornment,
}) => {
  const [rawInput, setRawInput] = useState(
    defaultValue
      ? (Math.round(defaultValue * 100) / 100)
          .toFixed(2)
          .split("")
          .filter((char) => /[0-9-]/.test(char))
          .join("")
          .padEnd(3, "0")
      : "",
  );
  const [formattedPrice, setFormattedPrice] = useState(
    defaultValue ? formatRawInput(rawInput) : "0.00",
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== "Tab") {
      event.preventDefault();
    }

    let updatedRawInput = rawInput;

    if (/^[0-9]$/.test(event.key)) {
      updatedRawInput = rawInput.concat(event.key);
    } else if (event.key === "Backspace" || event.key === "Delete") {
      updatedRawInput = rawInput.slice(0, -1);
    } else if (event.key === "-") {
      updatedRawInput =
        rawInput[0] === "-" ? rawInput.slice(1) : ["-", ...rawInput].join("");
    }
    formatAndUpdate(updatedRawInput);
  };

  const formatAndUpdate = useCallback(
    (input: string) => {
      const formatted = formatRawInput(input);
      setFormattedPrice(formatted);
      setRawInput(
        formatted
          .split("")
          .filter((char) => /[0-9-]/.test(char))
          .join(""),
      );
      const priceFloat = parseFloat(formatted);
      onPriceChange && onPriceChange(priceFloat);
    },
    [onPriceChange],
  );

  useEffect(() => {
    defaultValue &&
      formatAndUpdate(
        (Math.round(defaultValue * 100) / 100)
          .toFixed(2)
          .split("")
          .filter((char) => /[0-9-]/.test(char))
          .join("")
          .padEnd(3, "0"),
      );
  }, [defaultValue, formatAndUpdate]);

  return (
    <TextField
      label={label ? label : "Price"}
      value={formattedPrice}
      onKeyDown={handleKeyDown}
      variant="outlined"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {adornment ? adornment : "$"}
          </InputAdornment>
        ),
        inputProps: {
          style: { textAlign: "right", caretColor: "transparent" },
        },
      }}
    />
  );
};

export default PriceInput;
