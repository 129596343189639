import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { CSVLink } from "react-csv";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface TimeSeriesPlotCardProps {
  title: string;
  subheader?: string;
  isLoading: boolean;
  start: Date | null;
  setStart(newValue: Date | null): void;
  end: Date | null;
  setEnd(newValue: Date | null): void;
  data: any[];
  metaData: { name: string; color: string; dash?: string }[];
  ymin?: number;
  ymax?: number;
  ylabel?: string;
  downloadData?: any[];
  fetchData?: () => void;
}

const TimeSeriesPlotCard: React.FC<TimeSeriesPlotCardProps> = (props) => {
  const handleStartChange = (newValue: Date | null) => {
    props.setStart(newValue);
  };

  const handleEndChange = (newValue: Date | null) => {
    props.setEnd(newValue);
  };

  return (
    <Card sx={{ boxShadow: 3 }}>
      {!props.subheader && <CardHeader title={props.title} />}
      {props.subheader && (
        <CardHeader title={props.title} subheader={props.subheader} />
      )}
      <CardContent>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={props.data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="index" />
            {(props.ymin === undefined || props.ymax === undefined) && (
              <YAxis
                label={{
                  value: props.ylabel ? props.ylabel : "KW",
                  angle: -90,
                  position: "left",
                }}
              />
            )}
            {props.ymin !== undefined && props.ymax !== undefined && (
              <YAxis
                label={{
                  value: props.ylabel ? props.ylabel : "KW",
                  angle: -90,
                  position: "left",
                }}
                domain={[props.ymin, props.ymax]}
              />
            )}
            <Tooltip />
            <Legend />
            {props.metaData.map((element) => (
              <Line
                type="monotone"
                dataKey={element.name}
                stroke={element.color}
                dot={false}
                key={element.name}
                strokeDasharray={element.dash ? element.dash : ""}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
      <CardActions>
        <Stack direction="row" spacing={2} alignItems="center">
          <DesktopDatePicker
            label="Start"
            inputFormat="MM/dd/yyyy"
            value={props.start}
            onChange={handleStartChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <DesktopDatePicker
            label="End"
            inputFormat="MM/dd/yyyy"
            value={props.end}
            onChange={handleEndChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <Button variant="outlined" onClick={props.fetchData}>
            Update
          </Button>
          <CSVLink
            data={props.downloadData ? props.downloadData : props.data}
            filename="data.csv"
            className="csvLinkNoStyle"
          >
            <Button variant="contained">Download</Button>
          </CSVLink>
        </Stack>
      </CardActions>
      {props.isLoading && <LinearProgress />}
    </Card>
  );
};

export default TimeSeriesPlotCard;
