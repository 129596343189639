import { useEffect, useState } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinearProgress from "@mui/material/LinearProgress";

import { firebaseApp } from "../../initializeFirebase";
import fetchGCP from "../../utils/fetchGCP";

interface ModelEvaluationCardProps {
  entity: string;
  title: string;
  data: string;
  folder: string;
  images: { summary: string; name: string }[];
  hourAhead?: number;
  showStatsDetails?: boolean;
}

const ModelEvaluationCard: React.FC<ModelEvaluationCardProps> = (props) => {
  const [statsDetails, setStatsDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState<
    { summary: string; name: string; url: string }[]
  >([]);
  const [isLoadingImages, setIsLoadingImages] = useState(false);

  const getShowStatsDetais = () => {
    if (props.showStatsDetails === undefined) return true;
    return props.showStatsDetails;
  };

  // useEffect
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      // Get stats data from API
      let url = "";
      let headers = {};
      if (props.entity === "psco") {
        url = `${process.env.REACT_APP_ENDPOINTS_URL}/forecast/psco?data=${props.data}&evaluation=true`;
      } else if (props.entity === "kcec") {
        url = `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/loads/forecasts/evaluations?entity=${props.entity}&data=load&hour-ahead=${props.hourAhead}`;
        headers = {
          "x-api-key": process.env.REACT_APP_AWS_API_GATEWAY_API_KEY,
        };
      } else {
        url = `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/loads/forecasts/evaluations?entity=${props.entity}&data=load`;
        headers = {
          "x-api-key": process.env.REACT_APP_AWS_API_GATEWAY_API_KEY,
        };
      }
      let response;
      if (props.entity === "psco") {
        response = await fetchGCP(url);
      } else {
        response = await fetch(url, {
          method: "GET",
          headers: headers,
        });
      }
      const fetchedData = await response.json();

      // Format string data by the type of stats
      if (getShowStatsDetais()) {
        let createdDetails = "";
        if (props.data === "curtailment-probability") {
          createdDetails = `Expected recall: ${fetchedData["recall"]}, precision: ${fetchedData["precision"]}, F1-score: ${fetchedData["f1_score"]}, accuracy: ${fetchedData["accuracy"]}`;
        } else if (props.data === "load") {
          let unit = "KW";
          if (props.entity === "psco") {
            unit = "MW";
          }
          createdDetails = `Expected RMSE: ${Math.round(
            fetchedData["rmse"],
          )}${unit}, MAE: ${Math.round(fetchedData["mae"])}${unit}, MAPE: ${(
            fetchedData["mape"] * 100
          ).toFixed(1)}%`;
        }
        setStatsDetails(createdDetails);
      }
      setIsLoading(false);
    };

    // Get evaluation stats by API
    fetchData();

    // Get image links by Firebase Storage
    setIsLoadingImages(true);

    // Make an iterable of Promise to get image URLs
    const storage = getStorage(firebaseApp);
    const promises: Promise<string>[] = [];
    for (const index in props.images) {
      const imageRef = ref(
        storage,
        `${props.folder}/${props.images[index].name}`,
      );
      promises.push(getDownloadURL(imageRef));
    }

    // Fulfill all the promises and set the result to the state
    Promise.all(promises).then((values) => {
      const copiedImages: { summary: string; name: string; url: string }[] =
        props.images.map((element) => {
          return { ...element, url: "" };
        });
      for (const index in copiedImages) {
        copiedImages[index]["url"] = values[index];
      }
      setImages(copiedImages);

      setIsLoadingImages(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.data,
    props.folder,
    props.images,
    props.entity,
    props.hourAhead,
    props.showStatsDetails,
  ]);

  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardHeader title={props.title} />
      <CardContent>
        {/* Stats */}
        <Accordion hidden={!getShowStatsDetais()} defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Stats
          </AccordionSummary>
          {!isLoading && <AccordionDetails>{statsDetails}</AccordionDetails>}
          {isLoading && <LinearProgress />}
        </Accordion>

        {/* Images before downloaded */}
        {isLoadingImages &&
          props.images.map((element) => (
            <Accordion key={element.summary} defaultExpanded={false}>
              <AccordionSummary>{element.summary}</AccordionSummary>
            </Accordion>
          ))}

        {/* Images after downloaded */}
        {!isLoadingImages &&
          images.map((element) => (
            <Accordion key={element.summary} defaultExpanded={false}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {element.summary}
              </AccordionSummary>
              <AccordionDetails>
                <img
                  src={element.url}
                  alt={element.summary}
                  height="auto"
                  width="100%"
                />
              </AccordionDetails>
            </Accordion>
          ))}
      </CardContent>
    </Card>
  );
};

export default ModelEvaluationCard;
