import React, { useEffect, useState } from "react";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { firebaseApp } from "../../initializeFirebase";
import Papa from "papaparse";

/** Pulling in position + substation data to add to map + data table */

interface PositionDataProps {
  load: string;
  onDataFetched: (data: any) => void;
  onPathsFetched: (paths: { [key: string]: string[] }[]) => void;
  onSubstationsFetched: (substations: {
    [key: string]: [number, number];
  }) => void;
}
interface Position {
  [key: string]: any;
}
interface Substations {
  [key: string]: [number, number];
}

const PositionData: React.FC<PositionDataProps> = ({
  load,
  onDataFetched,
  onPathsFetched,
  onSubstationsFetched,
}) => {
  const [positionData, setPositionData] = useState<Position | null>(null);
  const [paths, setPaths] = useState<{ [key: string]: string[] }[]>([]);

  useEffect(() => {
    // Get + store load position
    const fetchPositionsData = async () => {
      try {
        const storage = getStorage(firebaseApp);
        const fileRef = ref(storage, "map-data/position-data.json");
        const url = await getDownloadURL(fileRef);
        const response = await fetch(url);
        const data = await response.json();
        setPositionData(data);
        onDataFetched(data);

        // Extract all path dictionaries
        if (load && data[load]) {
          const extractPaths = (data: any): { [key: string]: string[] }[] => {
            const result: { [key: string]: string[] }[] = [];
            for (const key in data[load]) {
              if (data[load][key].path) {
                result.push({ [key]: data[load][key].path });
              }
            }
            return result;
          };

          const paths = extractPaths(data);
          setPaths(paths);
          onPathsFetched(paths);
        } else {
          setPaths([]);
          onPathsFetched([]);
        }
      } catch (error) {
        console.error("Error fetching position data:", error);
        console.log(paths, positionData);
      }
    };

    // Getting substations for paths
    const fetchSubstationData = async () => {
      try {
        const storage = getStorage(firebaseApp);
        const fileRef = ref(storage, "map-data/substations-data.csv");
        const url = await getDownloadURL(fileRef);
        const response = await fetch(url);
        const data = await response.text();
        const parsedData: Substations = {};
        Papa.parse(data, {
          header: true,
          skipEmptyLines: true,
          complete: (results: any) => {
            (results.data as any[]).forEach((row) => {
              const name = row.point;
              const lat = parseFloat(row.lat);
              const long = parseFloat(row.long);
              parsedData[name] = [lat, long];
            });
            onSubstationsFetched(parsedData);
          },
        });
      } catch (error) {
        console.log("Error fetching substation data", error);
      }
    };

    fetchPositionsData();
    fetchSubstationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, onDataFetched, onPathsFetched, onSubstationsFetched]);

  return null;
};

export default PositionData;
