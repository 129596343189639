import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  AlertTitle,
  IconButton,
  Collapse,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridRowId,
  GridRowParams,
  GridRowSelectionModel,
  // GridCellParams,
  // GridCellModes,
  // GridCellModesModel,
  useGridApiRef,
} from "@mui/x-data-grid-v7";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { ValidationResult, runValidations } from "./validationRules";

// Load required plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const options = ["15MIN", "DYNAMIC", "HOURLY", "ONCE"];

export interface GridRow extends GridRowModel {
  id: GridRowId;
  hour: number | string;
  [key: string]: any;
}

const irows: GridRow[] = Array.from({ length: 24 }, (_, index) => ({
  id: index,
  hour: index,
  bidOption: "",
}));

interface BidGridProps {
  onDataChange?: (data: GridRow[]) => void;
  onSubmit?: (data: GridRow[]) => void;
  marketType: string;
  wheelReference?: string | null;
  wheelReferenceArray?: string[] | null;
  transaction?: string;
  PrimaryInterTieID?: string;
  dateTime?: Date | null;
}

type Mode = "submit" | "copy";

const BidGrid: React.FC<BidGridProps> = ({
  onDataChange = () => {},
  onSubmit = () => {},
  marketType,
  wheelReference,
  wheelReferenceArray,
  transaction,
  PrimaryInterTieID,
  dateTime,
}) => {
  const [rows, setRows] = useState<GridRow[]>(irows);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );
  const [sourceRowId, setSourceRowId] = useState<GridRowId | null>(null);
  const [mode, setMode] = useState<Mode>("submit");
  // const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const [segmentCount, setSegmentCount] = useState<number>(3);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [safeEditModeQuitQueue, setSafeEditModeQuitQueue] = useState<
    {
      f: (rows: GridRow[]) => void;
      ttl: number;
    }[]
  >([]);
  const apiRef = useGridApiRef();
  const [validationErrors, setValidationErrors] = useState<ValidationResult[]>(
    []
  );
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const baseColumns: GridColDef[] = useMemo(
    () => [
      {
        field: "hour",
        headerName: "Hour",
        type: "number",
        width: 85,
        headerAlign: "center",
        align: "center",
        valueFormatter: (params) => {
          return `${params + 1}`;
        },
      },
      {
        field: "status",
        headerName: "Status",
        type: "string",
        width: 110,
        cellClassName: "status-column",
        headerAlign: "center",
        align: "center",
      },
      {
        field: "bidSelfSchedule",
        headerName: "Bid Self Schedule",
        type: "number",
        width: 150,
        editable: true,
        headerAlign: "center",
        align: "right",
      },
      ...(marketType !== "DAM"
        ? [
            {
              field: "bidOption",
              headerName: "Bid Option",
              type: "singleSelect",
              valueOptions: options,
              width: 120,
              editable: true,
              headerAlign: "center",
              align: "center",
            } as GridColDef,
          ]
        : []),
      ...(wheelReference !== undefined
        ? [
            {
              field: "wheelReference",
              headerName: "Wheel Reference",
              type: "string",
              width: 380,
              editable: false,
              headerAlign: "center",
              align: "left",
            } as GridColDef,
          ]
        : []),
    ],
    [marketType, wheelReference]
  );

  const [columns, setColumns] = useState<GridColDef[]>(baseColumns);

  useEffect(() => {
    const newColumns = [...baseColumns];
    for (let i = 1; i <= segmentCount; i++) {
      newColumns.push(
        {
          field: `segment${i}MW`,
          headerName: `Segment${i} MW`,
          type: "number",
          width: 130,
          editable: true,
          headerAlign: "center",
          align: "right",
        },
        {
          field: `segment${i}Price`,
          headerName: `Segment${i} Price`,
          type: "number",
          width: 130,
          editable: true,
          headerAlign: "center",
          align: "right",
        }
      );
    }
    setColumns(newColumns);
  }, [segmentCount, baseColumns]);

  const calculateTotalRow = useCallback(
    (currentRows: GridRow[]): GridRow => {
      const totals: { [key: string]: number } = {};
      const counts: { [key: string]: number } = {};

      currentRows.forEach((row) => {
        if (row.id !== "total") {
          columns.forEach((col) => {
            const field = col.field;
            if (
              field !== "hour" &&
              field !== "status" &&
              field !== "bidOption"
            ) {
              const value = row[field];
              if (value !== undefined && value !== "") {
                const numValue = Number(value);
                if (!isNaN(numValue)) {
                  totals[field] = (totals[field] || 0) + numValue;
                  counts[field] = (counts[field] || 0) + 1;
                }
              }
            }
          });
        }
      });

      const totalRow: GridRow = {
        id: "total",
        hour: "Total/Avg",
        status: "",
        bidOption: "",
      };

      columns.forEach((col) => {
        const field = col.field;
        if (!counts[field]) {
          return;
        }
        if (field !== "hour" && field !== "status" && field !== "bidOption") {
          if (field === "bidSelfSchedule" || field.endsWith("MW")) {
            totalRow[field] = totals[field] || 0;
          } else if (field.endsWith("Price")) {
            totalRow[field] = counts[field]
              ? Number((totals[field] / counts[field]).toFixed(2))
              : 0;
          }
        }
      });

      return totalRow;
    },
    [columns]
  );

  useEffect(() => {
    const dataRows = rows.filter((row) => row.id !== "total");
    const filteredDataRows = dataRows.map((row) => {
      const filteredRow: GridRow = { id: row.id, hour: row.hour };
      columns.forEach((col) => {
        if (col.field !== "hour" && col.field !== "status") {
          if (marketType === "DAM" && col.field === "bidOption") {
            // Don't include bidOption for DAM
          } else if (row[col.field] !== undefined && row[col.field] !== "") {
            filteredRow[col.field] = row[col.field];
          }
        }
      });
      return filteredRow;
    });
    onDataChange(filteredDataRows);
  }, [rows, onDataChange, columns, marketType]);

  const handleAddSegment = () => {
    if (segmentCount < 11) {
      setSegmentCount((prevCount) => prevCount + 1);
    }
  };

  const handleRemoveSegment = () => {
    if (segmentCount > 3) {
      const segmentToRemove = segmentCount;
      const hasData = rows.some(
        (row) =>
          row.id !== "total" &&
          ((row[`segment${segmentToRemove}MW`] !== undefined &&
            row[`segment${segmentToRemove}MW`] !== "") ||
            (row[`segment${segmentToRemove}Price`] !== undefined &&
              row[`segment${segmentToRemove}Price`] !== ""))
      );

      if (hasData) {
        setOpenConfirmDialog(true);
      } else {
        removeSegment();
      }
    }
  };

  const removeSegment = () => {
    setSegmentCount((prevCount) => prevCount - 1);
    setRows((prevRows) =>
      prevRows.map((row) => {
        if (row.id !== "total") {
          const newRow = { ...row };
          delete newRow[`segment${segmentCount}MW`];
          delete newRow[`segment${segmentCount}Price`];
          return newRow;
        }
        return row;
      })
    );
  };

  const handleConfirmRemove = () => {
    setOpenConfirmDialog(false);
    removeSegment();
  };

  const handleCancelRemove = () => {
    setOpenConfirmDialog(false);
  };

  interface SafeEditModeQuitItem {
    f: (rows: GridRow[]) => void;
    ttl: number;
  }

  const processRowDataWithSafeEditModeQuit = (
    processRowDataF: (rows: GridRow[]) => void
  ) => {
    const editRows = apiRef.current.state.editRows as Record<string, any>;
    if (Object.keys(editRows).length > 0) {
      setSafeEditModeQuitQueue((prev: SafeEditModeQuitItem[]) => [
        ...prev,
        { f: processRowDataF, ttl: 1 },
      ]);
    } else {
      processRowDataF(rows);
    }
  };

  useEffect(() => {
    // console.log("SafeEditModeQuitQueue:", safeEditModeQuitQueue, rows);

    if (safeEditModeQuitQueue.length > 0) {
      const newQueue = safeEditModeQuitQueue.map((item) => ({
        ...item,
        ttl: item.ttl - 1,
      }));
      newQueue.forEach((item, index) => {
        if (item.ttl === 0) {
          item.f(rows);
          newQueue.splice(index, 1);
        }
      });

      setSafeEditModeQuitQueue(newQueue);
    }
  }, [rows]);

  useEffect(() => {
    const initialRowsWithTotal = [...irows, calculateTotalRow(irows)];
    setRows(initialRowsWithTotal);
  }, []);

  useEffect(() => {
    // console.log("Wheel Reference:", wheelReference);
    if (wheelReference) {
      setRows((prevRows) =>
        prevRows.map((row) => ({
          ...row,
          ...(row.id !== "total" && { wheelReference }),
          ...(row.id !== "total" && { wheelReferenceArray }),
        }))
      );
    }
  }, [wheelReferenceArray, wheelReference]);

  const handleCopy = () => {
    processRowDataWithSafeEditModeQuit((rows) => {
      if (sourceRowId !== null && selectionModel.length > 1) {
        const sourceRow = rows.find((row) => row.id === sourceRowId);
        if (sourceRow) {
          const updatedRows = rows.map((row) => {
            if (
              selectionModel.includes(row.id) &&
              row.id !== sourceRowId &&
              row.id !== "total"
            ) {
              const newRow = { ...row };
              const keys = new Set([
                ...Object.keys(sourceRow),
                ...Object.keys(row),
              ]);
              keys.forEach((key) => {
                if (key !== "id" && key !== "hour") {
                  newRow[key] = sourceRow[key];
                }
              });
              return newRow;
            }
            return row;
          });
          const newTotalRow = calculateTotalRow(updatedRows);
          setRows([
            ...updatedRows.filter((row) => row.id !== "total"),
            newTotalRow,
          ]);
        }
      }
    });
  };

  const handleSubmit = () => {
    processRowDataWithSafeEditModeQuit((rows) => {
      const selectedRows = rows.filter(
        (row) => selectionModel.includes(row.id) && row.id !== "total"
      );

      // If DAM, remove bidOption from selected rows
      if (marketType === "DAM") {
        selectedRows.forEach((row) => {
          delete row.bidOption;
        });
      }

      // If not wheel reference, remove wheel reference from selected rows
      if (!wheelReference) {
        selectedRows.forEach((row) => {
          delete row.wheelReference;
          delete row.wheelReferenceArray;
        });
      }

      // Delete all fields that is empty string
      selectedRows.forEach((row) => {
        Object.keys(row).forEach((key) => {
          if (row[key] === "" || row[key] === undefined) {
            delete row[key];
          }
        });
      });

      const validationResults = runValidations(selectedRows, columns, {
        transaction: transaction,
      });

      // Clear previous validation errors and success message
      setValidationErrors([]);
      setShowValidationErrors(false);
      setShowSuccessMessage(false);

      if (validationResults.length > 0) {
        setValidationErrors(validationResults);
        setShowValidationErrors(true);
      } else {
        // console.log("Submitting rows:", selectedRows);
        onSubmit(selectedRows);
        setShowSuccessMessage(true);
      }
    });
  };

  const handleCloseValidationErrors = () => {
    setShowValidationErrors(false);
  };

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  const processRowUpdate = useCallback(
    (newRow: GridRow) => {
      if (newRow.id === "total") return newRow; // Prevent editing of total row
      const updatedRow = Object.fromEntries(
        Object.entries(newRow).filter(
          ([key, value]) => value !== null && value !== undefined
        )
      ) as GridRow;
      setRows((prevRows) => {
        const newRows = prevRows
          .filter((row) => row.id !== "total")
          .map((row) => (row.id === newRow.id ? updatedRow : row));
        // console.log("updatedRow", updatedRow);
        const newTotalRow = calculateTotalRow(newRows);
        return [...newRows, newTotalRow];
      });
      return updatedRow;
    },
    [calculateTotalRow]
  );

  const getRowClassName = (params: GridRowParams) => {
    if (params.id === "total") return "total-row";
    if (mode === "copy" && params.id === sourceRowId) {
      return "highlight-row";
    }
    return "";
  };

  const handleModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: Mode
  ) => {
    if (newMode !== null) {
      setMode(newMode);
      setSelectionModel([]); // Clear selection when changing modes
      setSourceRowId(null); // Clear source row when changing modes
    }
  };

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel
  ) => {
    const filteredSelectionModel = newSelectionModel.filter((id) => {
      // use isRowSelectable to check if the row is selectable
      return isRowSelectable({
        row: rows.find((row) => row.id === id) as GridRow,
      } as GridRowParams);
    });
    if (mode === "copy") {
      const addedIds = filteredSelectionModel.filter(
        (id) => !selectionModel.includes(id)
      );
      const removedIds = selectionModel.filter(
        (id) => !filteredSelectionModel.includes(id)
      );

      if (addedIds.length > 0) {
        // If there's no source row, set the newly selected row as the source
        if (sourceRowId === null) {
          setSourceRowId(addedIds[0]);
          setSelectionModel([addedIds[0]]);
        } else {
          // If there's already a source row, add the new selection to the model
          setSelectionModel([
            sourceRowId,
            ...filteredSelectionModel.filter((id) => id !== sourceRowId),
          ]);
        }
      } else if (removedIds.length > 0) {
        // If the source row was deselected, clear it and the selection model
        if (removedIds.includes(sourceRowId as GridRowId)) {
          setSourceRowId(null);
          setSelectionModel([]);
        } else {
          // Otherwise, just update the selection model
          setSelectionModel(filteredSelectionModel);
        }
      }
    } else {
      // In submit mode, just use the new selection model as is
      setSelectionModel(filteredSelectionModel);
    }
  };

  const resetAllFields = useCallback(() => {
    setRows((prevRows) => {
      const newRows = prevRows.map((row) => {
        const newRow = { ...row };
        columns.forEach((col) => {
          if (col.field !== "hour" && col.field !== "status" && col.field !== "wheelReference") {
            newRow[col.field] = "";
          }
        });
        return newRow;
      });
      const newTotalRow = calculateTotalRow(newRows);
      return [...newRows.filter((row) => row.id !== "total"), newTotalRow];
    });

    // Clear selection model
    setMode("submit");
    setSelectionModel([]); // Clear selection when changing modes
    setSourceRowId(null);
  }, [columns, calculateTotalRow]);

  useEffect(() => {
    // clear all field in the table
    resetAllFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PrimaryInterTieID, transaction]);

  // check if an hour is submittable for RTM
  const isHourSubmittable = useCallback(
    (hourIndex: number): boolean => {
      if (marketType !== "RTM") return true;

      // Get current time in PPT
      const currentTimePPT = dayjs().tz("America/Los_Angeles");

      // Calculate the flow hour start time for the given hour index
      // Hour index is 0-23, representing HE1-HE24
      const flowHourStart = currentTimePPT
        .startOf("day")
        .add(hourIndex, "hour");

      // Calculate submission deadline (T-80 minutes before flow hour start) (use T-75 minutes to sneak a CAISO bid)
      const submissionDeadline = flowHourStart.subtract(75, "minute");

      // Return true if current time is before submission deadline
      return currentTimePPT.isBefore(submissionDeadline);
    },
    [marketType]
  );

  const isRowSelectable = useCallback(
    (params: GridRowParams) => {
      if (params.row.id === "total") return false;

      // For RTM, check if the hour is still submittable
      if (marketType === "RTM" && mode === "submit") {
        // if dateTime is before today, all hours are not submittable
        // if dateTime is today, check if the hour is submittable
        // if dateTime is after today, all hours are submittable

        if (dateTime) {
          const year = dateTime.getFullYear();
          const month = dateTime.getMonth(); // Note: months are 0-indexed in JavaScript
          const day = dateTime.getDate();

          const currentDate = dayjs().tz("America/Los_Angeles");
          const selectedDate = dayjs.tz(
            `${year}-${month + 1}-${day}`,
            "America/Los_Angeles"
          );

          if (selectedDate.isBefore(currentDate, "day")) {
            return false;
          } else if (selectedDate.isSame(currentDate, "day")) {
            return isHourSubmittable(params.row.hour as number);
          } else {
            return true;
          }
        }

        return isHourSubmittable(params.row.hour as number);
      }

      return true;
    },
    [marketType, isHourSubmittable, mode, dateTime]
  );

  useEffect(() => {
    // update the selection model when dateTime changes
    setSelectionModel([]);
  }, [dateTime]);

  return (
    <Box sx={{ width: "100%", marginTop: "20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          flexDirection: { xs: "column", sm: "row" },
          gap: 2,
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={mode}
          exclusive
          onChange={handleModeChange}
          size="small"
        >
          <ToggleButton value="submit">Submit Mode</ToggleButton>
          <ToggleButton value="copy">Copy Mode</ToggleButton>
        </ToggleButtonGroup>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="outlined"
            onClick={handleRemoveSegment}
            disabled={segmentCount <= 3}
            size="small"
          >
            Remove Segment
          </Button>
          <Button
            variant="outlined"
            onClick={handleAddSegment}
            disabled={segmentCount >= 11}
            size="small"
          >
            Add Segment
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={mode === "copy" ? handleCopy : handleSubmit}
            disabled={
              mode === "copy"
                ? sourceRowId === null || selectionModel.length <= 1
                : selectionModel.length === 0
            }
            size="small"
          >
            {mode === "copy" ? "Copy to Selected" : "Submit Selected"}
          </Button>
        </Box>
      </Box>
      <Typography variant="body2" sx={{ mb: 1 }}>
        {mode === "copy"
          ? "Copy Mode: Select source row (highlighted in red) and target rows, then click 'Copy' to copy data."
          : "Submit Mode: Select rows to submit, then click 'Submit' to process selected rows."}
      </Typography>
      <Collapse in={showValidationErrors}>
        <Paper
          elevation={3}
          sx={{
            mb: 2,
            border: "2px solid #f44336",
            borderRadius: "4px",
            overflow: "hidden",
          }}
        >
          <Alert
            severity="error"
            icon={<ErrorOutlineIcon fontSize="large" />}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleCloseValidationErrors}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{
              "& .MuiAlert-icon": {
                alignItems: "center",
              },
            }}
          >
            <Box>
              <AlertTitle sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                Validation Errors Detected
              </AlertTitle>
              <Typography variant="body1" sx={{ fontWeight: "medium", mb: 1 }}>
                Please correct the following issues before submitting:
              </Typography>
              {validationErrors.map((error, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{ ml: 2, "&:before": { content: '"•"', mr: 1 } }}
                >
                  {error.errorMessage}
                </Typography>
              ))}
            </Box>
          </Alert>
        </Paper>
      </Collapse>

      <Collapse in={showSuccessMessage}>
        <Paper
          elevation={3}
          sx={{
            mb: 2,
            border: "2px solid #4caf50",
            borderRadius: "4px",
            overflow: "hidden",
          }}
        >
          <Alert
            severity="success"
            icon={<CheckCircleOutlineIcon fontSize="large" />}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleCloseSuccessMessage}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{
              "& .MuiAlert-icon": {
                alignItems: "center",
              },
            }}
          >
            <AlertTitle sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
              Validation Passed
            </AlertTitle>
            <Typography variant="body1">
              All selected rows have passed validation and are ready for
              submission.
            </Typography>
          </Alert>
        </Paper>
      </Collapse>
      <DataGrid
        rowHeight={25}
        rows={rows}
        columns={columns}
        hideFooter={true}
        checkboxSelection
        isRowSelectable={isRowSelectable}
        disableRowSelectionOnClick
        onRowSelectionModelChange={handleSelectionModelChange}
        rowSelectionModel={selectionModel}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(error) => {
          console.error("Error while saving: ", error);
        }}
        getRowClassName={getRowClassName}
        // cellModesModel={cellModesModel}
        // onCellModesModelChange={handleCellModesModelChange}
        // onCellClick={handleCellClick}
        apiRef={apiRef}
        sx={{
          "& .MuiDataGrid-cell": {
            border: "1px solid #e0e0e0",
          },
          "& .status-column": {
            backgroundColor: "#f5f5f5",
            color: "#666",
          },
          "& .MuiDataGrid-row.Mui-selected": {
            bgcolor:
              mode === "submit"
                ? "rgba(25, 118, 210, 0.08)"
                : "rgba(25, 118, 210, 0.08)",
          },
          "& .MuiDataGrid-row.Mui-selected:hover": {
            bgcolor:
              mode === "submit"
                ? "rgba(25, 118, 210, 0.12)"
                : "rgba(25, 118, 210, 0.12)",
          },
          "& .highlight-row": {
            bgcolor: "#FFCCCB !important",
            "&:hover": {
              bgcolor: "#FFB6B6 !important",
            },
          },
          "& .highlight-row.Mui-selected": {
            bgcolor: "#FFCCCB !important",
          },
          "& .highlight-row.Mui-selected:hover": {
            bgcolor: "#FFB6B6 !important",
          },
          "& .total-row": {
            bgcolor: "#f0f0f0 !important",
            fontWeight: "bold",
            pointerEvents: "none",
          },
          "& .MuiDataGrid-cell--editable:hover": {
            cursor: "text",
          },
          '& .MuiDataGrid-cell--editable[data-field="bidOption"]:hover': {
            cursor: "pointer",
          },
          "& .validation-error": {
            bgcolor: "#FFCCCB !important",
          },
        }}
      />
      <Dialog
        open={openConfirmDialog}
        onClose={handleCancelRemove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Segment Removal"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            The segment you are trying to remove contains data. Are you sure you
            want to remove this segment and delete its data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelRemove} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmRemove} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BidGrid;
