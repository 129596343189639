import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
// import Button from "@mui/material/Button";

import DrawerMenu from "./DrawerMenu";

function Navigation() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Fragment>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            component={Link}
            to={"/dashboard"}
            color="inherit"
            sx={{ flexGrow: 1, textDecoration: "none" }}
          >
            Data Analytics WebApp
          </Typography>

          {/* <Button
            variant="text"
            color="inherit"
            size="large"
            component={Link}
            to="/dashboard"
            sx={{ textTransform: "none" }}
          >
            Analytics WebApp
          </Button> */}
        </Toolbar>
      </AppBar>

      <DrawerMenu open={isDrawerOpen} onClose={toggleDrawer} />
    </Fragment>
  );
}

export default Navigation;
