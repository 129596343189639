import { useEffect, useState } from "react";

import ScenarioForecastPlotCard from "../data-visualization/ScenarioForecastPlotCard";
import { tabBlue } from "../../utils/style";
import { capitalizeFirstLetter } from "../../utils/shared";
import fetchGCP from "../../utils/fetchGCP";

const metaData = [{ name: "averageLoad", color: tabBlue }];

const LongTermLoadHourlyShapeBySeason: React.FC<{
  entity: string;
  season: string;
}> = (props) => {
  const [scenario, setScenario] = useState("medium");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const url = `${process.env.REACT_APP_ENDPOINTS_URL}/forecast/long-term-load?data=hourly-shape&entity=${props.entity}&scenario=${scenario}&season=${props.season}`;
      const response = await fetchGCP(url);
      const fetchedData = await response.json();

      setData(fetchedData);
      setIsLoading(false);
    };

    fetchData();
  }, [scenario, props.entity, props.season]);

  return (
    <ScenarioForecastPlotCard
      title={`${capitalizeFirstLetter(props.season)} hourly shape`}
      isLoading={isLoading}
      scenario={scenario}
      setScenario={setScenario}
      data={data}
      metaData={metaData}
    />
  );
};

export default LongTermLoadHourlyShapeBySeason;
