export const getDefaultDate = (days: number) => {
  const date = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const dateToDatetimeString = (date: Date) => {
  let hours = date.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  // 0 hour should be 12 hour
  hours = hours ? hours : 12;
  return `${date.toLocaleDateString()} ${hours}${ampm}`;
};

export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const computeRmse = (trues: number[], preds: number[]) => {
  let total = 0;
  let n = 0;
  trues.forEach((_true, index) => {
    const pred = preds[index];
    total += Math.pow(_true - pred, 2);
    n += 1;
  });
  return Math.sqrt(total / n);
};

export const computeMae = (trues: number[], preds: number[]) => {
  let total = 0;
  let n = 0;
  trues.forEach((_true, index) => {
    const pred = preds[index];
    // Null check to allow the function to work with missing values
    if (_true === null || pred === null) {
      total += 0;
      n += 0;
    } else {
      total += Math.abs(_true - pred);
      n += 1;
    }
  });
  return total / n;
};

export const computeMape = (trues: number[], preds: number[]) => {
  let total = 0;
  let n = 0;
  trues.forEach((_true, index) => {
    const pred = preds[index];
    // Null check to allow the function to work with missing values
    if (_true === null || pred === null) {
      total += 0;
      n += 0;
    } else {
      total += Math.abs((pred - _true) / pred);
      n += 1;
    }
  });
  return total / n;
};
