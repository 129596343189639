import { Fragment, useContext } from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";

import Navigation from "./components/Navigation";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Hce from "./pages/forecast/Hce";
import Dmea from "./pages/forecast/Dmea";
import Kcec from "./pages/forecast/Kcec";
import Raton from "./pages/forecast/Raton";
import Aztec from "./pages/forecast/Aztec";
import Jicarilla from "./pages/forecast/Jicarilla";
import Acoma from "./pages/forecast/Acoma";
import HydroForecast from "./pages/forecast/SmithCreek";
import Psco from "./pages/forecast/Psco";
import DmeaEnergyImbalance from "./pages/energy-imbalance/DmeaEnergyImbalance";
import AztecEnergyImbalance from "./pages/energy-imbalance/AztecEnergyImbalance";
import LongTermLoadForecastPage from "./pages/long-term-load-forecast/LongTermLoadForecast";
import CryptoCurtailmentPage from "./pages/operation/CryptoCurtailment";
import SubmitCurtailmentPage from "./pages/operation/SubmitCurtailment";
import UpdateCurtailmentPage from "./pages/operation/UpdateCurtailment";
import DownloadData from "./pages/DownloadData";
import Parameter from "./pages/Parameter";
import Links from "./pages/Links";
import TransmissionPathTool from "./pages/TransmissionPathTool";
import AuthContext from "./store/auth-context";
import WeccOutages from "./pages/WeccOutages";
import FeatureRequest from "./pages/FeatureRequest";
import BroncoDownload from "./pages/BroncoDownload";
import CapacityPlan from "./pages/CapacityPlan";
import PriceCapture from "./pages/PriceCapture";
import LoadActuals from "./pages/LoadActuals";
import TableauHomePage from "./pages/TableauHomePage";
import CaisoBidSubmit from "./pages/bid-submission/CaisoBidSubmit";

function App() {
  const authCtx = useContext(AuthContext);

  const isLoggedIn = authCtx.isLoggedIn;

  return (
    <Fragment>
      <Navigation />
      <Routes>
        {!isLoggedIn && <Route path="/login" element={<Login />} />}

        {isLoggedIn && <Route path="/dashboard" element={<Dashboard />} />}

        {isLoggedIn && <Route path="forecast/hce" element={<Hce />} />}
        {isLoggedIn && <Route path="forecast/dmea" element={<Dmea />} />}
        {isLoggedIn && <Route path="forecast/kcec" element={<Kcec />} />}
        {isLoggedIn && <Route path="forecast/raton" element={<Raton />} />}
        {isLoggedIn && <Route path="forecast/aztec" element={<Aztec />} />}
        {isLoggedIn && (
          <Route path="forecast/jicarilla" element={<Jicarilla />} />
        )}
        {isLoggedIn && <Route path="forecast/acoma" element={<Acoma />} />}
        {isLoggedIn && (
          <Route path="/forecast/smith-creek" element={<HydroForecast />} />
        )}
        {isLoggedIn && <Route path="forecast/psco" element={<Psco />} />}

        {isLoggedIn && (
          <Route
            path="/energy-imbalance/dmea"
            element={<DmeaEnergyImbalance />}
          />
        )}
        {isLoggedIn && (
          <Route
            path="/energy-imbalance/aztec"
            element={<AztecEnergyImbalance />}
          />
        )}

        {/* Long-term load forecast */}
        {isLoggedIn && (
          <Route
            path="long-term-load-forecast"
            element={<LongTermLoadForecastPage />}
          />
        )}

        {isLoggedIn && (
          <Route path="feature-request" element={<FeatureRequest />} />
        )}

        {/* Crypto curtailment */}
        {isLoggedIn && (
          <Route
            path="/operation/crypto-curtailment"
            element={<CryptoCurtailmentPage />}
          />
        )}
        {isLoggedIn && (
          <Route
            path="/operation/crypto-curtailment/submit"
            element={<SubmitCurtailmentPage />}
          />
        )}
        {isLoggedIn && (
          <Route
            path="/operation/crypto-curtailment/update/:id"
            element={<UpdateCurtailmentPage />}
          />
        )}

        {isLoggedIn && (
          <Route
            path="/caiso-bid-submit"
            element={<CaisoBidSubmit />}
          />
        )}

        {isLoggedIn && (
          <Route
            path="/transmission-path-tool"
            element={<TransmissionPathTool />}
          />
        )}

        {isLoggedIn && (
          <Route path="/download-data" element={<DownloadData />} />
        )}
        {isLoggedIn && <Route path="/parameter" element={<Parameter />} />}

        {isLoggedIn && (
          <Route
            path="/long-term-load-forecast"
            element={<LongTermLoadForecastPage />}
          />
        )}
        {isLoggedIn && (
          <Route path="/bronco-download" element={<BroncoDownload />} />
        )}

        {isLoggedIn && <Route path="/wecc-outages" element={<WeccOutages />} />}

        {isLoggedIn && (
          <Route
            path="/price-capture"
            element={<PriceCapture autoSaveInterval={10 * 1000} />}
          />
        )}

        {isLoggedIn && (
          <Route path={"/load-actuals"} element={<LoadActuals />} />
        )}

        {isLoggedIn && (
          <Route path="/feature-request" element={<FeatureRequest />} />
        )}

        {isLoggedIn && (
          <Route path={"/tableau"} element={<TableauHomePage />} />
        )}

        {isLoggedIn && (
          <Route path="/capacity-plan" element={<CapacityPlan />} />
        )}

        {isLoggedIn && <Route path="/links" element={<Links />} />}

        {isLoggedIn && (
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        )}
        {!isLoggedIn && (
          <Route path="*" element={<Navigate to="/login" replace />} />
        )}
      </Routes>
    </Fragment>
  );
}

export default App;
