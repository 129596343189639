import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { CSVLink } from "react-csv";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface ScenarioForecastPlotCardProps {
  title: string;
  isLoading: boolean;
  scenario: string;
  setScenario(newValue: string): void;
  data: any[];
  metaData: { name: string; color: string }[];
}

const scenarios = [
  { name: "High", value: "high" },
  { name: "Medium", value: "medium" },
  { name: "Low", value: "low" },
];

const ScenarioForecastPlotCard: React.FC<ScenarioForecastPlotCardProps> = (
  props,
) => {
  const handleScenarioChange = (event: SelectChangeEvent) => {
    const newScenario = event.target.value as string;
    props.setScenario(newScenario);
  };

  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardHeader title={props.title} />
      <CardContent>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={props.data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="index" />
            <YAxis
              label={{ value: "KW", angle: -90, position: "left" }}
              domain={["auto", "auto"]}
            />
            <Tooltip />
            <Legend />
            {props.metaData.map((element) => (
              <Line
                type="monotone"
                dataKey={element.name}
                stroke={element.color}
                dot={false}
                key={element.name}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
      <CardActions>
        <Stack direction="row" spacing={2} alignItems="center">
          <FormControl>
            <InputLabel id="scenario-label">Scenario</InputLabel>
            <Select
              labelId="scenario-label"
              value={props.scenario}
              label="Scenario"
              onChange={handleScenarioChange}
            >
              {scenarios.map((element) => (
                <MenuItem value={element.value} key={element.value}>
                  {element.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <CSVLink
            data={props.data}
            filename="data.csv"
            className="csvLinkNoStyle"
          >
            <Button variant="contained">Download</Button>
          </CSVLink>
        </Stack>
      </CardActions>
      {props.isLoading && <LinearProgress />}
    </Card>
  );
};

export default ScenarioForecastPlotCard;
