import { Stack } from "@mui/material";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { Button } from "@mui/material";
import ExcelJS from "exceljs";
import fetchGCP from "../../utils/fetchGCP";

const columns: GridColDef[] = [
  { field: "OutageName", headerName: "Outage Name", flex: 1 },
  { field: "OutageId", headerName: "Outage ID", flex: 1 },
  { field: "Company", headerName: "Company", flex: 1 },
  { field: "Facility", headerName: "Facility", flex: 1 },
  { field: "Segment", headerName: "Segment", flex: 1 },
  { field: "StartTimePS", headerName: "Start Time PS", flex: 1 },
  { field: "StopTimePS", headerName: "Stop Time PS", flex: 1 },
  { field: "OriginalTTCIn", headerName: "Original TTC In", flex: 1 },
  { field: "OriginalTTCOut", headerName: "Original TTC Out", flex: 1 },
  { field: "NewTTC", headerName: "New TTC", flex: 1 },
  { field: "LastUpdatedPS", headerName: "Last Updated PS", flex: 1 },
];

const WeccOutages = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const [outageData, setOutageData] = useState<Map<any, any>>(new Map());

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const submitWatchlist = async () => {
    // Submit the selected rows to the watchlist
    console.log("Submitting watchlist");
    const selectedRows = Array.from(outageData.values()).filter((row) =>
      selectionModel?.includes(row.OutageId),
    );

    let output = "";
    let indexer = 0;
    selectedRows.forEach((row) => {
      output += indexer === 0 ? "?" : "&";
      output +=
        "watch_outage" + indexer + "=" + JSON.stringify(row.OutageId) + "\n";
      indexer++;
    });

    if (output === "") {
      output = "?watch_outage=none";
    }

    const url = `${process.env.REACT_APP_ENDPOINTS_URL}/wecc-outages${output}`;

    const response = await fetchGCP(url);
    // Currently doing nothing with the response
    if (response.status === 200) {
      console.log("Submitted watchlist");
    }
  };

  const handleDownload = () => {
    // Convert the data to Excel format
    const excelData = Array.from(outageData.values()).map((row) => ({
      "Outage Name": row.OutageName,
      "Outage ID": row.OutageId,
      Company: row.Company,
      Facility: row.Facility,
      Segment: row.Segment,
      "Start Time PS": row.StartTimePS,
      "Stop Time PS": row.StopTimePS,
      "Original TTC In": row.OriginalTTCIn,
      "Original TTC Out": row.OriginalTTCOut,
      "New TTC": row.NewTTC,
      "Last Updated PS": row.LastUpdatedPS,
      EndingSoon: row.EndingSoon,
      RecentlyUpdated: row.RecentlyUpdated,
      StartingSoon: row.StartingSoon,
    }));

    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Wecc Outages");

    // Define the columns
    const excelColumns = [
      { header: "Outage Name", key: "Outage Name" },
      { header: "Outage ID", key: "Outage ID" },
      { header: "Company", key: "Company" },
      { header: "Facility", key: "Facility" },
      { header: "Segment", key: "Segment" },
      { header: "Start Time PS", key: "Start Time PS" },
      { header: "Stop Time PS", key: "Stop Time PS" },
      { header: "Original TTC In", key: "Original TTC In" },
      { header: "Original TTC Out", key: "Original TTC Out" },
      { header: "New TTC", key: "New TTC" },
      { header: "Last Updated PS", key: "Last Updated PS" },
      { header: "EndingSoon", key: "EndingSoon" },
      { header: "RecentlyUpdated", key: "RecentlyUpdated" },
      { header: "StartingSoon", key: "StartingSoon" },
    ];

    // Add the columns to the worksheet
    worksheet.columns = excelColumns;

    // Add the data to the worksheet
    worksheet.addRows(excelData);

    // Generate a buffer from the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      // Create a Blob from the buffer
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create a download link
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "wecc-outages.xlsx";

      // Click the link to start the download
      link.click();

      // Clean up the URL and link
      URL.revokeObjectURL(url);
      link.remove();
    });
  };

  // We only want to to have data match what tab it is in
  // All data will be in the all tab
  const getWeccOutagesApi = async () => {
    console.log("Fetching Wecc Outages API");
    // return;

    // Fetch data from api
    const url = `${process.env.REACT_APP_ENDPOINTS_URL}/wecc-outages`;

    const response = await fetchGCP(url);
    const mapTotal = new Map();
    const fetchedData = await response.json();

    const csvFields = [
      "OutageName",
      "OutageId",
      "Company",
      "Facility",
      "Segment",
      "StartTimePS",
      "StopTimePS",
      "OriginalTTCIn",
      "OriginalTTCOut",
      "NewTTC",
      "LastUpdatedPS",
      "EndingSoon",
      "RecentlyUpdated",
      "StartingSoon",
      "watch",
    ];
    // this will take a map and then add all the key value pairs to the map
    for (let i = 0; i < csvFields.length; i++) {
      Array(fetchedData[csvFields[i]]).forEach((item: any) => {
        for (var [key, value] of Object.entries(item)) {
          if (value === null || value === undefined) {
            value = "";
          }
          if (typeof value === "string" && value.includes("_x000D_\n")) {
            value = value.replaceAll("_x000D_\n", "\n");
          }
          mapTotal.set(key, {
            ...mapTotal.get(key),
            [csvFields[i]]: value,
          });
        }
      });
    }

    const mapTotalAsc = new Map([...mapTotal.entries()].sort());
    setOutageData(mapTotalAsc);

    //Every row that has a watch value will be added to the selection model
    const selectedRows = Array.from(mapTotalAsc.values()).filter(
      (row) => row.watch,
    );
    setSelectionModel(selectedRows.map((row) => row.OutageId));
  };

  useEffect(() => {
    // Run the WeccOutagesApi initially
    getWeccOutagesApi();

    // Rerun the WeccOutagesApi every 5 minutes
    const interval = setInterval(() => {
      getWeccOutagesApi();
    }, 600000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <Stack spacing={2}>
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab label="Wecc Outages All" />
        <Tab label="Recently Updated" />
        <Tab label="Starting Soon" />
        <Tab label="Ending Soon" />
        <Tab label="Watch List" />
      </Tabs>
      {/* All tab */}
      {selectedTab === 0 && (
        <DataGrid
          rows={Array.from(outageData.values())}
          rowsPerPageOptions={[25, 50, 100]}
          getRowId={(row) => row.OutageId}
          columns={columns}
          // getRowClassName={(params) => `status-${params.row.status}`}
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={(newSelection) => {
            setSelectionModel(newSelection);
            // handleAddToWatchlist(newSelection)
          }}
          keepNonExistentRowsSelected
          autoHeight
        />
      )}
      {/* Recently Updated tab */}
      {selectedTab === 1 && (
        <DataGrid
          rows={Array.from(outageData.values()).filter(
            (row) => row.RecentlyUpdated,
          )}
          getRowId={(row) => row.OutageId}
          columns={columns}
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={(newSelection) => {
            setSelectionModel(newSelection);
          }}
          autoHeight
          keepNonExistentRowsSelected
        />
      )}
      {/* Starting soon tab */}
      {selectedTab === 2 && (
        <DataGrid
          rows={Array.from(outageData.values()).filter(
            (row) => row.StartingSoon,
          )}
          getRowId={(row) => row.OutageId}
          columns={columns}
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={(newSelection) => {
            setSelectionModel(newSelection);
          }}
          autoHeight
          keepNonExistentRowsSelected
        />
      )}
      {/* Ending soon tab */}
      {selectedTab === 3 && (
        <DataGrid
          rows={Array.from(outageData.values()).filter((row) => row.EndingSoon)}
          getRowId={(row) => row.OutageId}
          columns={columns}
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={(newSelection) => {
            setSelectionModel(newSelection);
          }}
          autoHeight
          keepNonExistentRowsSelected
        />
      )}
      {/* Watch list soon tab */}
      {selectedTab === 4 && (
        <DataGrid
          rows={Array.from(outageData.values()).filter(
            (row) => selectionModel?.includes(row.OutageId) || false,
          )}
          getRowId={(row) => row.OutageId}
          columns={columns}
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={(newSelection) => {
            setSelectionModel(newSelection);
          }}
          autoHeight
          keepNonExistentRowsSelected
        />
      )}
      <Button variant="contained" onClick={submitWatchlist}>
        submit watchlist
      </Button>
      <Button variant="contained" onClick={handleDownload}>
        Download as Excel
      </Button>
    </Stack>
  );
};

export default WeccOutages;
