import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface Props {
  title: string;
  data: any[];
  metadata: {
    value: string;
    color: string;
    strokeWidth?: number;
    strokeDasharray?: string;
  }[];
  xAxisUnits: string;
  yAxisUnits: string;
  isLoading: boolean;
}

const LongTermLoadForecastPlotCard: React.FC<Props> = (props) => {
  return (
    <Card>
      <CardHeader title={props.title} />
      <CardContent>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={props.data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="index"
              label={{ value: props.xAxisUnits, position: "left" }}
            />
            <YAxis
              label={{
                value: props.yAxisUnits,
                angle: -90,
                position: "bottom",
              }}
            />
            <Tooltip />
            <Legend />
            {props.metadata.map((data) => (
              <Line
                key={data.value}
                type="monotone"
                dataKey={data.value}
                stroke={data.color}
                strokeWidth={data.strokeWidth}
                strokeDasharray={data.strokeDasharray}
                dot={false}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
      {props.isLoading && <LinearProgress />}
    </Card>
  );
};

export default LongTermLoadForecastPlotCard;
