import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import LinearProgress from "@mui/material/LinearProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import WindPowerIcon from "@mui/icons-material/WindPower";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import { lightBlue, lightGreen, amber } from "@mui/material/colors";

import { RealtimeData, LoadForecastData } from "../../models/dashboard";

const DashboardTableCard: React.FC<{
  title: string;
  rows: RealtimeData[] | LoadForecastData[];
  header: string[];
  isLoading: boolean;
}> = (props) => {
  props.rows.sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0));

  return (
    <Card>
      <CardHeader title={props.title} />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">{props.header[0]}</TableCell>
              <TableCell align="center">{props.header[1]}</TableCell>
              {props.header[4] && (
                <TableCell align="center">{props.header[4]}</TableCell>
              )}
              <TableCell align="center">{props.header[2]}</TableCell>
              <TableCell align="center">{props.header[3]}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                  {typeof row.status === "boolean" &&
                    (row.status ? (
                      <CheckCircleIcon sx={{ color: "#298a47" }} />
                    ) : (
                      <ErrorIcon sx={{ color: "#ca2f2d" }} />
                    ))}
                  {typeof row.status === "string" &&
                    (row.status === "good" ? (
                      <CheckCircleIcon sx={{ color: "#298a47" }} />
                    ) : row.status === "neutral" ? (
                      <WarningIcon sx={{ color: "#d48722" }} />
                    ) : (
                      <ErrorIcon sx={{ color: "#ca2f2d" }} />
                    ))}
                </TableCell>
                <TableCell align="center">{row.entity}</TableCell>
                {props.header[4] && (
                  <TableCell align="center">
                    {row.type === "Hydro" ? (
                      <WaterDropIcon sx={{ color: lightBlue[500] }} />
                    ) : row.type === "Wind" ? (
                      <WindPowerIcon sx={{ color: lightGreen[500] }} />
                    ) : (
                      <SolarPowerIcon sx={{ color: amber[500] }} />
                    )}
                  </TableCell>
                )}
                <TableCell align="center">
                  {"latestTimestamp" in row
                    ? row.latestTimestamp
                    : `${
                        row.mape !== null ? (row.mape * 100).toFixed(1) : "N/A"
                      } %`}
                </TableCell>
                <TableCell align="center">
                  {"latestValue" in row
                    ? `${
                        row.latestValue === null
                          ? "N/A"
                          : row.latestValue.toLocaleString("en-US")
                      } KW`
                    : row.forecaster === "data-analytics"
                      ? "Data Analytics"
                      : row.forecaster.toUpperCase()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      {props.isLoading && <LinearProgress />}
    </Card>
  );
};

export default DashboardTableCard;
