import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import RealtimeLoad from "../components/dashboard/RealtimeLoad";
import RealtimeGeneration from "../components/dashboard/RealtimeGeneration";
import LoadForecast from "../components/dashboard/LoadForecast";

const Dashboard = () => {
  return (
    <Grid container spacing={2} p={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" component="div" align="center">
          Dashboard
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <LoadForecast />
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <RealtimeLoad />
      </Grid>

      <Grid item xs={12} md={6} lg={4}>
        <RealtimeGeneration />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
