import Typography from "@mui/material/Typography";

const Title: React.FC<{ entity: string }> = (props) => {
  return (
    <Typography variant="h3" component="div" align="center">
      {props.entity} Energy Imbalance
    </Typography>
  );
};

export default Title;
