import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import CurtailmentTable from "../../components/operation/crypto-curtailment/curtailment-table";

const CryptoCurtailmentPage: React.FC = () => {
  const navigate = useNavigate();

  const navigateToSubmitCurtailmentPage = () => {
    navigate("/operation/crypto-curtailment/submit");
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      p={2}
    >
      <Grid item xs={12}>
        <Typography variant="h3" component="div" align="center">
          Crypto Curtailment (Demo)
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        textAlign="center"
        onClick={navigateToSubmitCurtailmentPage}
      >
        <Button variant="contained">Submit curtailment</Button>
      </Grid>

      <Grid item xs={12} md={8}>
        <CurtailmentTable />
      </Grid>
    </Grid>
  );
};

export default CryptoCurtailmentPage;
