import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import LinearProgress from "@mui/material/LinearProgress";

import {
  getLocalDateStringFromDate,
  getMtHourFromUtcDate,
} from "../../../utils/datetime";

const hours = new Array(24);
for (var i = 0; i < hours.length; i++) {
  hours[i] = i + 1;
}

interface CurtailmentDetailProps {
  mode?: string;
  id?: string;
}

const CurtailmentDetail: React.FC<CurtailmentDetailProps> = (props) => {
  const [date, setDate] = useState<Date | null>(new Date());
  const [hour, setHour] = useState<number>(1);
  const [amount, setAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [mode, setMode] = useState("add");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsButtonDisabled(true);

      const url = `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/curtailments/schedules?id=${props.id}`;
      const response = await fetch(url);
      const fetchedData = await response.json();
      // Fetched data is array even if we are getting single data
      // const fetchedDate = getLocalDateStringFromDate(new Date(fetchedData[0].index));
      const fetchedHour = getMtHourFromUtcDate(fetchedData[0].index);
      const fetchedAmount = fetchedData[0].value / 1000;

      setDate(new Date(fetchedData[0].index));
      setHour(fetchedHour);
      setAmount(fetchedAmount);
      setIsLoading(false);
      setIsButtonDisabled(false);
    };

    if (props.mode) {
      setMode(props.mode);

      fetchData();
    }
  }, [props.mode, props.id]);

  const dateChangeHandler = (newValue: Date | null) => {
    setDate(newValue);
  };

  const hourChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHour(+event.target.value);
  };

  const amountChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(+event.target.value);
  };

  const submitHandler = async () => {
    setIsLoading(true);
    setIsButtonDisabled(true);

    const url = `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/curtailments/schedules`;
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        date: getLocalDateStringFromDate(date!),
        hour: hour,
        value: amount * 1000,
      }),
    });

    if (response.ok) {
      const fetchedData = await response.json();
      const message = `Curtailment submitted successfully with confirmation ID: ${fetchedData.id}`;
      setResult(message);
      setIsButtonDisabled(false);
      setIsLoading(false);

      navigate("/operation/crypto-curtailment");
    } else {
      const message = "Something went wrong. Curtailment failed.";
      setResult(message);
      setIsButtonDisabled(false);
      setIsLoading(false);
    }
  };

  const cancelHandler = () => {
    navigate("/operation/crypto-curtailment");
  };

  const updateHandler = async () => {
    setIsLoading(true);
    setIsButtonDisabled(true);
    const url = `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/curtailments/schedules`;
    const response = await fetch(url, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        date: getLocalDateStringFromDate(date!),
        hour: hour,
        value: amount * 1000,
      }),
    });

    if (response.ok) {
      setIsLoading(true);
      setIsButtonDisabled(true);
      navigate("/operation/crypto-curtailment");
    }
  };

  const deleteHandler = async () => {
    setIsLoading(true);
    setIsButtonDisabled(true);
    const url = `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/curtailments/schedules`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        date: getLocalDateStringFromDate(date!),
        hour: hour,
      }),
    });

    if (response.ok) {
      setIsLoading(true);
      setIsButtonDisabled(true);
      navigate("/operation/crypto-curtailment");
    }
  };

  const addActions = (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        onClick={submitHandler}
        disabled={isButtonDisabled}
      >
        Submit
      </Button>

      <Button variant="contained" color="warning" onClick={cancelHandler}>
        Go back
      </Button>

      <div>{result}</div>
    </Stack>
  );

  const updateActions = (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        onClick={updateHandler}
        disabled={isButtonDisabled}
      >
        Update
      </Button>

      <Button
        variant="contained"
        color="error"
        onClick={deleteHandler}
        disabled={isButtonDisabled}
      >
        Delete
      </Button>

      <Button variant="contained" color="warning" onClick={cancelHandler}>
        Go back
      </Button>

      <div>{result}</div>
    </Stack>
  );

  return (
    <Card>
      <CardHeader title="Submit curtailment" />
      <CardContent>
        <Stack spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Date"
              inputFormat="MM/dd/yyyy"
              value={date}
              onChange={dateChangeHandler}
              renderInput={(params) => <TextField {...params} />}
              disabled={mode === "update" ? true : false}
            />
          </LocalizationProvider>
          <TextField
            required
            label="Hour (MT)"
            select
            value={hour}
            onChange={hourChangeHandler}
            disabled={mode === "update" ? true : false}
          >
            {hours.map((hour) => (
              <MenuItem key={hour} value={hour}>
                {hour}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            required
            label="Amount (MW)"
            type="number"
            value={amount}
            onChange={amountChangeHandler}
          />
        </Stack>
      </CardContent>
      <CardActions>{mode === "add" ? addActions : updateActions}</CardActions>
      {isLoading && <LinearProgress />}
    </Card>
  );
};

export default CurtailmentDetail;
