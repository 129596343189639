import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import BidMainHeader, { BidData } from "./BidMainHeader";
import fetchGCP from "../../utils/fetchGCP";

const CaisoBidSubmit = () => {
  const handleSubmit = async (data: BidData) => {
    console.log("Data submitted:", data);
    const apiUrl = `${process.env.REACT_APP_ENDPOINTS_URL}/caiso-bids`;

    try {
      const res = await fetchGCP(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (res.ok) {
        console.log("Data submitted successfully", res);
      } else {
        console.error("Error submitting data", res);
      }
    } catch (error) {
      console.error("Error submitting data", error);
    }
  };

  return (
    <Card>
      <CardContent>
        <BidMainHeader onSubmit={handleSubmit} />
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};

export default CaisoBidSubmit;
