import Grid from "@mui/material/Grid";

import Title from "../../components/energy-imbalance/Title";
import WeisActualScheduleLoad from "../../components/energy-imbalance/WeisActualScheduleLoad";
import WeisLoadBySettlementType from "../../components/energy-imbalance/WeisLoadBySettlementType";
import ValidateWeisLoad from "../../components/energy-imbalance/ValidateWeisLoad";

const DmeaEnergyImbalance = () => {
  return (
    <Grid container justifyContent="center" spacing={2} p={2}>
      <Grid item xs={12}>
        <Title entity="DMEA"></Title>
      </Grid>
      <Grid item xs={12} md={6}>
        <WeisActualScheduleLoad entity="dmea" />
      </Grid>
      <Grid item xs={12} md={6}>
        <ValidateWeisLoad entity="dmea" />
      </Grid>
      <Grid item xs={12} md={6}>
        <WeisLoadBySettlementType entity="dmea" />
      </Grid>
    </Grid>
  );
};

export default DmeaEnergyImbalance;
