import { useEffect, useState } from "react";

import DashboardTableCard from "./dashboardTableCard";
import { LoadForecastData } from "../../models/dashboard";

const header = ["Status", "Entity", "MAPE", "Forecaster"];
const entities = [
  "HCE",
  "DMEA",
  "KCEC",
  "Aztec",
  "Raton",
  "Jicarilla",
  "Acoma",
];

const LoadForecast = () => {
  const [rows, setRows] = useState<LoadForecastData[]>([]);

  useEffect(() => {
    const fetchData = async (index: number) => {
      const url = `${process.env.REACT_APP_AWS_API_GATEWAY_URL}/loads/forecasts/evaluations?entity=${entities[index].toLowerCase()}&environment=production`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_AWS_API_GATEWAY_API_KEY!,
        },
      });
      if (response.ok) {
        // When API works fine
        const fetchedData = await response.json();
        const row: LoadForecastData = {
          id: index.toString(),
          status: fetchedData.mape < 0.1 ? "good" : "neutral",
          entity: entities[index],
          mape: fetchedData.mape,
          forecaster: fetchedData.forecaster,
        };
        setRows((prevRows) => [...prevRows, row]);
      } else {
        // When API fails for some reason
        const row = {
          id: index.toString(),
          status: "bad",
          entity: entities[index],
          mape: null,
          forecaster: "N/A",
        };
        setRows((prevRows) => [...prevRows, row]);
      }
    };
    // Clear state because the array inflates every time save code during development
    setRows([]);
    for (let i = 0; i < entities.length; i++) {
      fetchData(i);
    }
  }, []);

  return (
    <DashboardTableCard
      title={"Load forecast"}
      rows={rows}
      header={header}
      isLoading={rows.length !== entities.length ? true : false}
    />
  );
};

export default LoadForecast;
