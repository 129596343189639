import Card from "@mui/material/Card";
import React from "react";
import PriceHourRow from "./PriceHourRow";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";

interface HeavyLightFormProps {
  priceData: Map<string, number | null>;
  onPriceChange: (label: string, price: number) => void;
  onSave: () => void;
  dataLoading: boolean;
  saveLoading: boolean;
}

const HeavyLightForm: React.FC<HeavyLightFormProps> = ({
  priceData,
  onPriceChange,
  onSave,
  dataLoading,
  saveLoading,
}) => {
  return (
    <Card>
      <CardContent>
        {dataLoading ? (
          <Grid item container xs={12} justifyContent={"center"}>
            <CircularProgress />
          </Grid>
        ) : (
          <Stack
            spacing={4}
            p={4}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid
              container
              direction={{
                xs: "column",
                md: "row",
              }}
              justifyContent={"center"}
              alignItems={"center"}
              spacing={4}
              sx={{ width: { xl: "90%" } }}
            >
              <Grid item container xs={12} md={6}>
                <PriceHourRow
                  label={"Light Load"}
                  onChangePrice={(newPrice) =>
                    onPriceChange("lightLoad", newPrice)
                  }
                  price={priceData.get("lightLoad")}
                />
              </Grid>
              <Grid item container xs={12} md={6}>
                <PriceHourRow
                  label={"Heavy Load"}
                  onChangePrice={(newPrice) =>
                    onPriceChange("heavyLoad", newPrice)
                  }
                  price={priceData.get("heavyLoad")}
                />
              </Grid>
            </Grid>
            {saveLoading ? (
              <Grid item container xs={12} justifyContent={"center"}>
                <CircularProgress />
              </Grid>
            ) : (
              <Button
                sx={{ width: { sm: "100%", md: "50%", lg: "33.33%" } }}
                variant={"contained"}
                onClick={onSave}
              >
                Save
              </Button>
            )}
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default HeavyLightForm;
