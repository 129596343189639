import { useState, useRef } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import { CSVLink } from "react-csv";

import { getDefaultDate } from "../../utils/shared";
import fetchGCP from "../../utils/fetchGCP";

const entities = [
  { name: "HCE", value: "hce" },
  { name: "DMEA", value: "dmea" },
  { name: "KCEC", value: "kcec" },
  { name: "Aztec", value: "aztec" },
  { name: "Raton", value: "raton" },
  { name: "Jicarilla", value: "jicarilla" },
  { name: "Acoma", value: "acoma" },
];
const sourcesWeisGe = [
  { name: "WEIS", value: "weis" },
  { name: "Guzman Energy", value: "guzman-energy" },
];
const sourcesGe = [{ name: "Guzman Energy", value: "guzman-energy" }];
const weisData = [
  { name: "Actual load", value: "actual-load" },
  { name: "Schedule load", value: "schedule-load" },
  { name: "Energy imbalance", value: "energy-imbalance" },
];
const guzmanEnergyData = [{ name: "Actual load", value: "actual-load" }];

interface DownloadedData {
  index: string;
  value: number;
}

const DownloadForm = () => {
  const [entity, setEntity] = useState(entities[0].value);
  const [source, setSource] = useState(sourcesGe[0].value);
  const [sourceOption, setSourceOption] = useState(sourcesGe);
  const [data, setData] = useState(guzmanEnergyData[0].value);
  const [dataOption, setDataOption] = useState(guzmanEnergyData);
  const [start, setStart] = useState<Date | null>(getDefaultDate(-7));
  const [end, setEnd] = useState<Date | null>(getDefaultDate(-1));
  const [csvData, setCsvData] = useState<DownloadedData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const csvLink = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null);

  const handleEntityChange = (event: SelectChangeEvent) => {
    const newEntity = event.target.value as string;
    setEntity(newEntity);
    if (newEntity === "dmea" || newEntity === "aztec") {
      setSourceOption(sourcesWeisGe);
    } else {
      setSourceOption(sourcesGe);
    }
  };

  const handleSourceChange = (event: SelectChangeEvent) => {
    const newSource = event.target.value as string;
    setSource(newSource);
    if (newSource === "weis") {
      setDataOption(weisData);
    } else if (newSource === "guzman-energy") {
      setDataOption(guzmanEnergyData);
    }
  };

  const handleDataChange = (event: SelectChangeEvent) => {
    setData(event.target.value as string);
  };

  const handleStartChange = (newValue: Date | null) => {
    setStart(newValue);
  };

  const handleEndChange = (newValue: Date | null) => {
    setEnd(newValue);
  };

  const download = async () => {
    setIsLoading(true);

    const url = `${
      process.env.REACT_APP_ENDPOINTS_URL
    }/download-data-react?entity=${entity}&source=${source}&data=${data}&start=${start?.toISOString()}&end=${end?.toISOString()}`;

    const response = await fetchGCP(url);
    const fetchedData: DownloadedData[] = await response.json();

    setCsvData(fetchedData);

    // I guess without wait a little bit, sometimes CSV is empty
    await new Promise((r) => setTimeout(r, 1000));

    csvLink?.current?.link.click();

    setIsLoading(false);
  };

  return (
    <Card>
      <CardHeader title="Select the type of data" />
      <CardContent>
        <Stack direction="column" spacing={2} justifyContent="center">
          <FormControl>
            <InputLabel id="entity-label">Entity</InputLabel>
            <Select
              labelId="entity-label"
              value={entity}
              label="Entity"
              onChange={handleEntityChange}
            >
              {entities.map((element) => (
                <MenuItem value={element.value} key={element.value}>
                  {element.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="source-label">Source</InputLabel>
            <Select
              labelId="source-label"
              value={source}
              label="Source"
              onChange={handleSourceChange}
            >
              {sourceOption.map((element) => (
                <MenuItem value={element.value} key={element.value}>
                  {element.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="data-label">Data</InputLabel>
            <Select
              labelId="data-label"
              value={data}
              label="Data"
              onChange={handleDataChange}
            >
              {dataOption.map((element) => (
                <MenuItem value={element.value} key={element.value}>
                  {element.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <DesktopDatePicker
            label="Start"
            inputFormat="MM/dd/yyyy"
            value={start}
            onChange={handleStartChange}
            renderInput={(params) => <TextField {...params} />}
          ></DesktopDatePicker>

          <DesktopDatePicker
            label="End"
            inputFormat="MM/dd/yyyy"
            value={end}
            onChange={handleEndChange}
            renderInput={(params) => <TextField {...params} />}
          ></DesktopDatePicker>
        </Stack>
      </CardContent>
      <CardActions>
        <CSVLink
          data={csvData}
          filename={"data.csv"}
          className="csvLinkNoStyle"
          ref={csvLink}
          uFEFF={false}
        />
        <Button variant="contained" onClick={download}>
          Download
        </Button>
      </CardActions>
      {isLoading && <LinearProgress />}
    </Card>
  );
};

export default DownloadForm;
