import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import DownloadForm from "../components/download-data/DownloadForm";

const DownloadData = () => {
  return (
    <Grid container spacing={2} p={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" component="div" align="center">
          Download data
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <DownloadForm />
      </Grid>
    </Grid>
  );
};

export default DownloadData;
