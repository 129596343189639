import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import AuthContext from "../store/auth-context";

const eiMenuItems = [
  { name: "DMEA", path: "/energy-imbalance/dmea" },
  { name: "Aztec", path: "/energy-imbalance/aztec" },
];

const forecastMenuItem = [
  { name: "HCE", path: "/forecast/hce" },
  { name: "DMEA", path: "/forecast/dmea" },
  { name: "KCEC", path: "/forecast/kcec" },
  { name: "Raton", path: "/forecast/raton" },
  { name: "Aztec", path: "/forecast/aztec" },
  { name: "Jicarilla", path: "/forecast/jicarilla" },
  { name: "Acoma", path: "/forecast/acoma" },
  { name: "Smith Creek", path: "/forecast/smith-creek" },
  { name: "PSCO", path: "/forecast/psco" },
];

const DrawerMenu: React.FC<{
  open: boolean;
  onClose: () => void;
}> = (props) => {
  const [eiOpen, setEiOpen] = useState(false);
  const [forecastOpen, setForecastOpen] = useState(false);
  const authCtx = useContext(AuthContext);

  const isLoggedIn = authCtx.isLoggedIn;

  const logoutHandler = () => {
    authCtx.logout();
    props.onClose();
  };

  const eiNestedList = (
    <Collapse in={eiOpen} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {eiMenuItems.map((element) => (
          <ListItemButton
            component={Link}
            to={element.path}
            onClick={props.onClose}
            sx={{ pl: 4 }}
            key={element.name}
          >
            <ListItemText primary={element.name} />
          </ListItemButton>
        ))}
      </List>
    </Collapse>
  );

  const forecastNestedList = (
    <Collapse in={forecastOpen} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {forecastMenuItem.map((element) => (
          <ListItemButton
            component={Link}
            to={element.path}
            onClick={props.onClose}
            sx={{ pl: 4 }}
            key={element.name}
          >
            <ListItemText primary={element.name} />
          </ListItemButton>
        ))}
      </List>
    </Collapse>
  );

  const loggedInDrawerList = (
    <Box sx={{ width: 200 }} role="presentation">
      <List>
        <ListItemButton
          component={Link}
          to="/dashboard"
          onClick={props.onClose}
        >
          <ListItemText primary="Dashboard" />
        </ListItemButton>

        <ListItemButton
          onClick={() => {
            setEiOpen(!eiOpen);
          }}
        >
          <ListItemText primary="Energy imbalance" />
          {eiOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        {eiNestedList}

        <ListItemButton
          onClick={() => {
            setForecastOpen(!forecastOpen);
          }}
        >
          <ListItemText primary="Forecast" />
          {forecastOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        {forecastNestedList}

        <ListItemButton
          component={Link}
          to="/caiso-bid-submit"
          onClick={props.onClose}
        >
          <ListItemText primary="Caiso Bids" />
        </ListItemButton>

        <ListItemButton
          component={Link}
          to="/transmission-path-tool"
          onClick={props.onClose}
        >
          <ListItemText primary="Path tool" />
        </ListItemButton>

        <ListItemButton
          component={Link}
          to="/download-data"
          onClick={props.onClose}
        >
          <ListItemText primary="Download data" />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/parameter"
          onClick={props.onClose}
        >
          <ListItemText primary="Parameter" />
        </ListItemButton>

        <ListItemButton
          component={Link}
          to="/long-term-load-forecast"
          onClick={props.onClose}
        >
          <ListItemText primary="Long term forecast" />
        </ListItemButton>

        <ListItemButton
          component={Link}
          to="/wecc-outages"
          onClick={props.onClose}
        >
          <ListItemText primary="Wecc Outages" />
        </ListItemButton>

        <ListItemButton
          component={Link}
          to="/bronco-download"
          onClick={props.onClose}
        >
          <ListItemText primary="Bronco Download" />
        </ListItemButton>

        <ListItemButton
          component={Link}
          to="/price-capture"
          onClick={props.onClose}
        >
          <ListItemText primary="Price Capture" />
        </ListItemButton>

        <ListItemButton
          component={Link}
          to="/load-actuals"
          onClick={props.onClose}
        >
          <ListItemText primary="Load Actuals" />
        </ListItemButton>

        <ListItemButton component={Link} to="/tableau" onClick={props.onClose}>
          <ListItemText primary="Tableau" />
        </ListItemButton>

        <ListItemButton
          component={Link}
          to="/feature-request"
          onClick={props.onClose}
        >
          <ListItemText primary="Feature request" />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/capacity-plan"
          onClick={props.onClose}
        >
          <ListItemText primary="Capacity Plan" />
        </ListItemButton>

        <ListItemButton component={Link} to="/links" onClick={props.onClose}>
          <ListItemText primary="Links" />
        </ListItemButton>

        <ListItem disablePadding>
          <ListItemButton onClick={logoutHandler}>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const loggedOutDrawList = (
    <Box sx={{ width: 200 }} role="presentation">
      <List>
        <ListItemButton component={Link} to="/login" onClick={props.onClose}>
          <ListItemText primary="Login" />
        </ListItemButton>
      </List>
    </Box>
  );
  return (
    <Drawer anchor="left" open={props.open} onClose={props.onClose}>
      {isLoggedIn ? loggedInDrawerList : loggedOutDrawList}
    </Drawer>
  );
};

export default DrawerMenu;
