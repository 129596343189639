import { useState, useEffect } from "react";

import TimeSeriesPlotCard from "../data-visualization/TimeSeriesPlotCard";
import { getDefaultDate, dateToDatetimeString } from "../../utils/shared";
import { tabBlue } from "../../utils/style";
import fetchGCP from "../../utils/fetchGCP";

const metaData = [{ name: "value", color: tabBlue }];

interface PscoForecastProps {
  data: string;
  title: string;
  ymin?: number;
  ymax?: number;
  ylabel?: string;
}

const PscoForecast: React.FC<PscoForecastProps> = (props) => {
  const [start, setStart] = useState<Date | null>(getDefaultDate(0));
  const [end, setEnd] = useState<Date | null>(getDefaultDate(3));
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const url = `${process.env.REACT_APP_ENDPOINTS_URL}/forecast/psco?start=${start?.toISOString()}&end=${end?.toISOString()}&data=${
        props.data
      }`;
      const response = await fetchGCP(url);
      const fetchedData = await response.json();

      fetchedData.forEach((element: { index: string }) => {
        element.index = dateToDatetimeString(new Date(element.index));
      });

      setData(fetchedData);
      setIsLoading(false);
    };

    fetchData();
  }, [start, end, props.data]);

  return (
    <TimeSeriesPlotCard
      title={props.title}
      isLoading={isLoading}
      start={start}
      setStart={setStart}
      end={end}
      setEnd={setEnd}
      data={data}
      metaData={metaData}
      ymin={props.ymin}
      ymax={props.ymax}
      ylabel={props.ylabel}
    />
  );
};

export default PscoForecast;
