import React from "react";
import Grid from "@mui/material/Grid";

import TransmissionPathToolForm from "../components/transmission-path-tool/TransmissionPathToolForm";
import { Typography } from "@mui/material";

const TransmissionPathTool: React.FC = () => {
  return (
    <Grid container spacing={2} p={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" component="div" align="center">
          Path Tool
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <TransmissionPathToolForm />
      </Grid>
    </Grid>
  );
};

export default TransmissionPathTool;
