import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Loss from "../components/parameter/Loss";

const Parameter = () => {
  return (
    <Grid container p={2} spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" component="div" align="center">
          Parameter
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Loss />
      </Grid>
    </Grid>
  );
};

export default Parameter;
