import { Tab, Tabs, TabsProps } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const PriceCaptureTabMenu: React.FC<TabsProps> = ({ value, onChange }) => {
  return (
    <Card>
      <CardContent>
        <Tabs value={value} onChange={onChange} centered>
          <Tab label={"Hourly"} />
          <Tab label={"Day Ahead"} />
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default PriceCaptureTabMenu;
