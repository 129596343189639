import { useState, useEffect, useCallback } from "react";

import TimeSeriesPlotCard from "../data-visualization/TimeSeriesPlotCard";
import {
  getCurrentHourlyNow,
  getDefaultDate,
  dateToDatetimeString,
} from "../../utils/datetime";
import { tabBlue, tabOrange } from "../../utils/style";

const metaDataForecast = [
  { name: "forecastGeneration", color: tabBlue, dash: "3 3" },
];
const metaDataActualAndForecast = [
  { name: "actualGeneration", color: tabBlue },
  { name: "forecastGeneration", color: tabOrange, dash: "3 3" },
];

interface GenerationForecastProps {
  entity: string;
  power?: string;
  title: string;
}

const GenerationForecast: React.FC<GenerationForecastProps> = (props) => {
  const [start, setStart] = useState<Date | null>(
    props.entity === "taos" ? getDefaultDate(0) : getDefaultDate(-3),
  );
  const [end, setEnd] = useState<Date | null>(getDefaultDate(7));
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    let urls: string[] = [];
    if (props.entity === "taos") {
      urls = [
        `${
          process.env.REACT_APP_AWS_API_GATEWAY_URL
        }/generations/forecasts?entity=${props.entity}&power=${
          props.power
        }&start=${start?.toISOString()}&end=${end?.toISOString()}`,
      ];
    } else {
      const now = getCurrentHourlyNow();
      urls = [
        `${
          process.env.REACT_APP_AWS_API_GATEWAY_URL
        }/generations/actuals?entity=${props.entity}&power=${
          props.power
        }&start=${start?.toISOString()}&end=${now.toISOString()}`,
        `${
          process.env.REACT_APP_AWS_API_GATEWAY_URL
        }/generations/forecasts?entity=${props.entity}&power=${
          props.power
        }&start=${start?.toISOString()}&end=${end?.toISOString()}`,
      ];
    }

    // Map used to merge multiple API responses
    const map = new Map();

    await Promise.all(
      urls.map(async (url, index) => {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "x-api-key": process.env.REACT_APP_AWS_API_GATEWAY_API_KEY!,
          },
        });
        const fetchedData = await response.json();

        fetchedData.forEach((data: any) => {
          if (props.entity === "taos") {
            // Rename the value key name to merge
            data[metaDataForecast[index].name] = data["value"];
            delete data["value"];
          } else {
            // Rename the value key name to merge
            data[metaDataActualAndForecast[index].name] = data["value"];
            delete data["value"];
          }
        });

        // Merge
        fetchedData.forEach((item: any) =>
          map.set(item.index, { ...map.get(item.index), ...item }),
        );
      }),
    );

    // Extract array of objects and sort by index
    const mapAsc = new Map([...map.entries()].sort());
    const merged = Array.from(mapAsc.values());
    // Convert UTC index to local datetime string
    merged.forEach(
      (data) => (data["index"] = dateToDatetimeString(new Date(data["index"]))),
    );

    setData(merged);
    setIsLoading(false);
  }, [start, end, props.entity, props.power]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TimeSeriesPlotCard
      title={props.title}
      isLoading={isLoading}
      start={start}
      setStart={setStart}
      end={end}
      setEnd={setEnd}
      data={data}
      metaData={
        props.entity === "taos" ? metaDataForecast : metaDataActualAndForecast
      }
      fetchData={fetchData}
    />
  );
};

export default GenerationForecast;
