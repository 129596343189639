import Stack from "@mui/material/Stack";
import { RealtimePriceData } from "./RealtimePriceCaptureForm";
import Typography from "@mui/material/Typography";
import PriceInput from "./PriceInput";

interface RealtimeCaptureRowProps {
  hour: number;
  data: RealtimePriceData;
  onChange: (newBid: number, newOffer: number) => void;
}

const RealtimeCaptureRow: React.FC<RealtimeCaptureRowProps> = ({
  hour,
  data,
  onChange,
}) => {
  return (
    <Stack
      sx={{
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
      direction={"row"}
      spacing={2}
      padding={2}
    >
      <Typography
        variant={"h6"}
        align={"center"}
        color={"gray"}
        sx={{ width: "33.33%" }}
      >
        {`${hour}:00`}
      </Typography>
      <PriceInput
        label={"Bid"}
        defaultValue={data.bid ?? 0}
        onPriceChange={(newBid) => onChange(newBid, data.offer ?? 0)}
      />
      <PriceInput
        label={"Offer"}
        defaultValue={data.offer ?? 0}
        onPriceChange={(newOffer) => onChange(data.bid ?? 0, newOffer)}
      />
    </Stack>
  );
};

export default RealtimeCaptureRow;
