import React, { useEffect } from "react";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { firebaseApp } from "../../initializeFirebase";
import Papa from "papaparse";

/** Scraping DNR data */

interface DNRDataProps {
  onDataFetched: (data: any[]) => void;
}

const DNRData: React.FC<DNRDataProps> = ({ onDataFetched }) => {
  useEffect(() => {
    const fetchDNRData = async () => {
      try {
        const storage = getStorage(firebaseApp);
        const fileRef = ref(storage, "map-data/DNR.csv");
        const url = await getDownloadURL(fileRef);
        const response = await fetch(url);
        const data = await response.text();
        const parsedData: any[] = [];

        Papa.parse(data, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            parsedData.push(...results.data);
            onDataFetched(parsedData);
          },
        });
      } catch (error) {
        console.log("Error fetching DNR data", error);
      }
    };

    fetchDNRData();
  }, [onDataFetched]);

  return null;
};

export default DNRData;
