import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import LinksTable from "../components/LinksTable";

const Links = () => {
  return (
    <Grid container p={2} spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" component="div" align="center">
          Links
        </Typography>
      </Grid>

      <Grid item xs={12} md={8}>
        <LinksTable />
      </Grid>
    </Grid>
  );
};

export default Links;
