import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import { tabBlue } from "../../utils/style";

interface RadarChartCardProps {
  title: string;
  isLoading: boolean;
  data: any;
  name: string;
  value: string;
}

const RadarChartCard: React.FC<RadarChartCardProps> = (props) => {
  return (
    <Card sx={{ boxShadow: 3 }}>
      <CardHeader title={props.title} />
      <CardContent>
        {!props.isLoading && (
          <ResponsiveContainer width="100%" height={400}>
            <RadarChart data={props.data}>
              <PolarGrid />
              <PolarAngleAxis dataKey={props.name} />
              <PolarRadiusAxis angle={90} />
              <Radar
                dataKey={props.value}
                stroke={tabBlue}
                fill={tabBlue}
                fillOpacity={0.6}
              />
              <Tooltip />
            </RadarChart>
          </ResponsiveContainer>
        )}
      </CardContent>

      {props.isLoading && <LinearProgress />}
    </Card>
  );
};

export default RadarChartCard;
