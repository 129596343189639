export const computeRmse = (trues: number[], preds: number[]) => {
  let total = 0;
  let n = 0;
  trues.forEach((_true, index) => {
    const pred = preds[index];
    total += Math.pow(_true - pred, 2);
    n += 1;
  });
  return Math.sqrt(total / n);
};

export const computeMae = (trues: number[], preds: number[]) => {
  let total = 0;
  let n = 0;
  trues.forEach((_true, index) => {
    const pred = preds[index];
    // Null check to allow the function to work with missing values
    if (_true === null || pred === null) {
      total += 0;
      n += 0;
    } else {
      total += Math.abs(_true - pred);
      n += 1;
    }
  });
  return total / n;
};

export const computeMape = (trues: number[], preds: number[]) => {
  let total = 0;
  let n = 0;
  trues.forEach((_true, index) => {
    const pred = preds[index];
    // Null check to allow the function to work with missing values
    if (_true === null || pred === null) {
      total += 0;
      n += 0;
    } else {
      total += Math.abs((pred - _true) / pred);
      n += 1;
    }
  });
  return total / n;
};

export const computeMapeAvgDividedByAvg = (
  trues: number[],
  preds: number[],
) => {
  let totalAbsDiff = 0;
  let totalPred = 0;
  let n = 0;

  trues.forEach((_true, index) => {
    const pred = preds[index];

    // Null check to allow the function to work with missing values
    if (_true != null && pred != null) {
      totalAbsDiff += Math.abs(pred - _true);
      totalPred += pred;
      n += 1;
    }
  });
  return totalAbsDiff / n / (totalPred / n);
};
